import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function AddNewReferenceGN({
    isRefModal,
    setIsRefModal,
    setRefDetail,
    refDetail,
    AddNawRef
}) {
    const handleClose = () => {
        setIsRefModal(false);
        setRefDetail(prev => ({ ...prev, refName: "", index: null, subIndex:null, subDataIndex: null, actionType:"", noteNo: null }));
    };

    return (
        <React.Fragment>
            <Dialog
                open={isRefModal}
                onClose={handleClose}
            >
                <DialogTitle className=''>Add New Reference</DialogTitle>
                <DialogContent >
                    <input
                        style={{ width: "20rem" }}
                        className='px-2 py-1 rounded custom-bg-blue-200 p-2 border text-secondary'
                        placeholder='Ex:- Ind As 1.49'
                        value={refDetail.refName}
                        onChange={(e) => setRefDetail(prev => ({...prev, refName: e.target.value}))}
                    />
                </DialogContent>
                <DialogActions>
                    <button onClick={handleClose} className='bg-danger text-white border-0 px-3 py-1 rounded shadow'>
                        Cancel
                    </button>
                    <button
                        onClick={AddNawRef}
                        className='custom-common-theme-bg-color text-white border-0 px-4 py-1 rounded shadow'
                    >
                        Add
                    </button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
