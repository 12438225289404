import * as React from "react";
import Sidbarv2 from "../../Workspace-v2/Sidbarv2";
import Button from "@mui/material/Button";
import "../../Workspace-v2/sidebarv2.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddIcon from "@mui/icons-material/Add";
import { Navbar } from "../../../Components/Navbar";
import { GetTeamAccess, addworkspaceteamMember, deleteworkspaceteamMember, getMeber, getMemberbyPreparerId, getworkspaceteamMember,getPermission, getChildUser,addTeamMemberDraft,getTeamMemberDraft, getActivePlan, getMemberForFreePlans } from "../ApiServices/apiHandler";
import { useState ,useEffect} from "react";
import { useHistory, useLocation } from "react-router";
import { Box, FormControl, MenuItem, Modal, Select } from "@mui/material";
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import swal from "sweetalert";
// import SearchIcon from '@mui/icons-material/Search';
import { socket } from '../../../services/socket/socket';

const stylemodal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  height:250,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p:2,
};

const TeamMemebers = (props) => {
  const users = JSON.parse(localStorage.getItem('user'));
  const [acessTeam, setAccess] = useState([]);
  const [permission,setPermission]=useState([]);
  const [allmember, setAllmember] = useState([]);
  const [userId, setUserId] = useState('');
  const [teamMember,setTeamMember]=useState({});
  const [openModal, setOpenModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openRemarkModal, setOpenRemarkModal] = useState(false);
  const [reason,setReason]=useState('');
  const [approvalId,setApprovalId]=useState([]);
  const [approverId,setApproverId]=useState({});
  const [isClicked,setIsChecked]=useState(false);
  const [draftTeam,setDraftTeam]=useState({});
  const [ActivePlan,setActivePlan]=useState("");

  const location = useLocation();
  let history = useHistory();
  let a = location.pathname;
  let w_id = a.split("/");
  useEffect(() => {
    teamAccess();
    usersData();
    getApprovalIds();
    getDraftedteamMembers();
    getworkspaceteamMembers();
    getPermissionOfWorkspace();
    activePlan();
    if(w_id.includes('TeamMemebers')){
      localStorage.setItem("modifyAccess", 'TeamMemebers');
    }
  }, [])
  

  const handleChange = (event,value) => {
    const newValue = event.target.value;
    setUserId(newValue);
  }

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenCancelModal = () => setOpenCancelModal(true);
  const handleCloseCancelModal = () => setOpenCancelModal(false);
  const handleOpenRemarkModal = () => setOpenRemarkModal(true);
  const handleCloseRemarkModal = () => setOpenRemarkModal(false);

  const teamAccess = async () => {
    const res = await GetTeamAccess();
    if (res.status === 200) {
      setAccess(res.data.getAccess)
    }
  }
  const getPermissionOfWorkspace = async () => {
    const workspace_id = w_id[2];
    const res = await getPermission(workspace_id);
    if (res.status === 200) {
      setPermission(res.data.response.workspace_access)
    }
  }
  const usersData = async () => {
    if (users.responsibilities==="preparer"){
      const parent_id = users.parent_id;
      const res = await getMemberbyPreparerId(parent_id);
      setAllmember(res.data.mergedData);
    }else{
    const res = await getMemberForFreePlans();
    setAllmember(res.data.fetchMember);
    }
  };
  const getworkspaceteamMembers = () => {
    const workspace_id = w_id[2];
    getworkspaceteamMember(workspace_id)
      .then((result) => {
        if (result.status === 200) {
          setTeamMember(result.data.response?.user_ids);
        }
      })
      .catch(error => {
        swal("Error",  "Get Workspace Team Failed", "error")
      });
  };

  const handleAdd = () => {
    if(users.responsibilities==="preparer"){
      if(userId===''){
        swal("ERROR","Select a Team Member","error");
        setIsChecked(false);
        return false;
      }else{
        swal("ERROR","Send Approval to an Approver to add Team Member","error");
        setIsChecked(true);
      return false;
      }
    }
    if(ActivePlan==="Free Plan"){
      swal("Warning","Upgrade Your Subscripsion plan to add Team member","warning");
      return false;
    }
    const datas = {
        workspace_id:w_id[2],
        user_id:userId
        };
        addworkspaceteamMember(datas).then(res => { 
        setUserId('');
        getworkspaceteamMembers();
        if(res.data.response.user_exists===true){
          swal("ERROR","Team member Already exists","error");
        }else{
          swal("Success","Team Added Sucessfully","success");
        }
      }).catch(error => {
        swal("Error", error.response.data.error, "error")
      });
  };

  const handleRemove = (u_id) => {
    if(ActivePlan==="Free Plan"){
      swal("Warning","Upgrade Your Subscripsion plan to remove Team member","warning");
      return false;
    }
    const datas = {
      workspace_id:w_id[2],
      user_id:u_id
      };
      deleteworkspaceteamMember(datas).then(res => { 
      swal("Success","Team member Removed Sucessfully","success");
      getworkspaceteamMembers();
    }).catch(error => {
      swal("Error", error.response.data.error, "error")
    });
  };

  const handleApproverChange = (event) => {
    const newValue = event.target.value;
    setApproverId(newValue);
  };

  const getApprovalIds = async()=>{
    const parent_id = users.parent_id;
    const workspace_id =w_id[2];
      getChildUser(parent_id,workspace_id)
        .then(response => {
          setApprovalId(response.data.result);
        })
        .catch(error => {
          console.error('Error:', error);
        });
  }

 const sendForApproval = async()=>{
  const datas = {
    workspace_id:w_id[2],
    user_id:userId?userId:draftTeam?.user_id,
    preparer_id:users._id,
    approver_id:approverId,
    status:"draft"
    };
    addTeamMemberDraft(datas).then(res => { 
      setUserId('');
      getDraftedteamMembers();
      swal("Success","Send Approval to ADD TeamMember Sucessfully","success");
      handleCloseModal();
      socket.emit("add-notification", {
        receiver_id: approverId,
        description: "Team Member",
        type:"Send approval",
        path:"/Notification"
      });
      socket.emit('get-notification');
    }).catch(error => {
      swal("Error", error.response.data.error, "error")
    });
 }
 const handleApprove = async()=>{
  const datas = {
    workspace_id:w_id[2],
    preparer_id:draftTeam?.preparer_id,
    approver_id:draftTeam?.approver_id,
    user_id:draftTeam?.user_id,
    status:"approved",
    cancel_reason:""
    };
    addTeamMemberDraft(datas).then(res => { 
      swal("Success","Approval Approved Sucessfully","success");
      getDraftedteamMembers();
      getworkspaceteamMembers();
      socket.emit("add-notification", {
        receiver_id: draftTeam?.preparer_id,
        description: "Team Member",
        type:"approved approval",
        path:"/Notification"
      });
      socket.emit('get-notification');
    }).catch(error => {
      swal("Error", error.response.data.error, "error")
    });
 }
 const handleCancel = async()=>{
  const datas = {
    workspace_id:w_id[2],
    preparer_id:draftTeam?.preparer_id,
    approver_id:draftTeam?.approver_id,
    user_id:draftTeam?.user_id,
    status:"cancelled",
    cancel_reason:reason
    };
    addTeamMemberDraft(datas).then(res => { 
      swal("Warning","Approval Cancelled Sucessfully","warning");
      handleCloseCancelModal();
      getDraftedteamMembers();
      socket.emit("add-notification", {
        receiver_id: draftTeam?.preparer_id,
        description: "Team Member",
        type:"Cancelled approval",
        path:"/Notification"
      });
      socket.emit('get-notification');
    }).catch(error => {
      swal("Error", error.response.data.error, "error")
    });
 }

 const getDraftedteamMembers = () => {
  getTeamMemberDraft(w_id[2])
    .then((result) => {
      if (result.status === 200) {
        setDraftTeam(result.data.result);
      }
    })
    .catch(error => {
      console.log("Error",  "Get Workspace Team Failed", "error")
    });
};

//current active plan Subscribed
  const activePlan = async () => {
    try {
      const res = await getActivePlan();
      setActivePlan(res.data.plan_name);
    } catch (error) {
      console.log(error.message)
    }
  }
  // modify access function
  const modifyAccessButton =(data)=>{
    if(ActivePlan==="Free Plan"){
      swal("Warning","Upgrade Your Subscripsion plan to Modify Access","warning");
      return false;
    }
    if(permission && permission.length > 0 && permission[0].addteam_member===false){
    swal("Warning","You Don't have permissions","warning");
     return false 
    }else if( permission && permission.length === 0){
      swal("Warning","You Don't have permissions","warning");
      return false
    }else{
      history.push(`/modifyacess/${data._id}/${w_id[2]}`)
    }
    if(data.responsibilities === "approver"||data.responsibilities === "view"){
      history.push(`/functionalityacess/${data._id}/${w_id[2]}`)
    }
  }

  return (
    <>
      <div>
        <div>
          <Navbar />
        </div>
        <div className="d-flex">
          <div>
            <Sidbarv2
              w_id={w_id}
            />
          </div>
          {/* <div class="px-4 w-100 mt-3">
            <div className="d-flex justify-content-between mt-4">
            <p className="dm-sans fs-head">Team Members</p>
            <Button
            className="font-sans"
                  style={{
                    height: "40px",
                    width: "250px",
                    border: "2px solid #03565A",
                    color: "#03565A",
                    textTransform: "none",
                    borderRadius: "5px",
                    fontWeight: "normal",
                  }}
                >
                  <span style={{ marginRight: "8px" }}>
                    <AddIcon />
                  </span>
                  Invite Team Member
                </Button>
            </div>
            <TableContainer component={Paper} className="wpTableContainer">
              <Table sx={{ minWidth: 650 }} aria-label="caption table">
                <TableHead>
                <div
                    style={{
                      width: "100%",
                      height: "50px",
                      marginLeft: "15px",
                    }}
                  >
                      <div
                        className="d-flex align-items-center"
                        style={{
                          border: "1px solid gray",
                          borderRadius: "7px",
                          marginTop:"5px"
                        }}
                      >
                        <SearchIcon style={{
                            paddingLeft: "5px",
                          }}/>
                        <input
                          style={{
                            border: "none",
                            outline: "none",
                            marginTop: "0px",
                            padding:"10px",
                            borderRadius: "7px",
                            width:"100%"
                          }}
                          type="search"
                          name="search"
                          placeholder="Search for a team member"
                        />
                      </div>
                  </div>
                  <TableRow>
                    <TableCell className="font-inter">TEAM MEMBER</TableCell>
                    <TableCell className="font-inter" align="right">ACCESSTYPE</TableCell>
                    <TableCell className="font-inter" align="right">INVITE STATUS</TableCell>
                    <TableCell className="font-inter" align="right">ACTION</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {acessTeam.length > 0 && acessTeam.map((dat) => {
                    return (<>
                      <TableRow>
                      <TableCell
                      component="th"
                      scope="row"
                      style={{ backgroundColor: "white" }}
                      className="font-sans"
                    >
                      {dat?.email}
                    </TableCell>
                    
                        <TableCell align="right" className="font-sans">{dat?.access}</TableCell>
                        <TableCell align="right">
                          <Badge className="badgePending font-sans">pending</Badge>
                        </TableCell>
                        <TableCell align="right">
                          <MoreVertIcon />
                        </TableCell>
                      </TableRow>
                    </>)
                  })}</TableBody>
              </Table>
            </TableContainer>
          </div> */}
            <div className="d-flex flex-column w-100">
              <div className="container">
                  <div className="row m-5 justify-content-between">
                      <div className="col">
                        <p className="font-sans text-center">User ID:</p>
                      </div>
                      <div className="col">
                        <FormControl fullWidth>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={userId|| ''}
                            onChange={handleChange}
                            disabled={permission && permission.length > 0 && permission[0].addteam_member===false || users.responsibilities==="approver"||permission && permission.length === 0||users.responsibilities==="view"}
                            >
                              {allmember && allmember.length>0 && allmember.map((data)=>
                              <MenuItem
                              key={data._id} 
                              value={data._id}
                              >
                                {data.email}({data.responsibilities?data.responsibilities: "admin"})
                              </MenuItem>
                            )}
                            </Select>
                        </FormControl>
                      </div>
                      <div className="col">
                        <Button
                            style={{
                              color: "black",
                              textTransform: "none",
                              marginTop: "17px",
                            }}
                            onClick={()=>{handleAdd()}}
                            disabled={permission && permission.length > 0 && permission[0].addteam_member===false || users.responsibilities==="approver"||permission && permission.length === 0||users.responsibilities==="view"}
                          >
                            <AddIcon />
                            <span className="font-sans">Add into Workspace</span>
                        </Button>
                      </div>
                  </div>
              </div>
              <div className="mt-4">
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow style={{ backgroundColor: "#b5b3b3" }}>
                        <TableCell style={{border:"none"}}align="center" className="font-sans">S.No</TableCell>
                        <TableCell style={{border:"none"}} align="center"className="font-sans">Name</TableCell>
                        <TableCell style={{border:"none"}} align="center"className="font-sans">Company</TableCell>
                        <TableCell style={{border:"none"}} align="center"className="font-sans">Actions</TableCell>
                        <TableCell style={{border:"none"}} align="center"className="font-sans">ID</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {teamMember && teamMember.length>0  && teamMember.map((data,index)=>
                      <TableRow>
                        <TableCell component="th" scope="row" style={{border:"none"}}>{index+1}</TableCell>
                        <TableCell align="center" style={{border:"none"}}className="font-sans">{data.name?data.name:"Unknown"}</TableCell>
                        <TableCell align="center" style={{border:"none"}}>Not Listed</TableCell>
                        <TableCell align="center" style={{border:"none"}}>
                          <div className="d-flex flex-row justify-content-center">
                          <div style={{color:"#03565a" ,cursor:"pointer"}} 
                          onClick={()=>modifyAccessButton(data)}>
                          <MiscellaneousServicesIcon/> Modify access
                          </div>
                          <div style={{color:"#03565a",cursor:"pointer"}} className="ml-3"  onClick={
                            users.responsibilities === "approver"||users.responsibilities==="view" ? null:permission && permission.length > 0 && permission[0].addteam_member===false? null: permission && permission.length === 0? null:
                            () => handleRemove(data._id)}>
                          <DoDisturbOnIcon/>Remove user
                          </div>
                          </div>
                        </TableCell>
                        <TableCell align="center" style={{border:"none"}}className="font-sans">{data.email}</TableCell>
                      </TableRow>
                    )}
                    {draftTeam?.status==="draft"?<>
                       <TableCell component="th" scope="row" style={{border:"none",color:"rosybrown"}}>{teamMember.length+1}</TableCell>
                       <TableCell align="center" style={{border:"none",color:"rosybrown"}}className="font-sans">{draftTeam?.user_id?.name}</TableCell>
                       <TableCell align="center" style={{border:"none",color:"rosybrown"}}>Not Listed</TableCell>
                       <TableCell align="center" style={{border:"none",color:"rosybrown"}}className="font-sans">Not Allocated</TableCell>
                       <TableCell align="center" style={{border:"none",color:"rosybrown"}}>{draftTeam?.user_id?.email}</TableCell>
                       </>:<></>}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div>
              { users.responsibilities==="preparer"&&isClicked===true?
              <div className="d-flex justify-content-end mt-3">
                <Button
                  className="mr-4 text-capitalize rounded text-white w-20 custom-common-theme-bg-color"
                  onClick={handleOpenModal}
                  disabled={draftTeam?.status==="draft"}
                >
                  {draftTeam?.status==="draft"?"Already Sent For Approval":"Send For Approval"}
                </Button>
                <Button
                  className="mr-4 text-capitalize rounded text-white custom-common-theme-bg-color w-20"
                  onClick={handleOpenRemarkModal}
                >
                  Remark
                </Button>
              </div>:users.responsibilities==="approver"&& draftTeam?.status==="draft" ?
              <>
              <div className="d-flex justify-content-end mt-3">
                <Button
                  className="mr-4 text-capitalize rounded text-white save-button custom-common-theme-bg-color"
                  onClick={handleApprove}
                >
                  Approve
                </Button>
                <Button
                  className="mr-4 text-capitalize rounded text-white save-button custom-common-theme-bg-color"
                  onClick={() =>
                   handleOpenCancelModal() 
                 }
                >
                  Cancel
                </Button>
              </div>
              </>:<></>
             }
              </div>
            </div>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                  <Box sx={stylemodal}>
                        <FormControl fullWidth className="mt-5">
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={approverId|| ''}
                              onChange={handleApproverChange}
                            >
                            {approvalId && approvalId.length>0&&approvalId.map((data)=>
                              <MenuItem key={data._id} value={data._id}>{data.name}</MenuItem>
                            )}
                            </Select>
                        </FormControl>
                        <div className="d-flex flex-row justify-content-center mt-3 ">
                            <Button style={{
                                backgroundColor: "#03565a",
                                color: "white",
                                textTransform: "none",
                                margin:"10px",
                                height:"36px",
                                width:"50%"
                              }}
                              className="font-sans"
                              onClick={handleCloseModal}
                              >
                                Return
                            </Button>
                            <Button
                              style={{
                                backgroundColor: "#03565a",
                                color: "white",
                                textTransform: "none",
                                width:"50%",
                                height:"36px",
                                marginTop:"10px"
                              }}
                              disabled={!approverId}
                              className="font-sans"
                              onClick={sendForApproval}
                             >
                              Send for approval
                            </Button>
                        </div>
                  </Box>
            </Modal>
            <Modal
              open={openCancelModal}
              onClose={handleCloseCancelModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              >
              <Box sx={stylemodal}>
                  <div>
                    <label className="font-sans">Cancel Reason:</label>
                    <textarea 
                    className="w-100"
                    value={reason}
                    onChange={(event)=>setReason(event.target.value)}
                    />
                  </div>
                  <div className="d-flex  justify-content-center mt-3 ">
                    <Button
                      style={{
                      backgroundColor: "#03565a",
                      color: "white",
                      textTransform: "none",
                      width:"50%",
                      marginTop:"10px"
                      }}
                      className="font-sans"
                      onClick={handleCancel}
                    >
                      Submit
                    </Button>
                </div>
              </Box>
           </Modal>
           <Modal
              open={openRemarkModal}
              onClose={handleCloseRemarkModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              >
              <Box sx={stylemodal}>
              <div className="d-flex flex-row-reverse font-sans" onClick={handleCloseRemarkModal} style={{cursor:"pointer"}}>Close</div>
                  <div style={{maxHeight:"150px",overflowY:"auto"}}className="mt-4">
                    {draftTeam?.cancel_reason?
                    <p className="font-sans">{draftTeam?.cancel_reason}</p>:
                    <center> There are no remarks </center>}
                  </div>
              </Box>
          </Modal>
        </div>
      </div>
    </>
  );
};
export default TeamMemebers;
