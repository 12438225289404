import React, { useState } from "react";
import Sidbarv2 from "../../Workspace-v2/Sidbarv2";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import inrlogo from "../../../assets/inrlogo.svg";
import AddIcon from "@mui/icons-material/Add";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import inrlogo2 from "../../../assets/inrlogo2.svg";
import inrlogo3 from "../../../assets/inrlogo3.svg";
import { Navbar } from "../../../Components/Navbar";
import {
  GetAllProject,
  getOneAccessWorkspacedata,
} from "../ApiServices/apiHandler";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { fetchWSData } from "../../../redux/actions/workspace/actionWorkspace";
import LoaderTwo from "../../../Components/Common/Loader/LoaderTwo";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Overview = (props) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const [allProject, setAllProjectData] = useState([]);
  const [oneAccess, setOneAccess] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  let a = location.pathname;
  let w_id = a.split("/");
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const workspace_data =  useSelector((state) => state.WSReducer);
  const [loader,setLoader] = useState("");



  useEffect(() => {
    if(workspace_data.isSuccess === false){
      dispatch(fetchWSData(w_id[2]));
    }
    getAllProject();
    getOneAccessWorkspace();
  }, []);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // const handleClickProject = ()=>{
  //   const auth = localStorage.getItem("auth_token");
  //   let headers = {
  //     "x-auth-token": auth,
  //   };
  //   axios
  //     .get(`api/v1/tb-onboarding/get-tb/${id}`, { headers })
  //     .then((response) => {
  //       // dispatch({type: actionTypes.SET_RESTART,payload:'arr'})
  //       // dispatch({type: actionTypes.SET_NOTERESTART,payload:'arr'})
  //       dispatch({ type: actionTypes.SET_TB_ID, payload: response.data.tb_id });
  //       window.localStorage.setItem("tb_id", response.data.tb_id);
  //       localStorage.setItem("project_id", response.data.project_id);
  //       axios
  //         .get(`api/v1/grouping/fs/${id}`, { headers })
  //         .then((res) => {
  //           console.log(res, "resoution resoution");
  //           console.log(res.data.fs_grp, "resoution");
  //           if (res.data.fs_grp === []) {
  //             return;
  //           }
  //           const gp_data = {};
  //           gp_data.grp = res.data.fs_grp;
  //           dispatch({
  //             type: actionTypes.SET_EDITSUBGROUPING,
  //             payload: gp_data,
  //           });
  //         })
  //         .catch((error) => {
  //           console.log(error.response, "error");
  //         });
  //       history.push(`/createProject/DataMapping/${id}`);
  //     })
  //     .catch((error) => {
  //       console.log(error.response, "error");
  //       swal("", "Cannot found any TB inside this project", "error");
  //     });
  // }
  const getAllProject = async () => {
    setLoader(true);
    const res = await GetAllProject(w_id[2]);
    if (res.status === 200) {
      setAllProjectData(res.data.project);
    setLoader(false);
    }
  };

  const handleData = () => {
    if(user.responsibilities==="approver"||user.responsibilities==="view"){
      swal("Warning", "You Don't have Access to Create Financial", "warning");
    }else{
    history.push(`/createProject/QuestionnaireNew/${w_id[2]}`);
    }
  };

  const getOneAccessWorkspace = async () => {
    const res = await getOneAccessWorkspacedata(w_id[2]);
    
    if (res.status === 200) {
      setOneAccess(res.data.getOneAccessWorkspace[0]);
    }
  };

  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div className="d-flex">
        <div>
          <Sidbarv2 w_id={w_id} />
        </div>
        {loader ?<LoaderTwo />: allProject.length > 0 ? (
          <div className="w-100 h-100 px-3">
            <div className="d-flex justify-content-between mt-4">
            <p className="dm-sans fs-head">Workspace Overview</p>
            
            </div>

              <div className="row">
                  <div className="col-4">
                  <Card style={{ width: "100%", height: "125px" }}>
                  <CardContent>
                      <div className="Card_v9">
                        <img src={inrlogo} alt="logo" />
                      </div>
                      <div className="mt-2 ml-1 font-weight-bold">
                        <div className="font-inter">INR 0</div>
                        <small className="mt-0 fs-6">Revenue</small>
                      </div>
                    </CardContent>
                  </Card>
                  </div>
                  <div className="col-4">
                  <Card style={{ width: "100%", height: "125px" }}>
                  <CardContent>
                      <div className="Card_v9">
                        <img src={inrlogo2} alt="logo" />
                      </div>
                      <div className="my-2 ml-1 font-weight-bold">
                        <div  className="font-inter">INR 0</div>
                        <small className="mt-0 fs-6">Expenses</small>
                      </div>
                    </CardContent>
                  </Card>
                  </div>
                  <div className="col-4">
                  <Card style={{ width: "100%", height: "125px" }}>
                  <CardContent>
                      <div className="Card_v9">
                        <img src={inrlogo3} alt="logo" />
                      </div>
                      <div className="mt-2 ml-1 font-weight-bold">
                        <div  className="font-inter">INR 0</div>
                        <small className="mt-0 fs-6">Net Income</small>
                      </div>
                    </CardContent>
                  </Card>
                  </div>
              </div>


              
            <div className="text_v2" style={{ display: "none" }}>
              <div>Financial statement Overview</div>
              {/* <div> View all</div> */}
            </div>
              <div className="w-100" style={{ display: "none" }}>
                <Box>
                  <Card style={{ marginTop: "10px" }} className="tabtoggle">
                    <CardContent>
                      <Box>
                        <Tabs
                          style={{
                            backgroundColor: "#EAECEE",
                            width: "400px",
                            borderRadius: "5px",
                          }}
                          value={value}
                          onChange={handleChange}
                          aria-label="basic tabs example"
                        >
                          <Box sx={{}}>
                            <Tabs
                              sx={{
                                "& button": {
                                  borderRadius: 1,
                                  color: "black",
                                  fontSize: "15px",
                                  fontWeight: "500",
                                },
                                "& button.Mui-selected": {
                                  backgroundColor: "#03565A",
                                  color: "white",
                                },
                              }}
                              value={value}
                              onChange={handleChange}
                              aria-label="basic tabs example"
                            >
                              <Tab
                                sx={{
                                  textTransform: "none",
                                  fontWeight: "400",
                                  width: "200px",
                                }}
                                label="Created by me"
                                {...a11yProps(0)}
                              />
                              <Tab
                                sx={{
                                  textTransform: "none",
                                  fontWeight: "400",
                                  width: "200px",
                                }}
                                label="Shared with me"
                                {...a11yProps(1)}
                              />
                            </Tabs>
                          </Box>
                        </Tabs>
                      </Box>
                    </CardContent>
                  </Card>
                  <CustomTabPanel value={value} index={0}>
                    <TableContainer component={Paper}>
                      <Table sx={{}} aria-label="caption table">
                        <TableHead>
                          <TableRow>
                            <TableCell>FILE NAME</TableCell>
                            <TableCell align="center">ENDED ON</TableCell>
                            <TableCell align="center">
                              GROUPING STATUS
                            </TableCell>
                            <TableCell align="center">LOGICAL ERRORS</TableCell>
                            <TableCell align="center">ACTION</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* {allProject.length > 0 &&
                            allProject.map((det) => {
                              return (
                                <>
                                  <TableRow>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        backgroundColor: "white",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <span
                                        onClick={(e) => {
                                          handleClickProject(e, det._id);
                                        }}
                                      >
                                        {det.project_name}
                                      </span>
                                    </TableCell>
                                    <TableCell align="center">
                                      {moment(det.createdAt).format(
                                        "MMM DD YY"
                                      )}
                                    </TableCell>
                                    <TableCell align="center">
                                      <Badge className="badgenotstarted">
                                        Not started
                                      </Badge>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Badge className="badgePending">
                                        10 identified
                                      </Badge>
                                    </TableCell>
                                    <TableCell
                                      style={{ backgroundColor: "white" }}
                                      align="center"
                                    >
                                      <MoreVertIcon onClick={handleClick} />
                                      <Popover
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "left",
                                        }}
                                      >
                                        <Typography sx={{ p: 2 }}>
                                          <span>Edit</span> <br />
                                          <span>Delete</span>
                                        </Typography>
                                      </Popover>
                                    </TableCell>
                                  </TableRow>
                                </>
                              );
                            })} */}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <TableContainer component={Paper}>
                      <Table sx={{}} aria-label="caption table">
                        <TableHead>
                          <TableRow>
                            <TableCell>FILE NAME</TableCell>
                            <TableCell align="left">ENDED ON</TableCell>
                            <TableCell align="center">
                              GROUPING STATUS
                            </TableCell>
                            <TableCell align="center">LOGICAL ERRORS</TableCell>
                            <TableCell align="center">ACTION</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ backgroundColor: "white" }}
                            >
                              hasgsaj
                            </TableCell>
                            <TableCell
                              style={{ backgroundColor: "white" }}
                              align="left"
                            >
                              345678
                            </TableCell>
                            <TableCell
                              style={{ backgroundColor: "white" }}
                              align="center"
                            >
                              <button className="table_btn">Not started</button>
                            </TableCell>
                            <TableCell
                              style={{ backgroundColor: "white" }}
                              align="center"
                            >
                              {" "}
                              <button className="table_btn_v1">
                                Not started
                              </button>
                            </TableCell>
                            <TableCell
                              style={{ backgroundColor: "white" }}
                              align="center"
                            >
                              <MoreVertIcon onClick={handleClick} />
                              <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                              >
                                <Typography sx={{ p: 2 }}>
                                  <span>Edit</span> <br />
                                  <span>Delete</span>
                                </Typography>
                              </Popover>
                            </TableCell>
                          </TableRow> */}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CustomTabPanel>
                </Box>
              </div>
          </div>
        ) : (
          <div className="w-100 h-100 px-5 mt-5 ">
            <div className="d-flex justify-content-between w-100 ">
              <div style={{ width: "50%", marginTop: "60px" }}>
                <div style={{ fontSize: "25px", fontWeight: "600" }}>
                  Welcome to {workspace_data.response[0]?.workspace_name} !
                </div>
                <div
                  style={{ marginTop: "6px", fontSize: "14px", width: "90%" }}
                >
                  Use this space to welcome team member collaborate and simplify
                  your financial task.import registers from our centralized
                  repository and effortlessly complete your reporting process.
                </div>
                <div style={{ marginTop: "25px" }}>
                  {oneAccess?.access === "View" || workspace_data.response[0]?.access_status ==="view" ? (
                    <Button
                      style={{
                        backgroundColor: "grey",
                        color: "white",
                        textTransform: "none",
                        width: "280px",
                        height: "50px",
                        borderRadius:"5px",
                      }}
                      disabled
                    >
                      <span>
                        <AddIcon />
                      </span>
                      Create Financial Statement
                    </Button>
                  ) : (
                    <Button
                      style={{
                        backgroundColor: "#03565A",
                        color: "white",
                        textTransform: "none",
                        width: "280px",
                        height: "50px",
                        borderRadius:"5px",
                      }}
                      onClick={handleData}
                    >
                      <span>
                        <AddIcon />
                      </span>
                      Create Financial Statement
                    </Button>
                  )}
                </div>
              </div>

              {/* <div> */}
              {/* <div>
                <Card className="w-100 h-100 rounded">
                  <CardContent>
                    <div style={{ marginLeft: "20px", marginTop: "10px" }}>
                      <div style={{ fontSize: "17px", fontWeight: "550" }}>
                        Here' s what's next{" "}
                        <span>
                          <ArrowDownwardIcon />
                        </span>
                      </div>
                      <div style={{ marginTop: "15px" }}>
                        <div className="workspace_v3">
                          <div
                            className="circle_V3 "
                            style={{ backgroundColor: "#03565A" }}
                          >
                            <span>
                              <CheckIcon style={{ color: "white" }} />
                            </span>
                          </div>
                          <div className="Layout_v3">Create Workspace </div>
                        </div>
                        <div className="vl_V3 "></div>
                        <div
                          style={{ marginTop: "5px" }}
                          className="vl_V3  "
                        ></div>
                        <div className="workspace_v3">
                          <div
                            className="circle_V3  "
                            style={{ backgroundColor: "#03565A" }}
                          >
                            <span>
                              <CheckIcon style={{ color: "white" }} />
                            </span>
                          </div>
                          <div className="Layout_v3">Invite team members</div>
                        </div>
                        <div className="vl_V3 "></div>
                        <div
                          style={{ marginTop: "5px" }}
                          className="vl_V3  "
                        ></div>
                        <div className="workspace_v3">
                          <div className="circle_V3  ">2</div>
                          <div className="Layout_v3">
                            Create / upload your register
                          </div>
                        </div>
                        <div className="vl_V3 "></div>
                        <div
                          style={{ marginTop: "5px" }}
                          className="vl_V3  "
                        ></div>
                        <div className="workspace_v3">
                          <div className="circle_V3  ">3</div>
                          <div className="Layout_v3">
                            Get Started on your financial tasks
                          </div>
                        </div>
                        <div className="vl_V3 "></div>
                        <div
                          style={{ marginTop: "5px" }}
                          className="vl_V3  "
                        ></div>
                        <div className="workspace_v3">
                          <div className="circle_V3  ">4</div>
                          <div className="Layout_v3">Export your report</div>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Overview;
