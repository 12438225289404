import React from 'react';
// import Checkbox from '@mui/material/Checkbox';

const WorkspaceSettingCheckBox = ({id,onChange,checked,companyName,style, children,disabled}) => {
    return (
        <div className="d-flex justify-content-between align-items-center mt-3 mr-3">
            <div className="d-flex align-items-center">
                <input
                type='checkbox'
                checked={checked}
                disabled={disabled}
                onChange = {onChange}
                id ={id}
                // sx={{
                //     color: "#03565A",
                //     '&.Mui-checked': {
                //         color: "#03565A",
                //     },
                // }}
                style={style}
                />
                { children }
            </div>
            <div> {companyName} </div>
        </div>
    )
}

export default WorkspaceSettingCheckBox