import React, { useContext, useEffect, useMemo, useState } from "react";
import {  useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Fade from "@material-ui/core/Fade";
import Tooltip from "@material-ui/core/Tooltip";
import { logout } from "../authentication/authUtilities";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import Badge from '@mui/material/Badge';
import NightlightIcon from "@mui/icons-material/Nightlight";
import Stack from '@mui/material/Stack';
import {  toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
// import PersonAdd from '@material-ui/icons/PersonAdd';
// import Settings from '@material-ui/icons/Settings';
// import Logout from '@material-ui/icons/Logout';

import styled from "styled-components";
import { Link } from "react-router-dom";

import LogoWhite from "../assets/navlogo.png";
import { ThemeContext } from "../helper/DarkModeContext";
import SearchPopover from "./Library/SearchPopover";
import { getProfile, logoutUser } from "../services/api/apiHandler";

import { socket } from '../services/socket/socket';
import PushNotification from "../WorkSpace/Components/Modal/PushNotification";
import { GetUser } from "../WorkSpace/Components/ApiServices/apiHandler";
  
const Nav = styled.div`
  width: 100%;
  background-color: #03565a;
  height: 80px;
  color: white;
  font-family: inherit;
  display: flex;
  padding: 0 2rem 0 2rem;
  align-items: center;
  position: sticky;
  top: 0%;
  z-index: 99;
`;
const Navlinks = styled.div`
  display: flex;
  margin-left: calc(18vw);
  & > ul {
    display: flex;
    align-items: center;
    margin: 0;
  }
  & > ul > li {
    margin-left: 2.8rem;
    text-decoration: none;
    text-emphasis: none;
    list-style: none;
    font-weight: 300;
    font-size: 15px;
    font-stretch: expanded;
  }
  & a {
    color: white;
  }

  & div {
    position: absolute;
  }
  & div img {
    border: 1px solid white;
  }
`;
// const Title = styled.h1`
//     font-size: 1.5rem;
//     font-weight: 700;
//     line-height: 36px;
//     font-size: 24px;

// `
const Profile = styled.div`
  margin-left: auto;
  display: flex;
  /* margin-right:2rem; */
  /* width: 3.5rem; 
    height: 3.5rem;
    border-radius: 50%; */
  /* background-color:black;   */
`;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};


export const Navbar = (props) => {
  const history = useHistory();

  const [random, setRandom] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [renameProfile, setRenameProfile] = useState(false);
  const open = Boolean(anchorEl);
  const [dataProfile, setProfileData] = useState({
    email: "",
  });
  const [pathName, setPathName] = useState('');
  const [unreadNotifications,setUnreadNotification]=useState([]);
  const [notifications,setNotifications]=useState([]);

  const { theme, setTheme } = useContext(ThemeContext);
  // const user = JSON.parse(localStorage.getItem('user'));
  const [user,setUser] = useState(JSON.parse(localStorage.getItem('user')))
  const [allUser, setAllUser] = useState([]);
  // const user = JSON.parse(localStorage.getItem('user'));
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = (ids) => {
    socket.emit("read-notification", { notification_id: ids }, (response) => {
      if (response.success) {
        socket.emit('get-unread-notification');
        socket.emit('get-notification');
      } else {
        console.error("Error reading notification:", response.error);
      }
    });
    setOpenModal(true);
  };
  const handleCloseModal = () => setOpenModal(false);

  const getNotificationsIds = () => {
    const firstEightNotifications = notifications.slice(0, 8);
    const ids = firstEightNotifications.map(notification => notification._id);
    return ids;
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setRenameProfile(false);
  };
  const Image = useSelector(
    (initialState) => initialState.reducer.profileImage
  );
  const Name = useSelector((initialState) => initialState.reducer.profileName);
  const Email = useSelector(
    (initialState) => initialState.reducer.profileEmail
  );

  useEffect(() => {
    setPathName(history.location.pathname);
    getProfile().then(response => {
      if (response.status === 200) {
        setProfileData({
          email: response.data.user.data.email,
        });
      }
    })
    setRandom(Math.random());
  }, []);

  useEffect(() => {
    socket.emit('get-notification');

    socket.on('get-notification', (data) => {
      if (!data.success) {
        console.log("Error", data.message, "error");
      } else {
        setNotifications(data.allNotifications);
      }
    });

    return () => {
      socket.off('get-notification');
    };
  }, []);
  useEffect(() => {
    socket.emit('get-unread-notification');

    socket.on('get-unread-notification', (data) => {
      if (!data.success) {
        console.log("Error", data.message, "error");
      } else {
        setUnreadNotification(data.allNotifications);
      }
    });

    return () => {
      socket.off('get-unread-notification');
    };
  }, []);
  useEffect(() => {
    GetAllUser();
  }, []);

  const userName = (u_id) => {
      const nameUser = allUser.filter((adj) => adj._id === u_id);
      const result = nameUser[0]?.name
        ? nameUser[0]?.name
        : "unknown";
      return result;
  };

  const GetAllUser = async () => {
    const res = await GetUser();
    if (res.status === 200) {
      setAllUser(res.data.fetchUser);
    }
  };

  function handlelogout() {
    logoutUser().then(response => {
      toast.success("Logout Successfully");
      logout();
      history.push("/");
      setAnchorEl(null);
    }).catch(error => {
      toast.error(error.response?.data?.error || "Logout Failed!");
    })
  }
  function handlelprofile() {
    history.push("/profile-change");
    setAnchorEl(null);
  }

  function handleSubscription() {
    history.push("/subscription");
    setAnchorEl(null);
  }

  function handleSupportV() {
    history.push("/support");
    setAnchorEl(null);
  }


  function handleProfile() {
    // console.log(data);
    setRenameProfile(true);
    // setRenameData({
    //   id: id,
    //   email: email,
    //   password: password,
    // });
  }

  function handleProfileChange(e) {
    console.log(e);
  }

  const navItems = useMemo(() => {
    const items = [
      {
        label: 'Home',
        href: "/dashboard"
      },
      {
        label: 'Company',
        href: "/allCompany"
      },
      {
        label: 'Library',
        href: "/library"
      },
      {
        label: 'AuditLogs',
        href: "/logs"
      }
    ];
  
    if (user.isAdmin === "Yes" && user.has_subscribed !== "free") {
      items.push(
        // {
        //   label: 'Admin Portal',
        //   href: "/IAM"
        // },
        {
          label: 'Admin Portal',
          href: "/admin"
        }
      );
    } else {
      items.push({
        label: 'Notification center',
        href: "/notification"
      });
    }
  
    return items;
  }, [user.isAdmin,user.has_subscribed]);
  

  return (
    <Nav>
      <div>
        <img
          src={LogoWhite}
          style={{ height: "50px" }}
          alt="logo"
          onClick={() => history.push("/dashboard")}
        /> 
      </div>
      {/* <Title>{props.text}</Title> */}
      <Navlinks>
        <ul>
          {!props.home && navItems.map((item, ix) => (
            <li key={ix}>
              <Link to={item.href}>{item.label}</Link>
            </li>
          ))
          }
        </ul>
      </Navlinks>
      
      
      <Profile>
        {/* <Button
          variant="text"
          sx={{ outline: "none", border: "none" }}
          onClick={() => {
            theme ? setTheme(false) : setTheme(true);
          }}
        >
          <h1>
            {theme ? (
              <NightlightIcon />
            ) : (
              <WbSunnyIcon sx={{ color: "yellow" }} />
            )}
          </h1>
        </Button> */}
        <IconButton size="large" aria-label="show new mails" color="inherit">
        <Badge badgeContent={unreadNotifications?.length} color="error">
          <ModeCommentOutlinedIcon onClick={()=>handleOpenModal(getNotificationsIds())}/>
        </Badge>
       </IconButton>
        {
          pathName === "/library-indas-1" && <div className="ml-2"> <SearchPopover/> </div>
        }
        <Tooltip title="Profile">
          <IconButton
            onClick={handleClick}
            size="large"
            // sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            sx={{ width: 56, height: 56 }}
          >
            <Stack direction="row" spacing={2}>
              <Avatar >{dataProfile.email.slice(0, 1).toUpperCase()}</Avatar>
            </Stack>
          </IconButton>
        </Tooltip>

        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
          transformOrigin={{ horizontal: "right", vertical: "bottom" }}
        // anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {/* <MenuItem style={{ cursor: "not-allowed" }}>Settings</MenuItem> */}
          {/* {props.home && ( */}
          {user?.responsibilities && <MenuItem  className="font-sans ">{`Iam a ${user?.responsibilities.charAt(0).toUpperCase()+ user?.responsibilities.slice(1)}`}</MenuItem>}
          <MenuItem onClick={handlelprofile} className="font-sans ">Profile</MenuItem>
          {user?.isAdmin !== "No" && (<MenuItem  className="font-sans " onClick={handleSubscription}>Subscription</MenuItem>)}
          <MenuItem className="font-sans">Settings</MenuItem>
          <MenuItem className="font-sans" onClick={handleSupportV}>Support</MenuItem>
          <MenuItem onClick={handlelogout} className="font-sans ">Logout</MenuItem>
          <MenuItem onClick={() => {
            theme ? setTheme(false) : setTheme(true);
          }}>
            {theme ? (
              <NightlightIcon />
            ) : (
              <WbSunnyIcon sx={{ color: "yellow" }} />
            )}
          </MenuItem>
          {/* )} */}
        </Menu>
      </Profile>
      <PushNotification openModal={openModal} handleCloseModal={handleCloseModal}notifications={notifications} userName={userName}/>
    </Nav>
  );
};
