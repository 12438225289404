import React from "react";
import { Drawer } from "@mui/material";
import  Company from  "../../../src/assets/Company.svg"
import  Sidebars from  "../../../src/assets/Sidebar.png"
import  Homes from  "../../../src/assets/Home.svg"
import  Frame from  "../../../src/assets/Frame.svg"
import  shareLogs from  "../../../src/assets/shareLogs.svg"
import  Settings from  "../../../src/assets/Settings.svg"
const Sidebar = () => {
  return (
    <Drawer
      variant="permanent"
      sx={{
        "& .MuiDrawer-paper": {
          width: 60,
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#fff",
          borderRight: "1px solid #ddd",
        },
      }}
    >
      <div className="d-flex justify-content-center py-3">
      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAfCAYAAABplKSyAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAFjSURBVFhH7ZY9S8NQFIbfOqdxrBkyiZND0an9A3HIFkoqKE4VrF0K/QVxc9DNSbs4aZFuBRUnQboVOnQRnIK0riF7HOyt5uQkFJu0y322896X8HBvvnJBEARYMWs0WAVSQiAlBLm4p8PzfYw+3mn8b1Qlj+3NLRoDcRLuZAzj5Aie79OlhahZVTinTRrzx9F57qUuAAA33XsaAXESy4aVOLb2oRc0GmcGe08I+sMBjYDptj6+vdIYqqLAqTehb2ixnc+XPo2SJZKotBoRyYeLK5SLu4kdToI9jnkoF3doFBL4maMdjkSJ/nAAdzKmceqwEp7vo3RgodJqoHRoofPUo5VUYSWuu3dwv3534PK2HVpPG1aC8lcoC+aSyBopIZASAlZiXcmHZu5jRjM6c5mqKKFZwErYhjl7BesFDe2zc1qBvWfCNkxgevG4Ts2qzjpOPfpDg0U+YGnC7sSykRICKSH4BrpCbmRKpAlBAAAAAElFTkSuQmCC" alt="" />
      </div>

      <div className="d-flex flex-column flex-grow-1 align-items-center" style={{marginTop:'130px'}}>
        
        {[Homes, Company,Sidebars,Settings, shareLogs].map((image, index) => (
          <div key={index} className="d-flex justify-content-center my-3" >
           <img
            src={image}
            alt={`Nav Icon ${index}`}
          />
          </div>
        ))}
      </div>

      <div className="d-flex flex-column align-items-center pb-3">
        <div className="mb-3">
        </div>
        <div>
          <img 
            src={Frame}
            className="rounded-circle img-fluid"
            alt="Avatar"
            style={{ width: "60px", height: "60px" }} 
          />
        </div>
      </div>
    </Drawer>
  );
};

export default Sidebar;
