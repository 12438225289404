import { React, useEffect, useState } from "react";
import NavBarV2 from "../../NavBarV2";
import Card from "react-bootstrap/Card";
import Paper from "@mui/material/Paper";
import Logo from "../../../assets/dashboard-img1.svg";
import Logo1 from "../../../assets/Arrow_04.svg";
import Logo2 from "../../../assets/dashboard-img2.svg";
import Logo3 from "../../../assets/dashboard-img3.svg";
import Logo4 from "../../../assets/Arrow_04-down.svg";
import AddIcon from "@mui/icons-material/Add";
import TableContainer from "@mui/material/TableContainer";
import Tab from "@mui/material/Tab";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import moment from "moment";
import Worklogo from "../../../assets/dashboardworkspace.svg";
import CardContent from "@mui/material/CardContent";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { InputAdornment, TextField } from "@mui/material";
import Table from "react-bootstrap/Table";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import "../../../WorkSpace/SignUp2.css";
// import AddCompanyModal from "../Modal/editCompanyModal";
import "../../../WorkSpace/font.css";
import Tabsystem from "../../Workspace-v2/Tabsystem";
import Tabs from "@mui/material/Tabs";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover from "@mui/material/Popover";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Navbar } from "../../../Components/Navbar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import swal from "sweetalert";
import InvestmentModal from "../Modal/investmentModal";
import { IrRegister } from "../../../Console/Component/services/apiHandler";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import addTeamMemberIcon from "./../../../../src/assets/Image/workspaceIcon/addtmember.svg"
import duplicateIcon from "./../../../../src/assets/Image/workspaceIcon/duplicate.svg"
import renameIcon from "./../../../../src/assets/Image/workspaceIcon/rename.svg"
import deleteIcon from "./../../../../src/assets/Image/workspaceIcon/delete.svg"
import RenameSVGComponent from "../../../Components/Common/SVGComponents/RenameSVGComponent";
import SendApproval from "../Modal/sendApproval";
import SendRequest from "../Modal/sendRequest";


import {
  AllWorkspace,
  DuplicateWorkspace,
  WorkspaceUpdate,
  checkDeleteAccess,
  cities,
  createWorkspaceName,
  deleteIr,
  deleteWorkspace,
  renameWorkspace,
  state,
} from "../ApiServices/apiHandler";
import { useHistory } from "react-router";
import { fetchWorkspace } from "../../../redux/actions/workspace/actionAllWrokspace";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import showDeleteConfirmation from "../../../helper/alertConfirmMessage";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 789,
  height: 451,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

const WorkSpaceData = (props) => {
  const {
    nextStep,
    handleClickData,
    allAcessWorkspace,
    workspace,
    UserName,
    WorkSpaceAll,
    workspaceCy,
    workspacePy,
    allIr,
    nextWorkspace,
    handleOpenInvestment,
    company,
    a11yProps,
    handleClicktab,
    CustomTabPanel,
    setAllIR,
  } = props;
  const dispatch = useDispatch();
  const users = JSON.parse(localStorage.getItem('user'));
  const permissions = JSON.parse(localStorage.getItem('permission'));
  const [value, setValue] = useState(0);
  const [Irid, setId] = useState("");
  const [WSPID, setWSp] = useState("");
  const [WID, setWID] = useState("");
  const [WSPDATA, setWspData] = useState("");
  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [openRequestModal, setOpenRequestModal] = useState(false);
  const [dataFetched,setDataFetched] = useState(false);
  const [rename, setName] = useState("");
  const [renameId, setRenameID] = useState("");
  const [approverId, setApproverId] = useState("");
  const [companyId,setCompanyIds]=useState([]);
  const all_workSpace = useSelector((state) => state.AllWSReducer);
  const allWorkspace = all_workSpace.response;


  useEffect(() => {
    if (!dataFetched) {
      dispatch(fetchWorkspace());
      setDataFetched(true);
    }
  }, [dataFetched]);


  let history = useHistory();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenRenameModal(false);
  };

  const handleApproverChange = (event) => {
    setApproverId(event.target.value);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const opentab = Boolean(anchorE2);
  const idtab = opentab ? "simple-popover" : undefined;

  const handleDeleteIr = async (Ir_id) => {
    const res = await deleteIr(Ir_id);
    if (res.status === 200) {
      setAllIR();
      setAnchorEl(null);
    }
  };
  const handleOpenApprovalModal = () => setOpenApproveModal(true);
  const handleCloseApprovalModal = () => setOpenApproveModal(false);

  const handleOpenRequestModal = () => setOpenRequestModal(true);
  const handleCloseRequestModal = () => setOpenRequestModal(false);

  const handleClosetab = () => {
    setAnchorE2(null);
  };
  const handleEditIr = (ir_id) => {
    console.log(ir_id, "uuuu");
    history.push(`/investmentregisterv2/${ir_id}`);
  };

  const handleSelectIr = (ir_id) => {
    history.push(`/insideworkspace/${ir_id}`);
  };

  const CompanyName = (c_id) => {
    const result = company.filter((adj) => adj._id === c_id);
    return result[0]?.company_name;
  };

  const handleClickWsp = (event, id, data) => {
    setAnchorE2(event.currentTarget);
    setWSp(id);
    setWspData(data);
  };

  const handleViewOnly = async (workspace_id, check) => {
    if (check === "on") {
      let data = {
        access_status: "view",
      };
      const res = await WorkspaceUpdate(data, workspace_id);
      if (res.status === 200) {
        console.log("success");
        swal("", "View Access On", "success");
        setAnchorE2(false);
        dispatch(fetchWorkspace());
      }
    } else if (check === "off") {
      let data = {
        access_status: "off",
      };
      const res = await WorkspaceUpdate(data, workspace_id);
      if (res.status === 200) {
        console.log("success");
        swal("", "View Access Off", "success");
        setAnchorE2(false);
        dispatch(fetchWorkspace());
      }
    }
  };
  const handleChangeData = (e) => {
    setName(e.target.value);
  };
  const submitCompany = async () => {
    const data = {
      workspaceName: rename,
    };
    const result = await renameWorkspace(renameId, data);
    if (result.status === 200) {
      swal("Success","Workspace Renamed Successfully","success");
      setAnchorE2(false);
      setOpenRenameModal(false);
      WorkSpaceAll().then(res => {
        dispatch(fetchWorkspace());
      }).catch(err=>{})
    }
    console.log(result.data);
    if (result.data.status === false) {
      swal("", result.data.message, "error");
    }
  };
  const handleDuplicate = async (workspace_id) => {
    if(users.responsibilities==="approver"||users.responsibilities==="view"){
      swal("warning","You don't have access to duplicate","warning");
     }else{
    const res = await DuplicateWorkspace(workspace_id);
    if (res.status === 200) {
      dispatch(fetchWorkspace());
    }
    handleClosetab();
  }
  };
  const handleAddTeam = (workspace_id) => {
    if(users.responsibilities==="approver"||users.responsibilities==="view"){
      swal("warning","You don't have access to add Team Member","warning");
     }else{
    history.push(`/inviteteammember/${workspace_id}`);
     }
  };
  const handleRename = (workspace_id) => {
    if(users.responsibilities==="approver"||users.responsibilities==="view"){
      swal("warning","You don't have access to rename workspace","warning");
      return;
     }
    setOpenRenameModal(true);
    setRenameID(workspace_id);
  };

  const shareUser = (id) => {
    try {
      const nameAcess = allAcessWorkspace.filter(
        (adj) => adj.workspace_id === id
      );
      const result = nameAcess?.email ? nameAcess?.email : "";
      return result.slice(0, 2);
    } catch (error) {
      console.log(error, "2222");
      return 0;
    }
  };
  
  const handleDelete = async (w_id) => {
    swal({
      title: "Are you sure",
      text: " you want to delete this workspace?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes, archive it!",
      cancelButtonText: "No, cancel please!",
      closeOnConfirm: false,
      closeOnCancel: false,
    }).then(async (result) => {
      if (result === true) {
        const res = await deleteWorkspace(w_id);
        if (res.status === 200) {
          dispatch(fetchWorkspace());
          setAnchorE2(false);
        }
      }
    });
  };

  const checkingPermission=async(w_id)=>{
    const workspace_id = w_id;
    checkDeleteAccess(workspace_id)
        .then(response => {
          if(response.data.missingCompanies.length === 0){
            handleOpenApprovalModal();
            setWID(w_id);
          }else if(response.data.missingCompanies.length===response.data.approvedCompanies.length){
            handleOpenApprovalModal();
            setWID(w_id);
          }else{
            swal("", "You Don't have full access to delete Workspace", "warning");
            handleOpenRequestModal();
            setWID(w_id);
            setCompanyIds(response.data.missingCompanies);
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
  };

  return (
    <>
      {all_workSpace.loading && !all_workSpace.response ? (
        <div className="dahboardHeaderV2">
          <div className="row">
            <div className="col-12 text-center" style={{ paddingTop: "100px" }}>
              <CircularProgress />
            </div>
          </div>
        </div>
      ) : all_workSpace.isSuccess && all_workSpace.response.length > 0 ? (
        <>
          <div className="dahboardHeaderV2">
            <div className="row">
              <div className="col-md-6">
                <h5 className="dbwcardhead">My Workspaces</h5>
              </div>
              <div className="col-6 text-right d-flex align-items-center justify-content-end" style={{ gap: "1rem" }}>
                <div style={{ cursor: "pointer", marginRight: "10px", }} onClick={nextWorkspace}>
                  <div className="myDIV">
                    <ArrowForwardIcon style={{ marginLeft: "10px", }} />
                  </div>
                  <div className="hide">View all</div>
                </div>
                <div>
                  {/* <button className="rwp-btn" onClick={nextStep}>
                    <span>
                      <AddIcon />
                    </span>
                    Create workspace
                  </button> */}
                  {permissions?.companyData[0]?.components[0]?.permission?.create  === true || permissions === null ? (
                      <button className="rwp-btn" onClick={nextStep}>
                        <span>
                          <AddIcon />
                        </span>
                        Create workspace
                      </button>
                    ) : (
                      <button className="rwp-btn" disabled>
                        <span>
                          <AddIcon />
                        </span>
                        Create workspace
                      </button>
                    )
                    }
                </div>
              </div>
            </div>

            <div className="row" style={{ paddingTop: "20px" }}>
              <div className="col-12">
                <div className="row">
                  {allWorkspace.slice(0, 4).map((det) => {
                    return (
                      <>
                        <div className="col-3 mb-4">
                          <Card
                            style={{ boxShadow: "0 8px 8px -4px lightblue", border: "none", borderRadius: "20px" }}
                          >
                            <CardContent>
                              <div className="d-flex justify-content-between">
                                <div
                                  onClick={() => handleClickData(det._id)}
                                  style={{
                                    fontWeight: "600",
                                    cursor: "pointer",
                                  }}
                                >
                                  {det.workspace_name ? det.workspace_name?.length > 18 ? (
                                    <div class="rtooltip" >{det.workspace_name.substring(0, 16).concat('...')}
                                      <span class="rtooltiptext">{det.workspace_name}</span>
                                    </div>
                                  ) : (
                                    <span>{det.workspace_name}</span>
                                  ): <div style={{ fontWeight: "600" }}>
                                  {workspace(det.workspace_id, "id")}
                                  </div>}


                                </div>
                                <div className="d-flex flex-row">
                                <div>
                                {det.access?det.access === "Edit" ?
                                <span className="font-inter rename-icons">
                                    <RenameSVGComponent height={14} width={14} color={'#32a899'} style={{marginBottom:"2px", marginRight:"1px"}} />
                                    CAN EDIT
                                  </span>:
                                  <span className="font-inter view-icons">
                                    <VisibilityIcon style={{ fontSize:"14",color:"#32a899",marginBottom:"2px", marginRight:"2px"}}/>
                                    VIEW ONLY
                                    </span>
                                :""}
                                </div>
                                  {/* {det.access_status === "view" ? (
                                    <VisibilityIcon />
                                  ) : (
                                    // <VisibilityOffIcon />
                                    ""
                                  )} */}
                                  <MoreHorizIcon
                                    onClick={(e) =>
                                      handleClickWsp(e, det._id, det)
                                    }
                                    style={{ cursor: "pointer" }}
                                  />

                                  <Popover
                                    style={{ left: "-103px", boxShadow: "none" }}
                                    id={idtab}
                                    open={opentab}
                                    anchorEl={anchorE2}
                                    onClose={handleClosetab}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                    PaperProps={{ style: { width: '160px', boxShadow: 'none' } }}
                                    className="wpdotmenu"
                                  >
                                    <Typography className="mt-2" sx={{}}>
                                      {/* <span
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                        onClick={() =>
                                          handleViewOnly(WSPID, "off")
                                        }
                                      >

                                        <div className="viewIcon">
                                          <VisibilityIcon className="mt-1 ml-3" style={{ width: "18px", height: "18px", color: "black" }} />
                                          <span className="mt-1 ml-1 font-sans">View Only</span>
                                        </div>
                                      </span> */}
                                      <br />
                                      {WSPDATA.access_status === "view" ? (
                                        <span
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "12px",
                                            color: "grey",
                                          }}
                                        >
                                          {"Add Team Member"}
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "12px",
                                          }}
                                          onClick={() => handleAddTeam(WSPID)}
                                        >
                                          <div className="view-options">
                                            <img
                                              className="icon-img ml-3"
                                              src={addTeamMemberIcon}
                                              alt="Add Team Member Icon"
                                              style={{
                                                filter: "grayscale(100%)",
                                              }}
                                            />
                                            <span className="mt-1 ml-1 font-sans ">Add Team Member</span>
                                          </div>
                                        </span>
                                      )}
                                      <br />
                                      {WSPDATA.access_status === "view" ? (
                                        <span
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "12px",
                                            color: "grey",
                                          }}
                                        >
                                          {"Duplicate"}
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "12px",
                                          }}
                                          onClick={() => handleDuplicate(WSPID)}
                                        >
                                          <div className="view-options">
                                            <img className="icon-img ml-3"
                                              src={duplicateIcon}
                                              alt="Duplicate icon"
                                            />
                                            <span className="mt-1 ml-1 font-sans ">Duplicate</span>
                                          </div>
                                        </span>
                                      )}
                                      <br />
                                      {WSPDATA.access_status === "view" ? (
                                        <span
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "12px",
                                            color: "grey",
                                          }}
                                        >
                                          {"Rename"}
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "12px",
                                          }}
                                          onClick={() => handleRename(WSPID)}
                                        >
                                          <div className="view-options">
                                            <img className="icon-img ml-3"
                                              src={renameIcon}
                                              alt="rename icon"
                                            />
                                            <span className="mt-1 ml-1 font-sans ">Rename</span>
                                          </div>
                                        </span>
                                      )}
                                      <br />
                                      {WSPDATA.access_status === "view" ? (
                                        <span
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "12px",
                                            color: "grey",
                                          }}
                                        >
                                          {"Delete"}
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "12px",
                                          }}
                                          // disabled={users.responsibilities==="approver"}
                                          onClick={() => {
                                            if (users.responsibilities === "preparer"&&WSPDATA.status==="approved") {
                                              checkingPermission(WSPID)
                                            }else if(users.responsibilities==="approver"||users.responsibilities==="view"){
                                              swal("Warning","You Don't have access to delete","warning");
                                              return
                                            } else {
                                              handleDelete(WSPID);
                                            }
                                          }}
                                        >
                                          <div className="view-options"
                                          >
                                            <img className="icon-img ml-3"
                                              src={deleteIcon}
                                              alt="rename icon"
                                            />
                                            <span className="mt-1 ml-1 font-sans ">Delete</span>
                                          </div>
                                        </span>
                                      )}
                                    </Typography>
                                  </Popover>
                                </div>
                              </div>
                              <div
                                onClick={() => handleClickData(det._id)}
                                style={{ fontSize: "12px",color:"#8B909A" }}
                              >
                                <>
                                  {det?.workspace_name ?
                                <span>Created {moment(det.createdAt).fromNow()}</span>  :
                                  <div className="font-inter" style={{fontWeight: "400", fontSize: "12px"}}>
                                    Shared by{" "}
                                    {UserName(det?.user_id).substring(
                                          0,
                                          UserName(det?.user_id).lastIndexOf("@")
                                    )}
                                    </div>}
                                </>
                              </div>
                              {/* <div
                                onClick={() => handleClickData(det._id)}
                                style={{
                                  marginTop: "15px",
                                  fontWeight: "600",
                                  cursor: "pointer",
                                }}
                              >
                                Team Members
                              </div> */}

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                {shareUser(det._id) === "" ? (
                                  ""
                                ) : (
                                  <div
                                    style={{
                                      height: "50px",
                                      width: "50px",
                                      borderRadius: "25px",
                                      backgroundColor: "yellow",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleClickData(det._id)}
                                  >
                                    <div
                                      style={{
                                        marginTop: "14px",
                                        marginLeft: "15px",
                                      }}
                                    >
                                      {shareUser(det._id)}
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div
                                style={{
                                  marginTop: "8px",
                                  fontWeight: "600",
                                  color: " #4F4F4F",
                                  fontSize: "12px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleClickData(det._id)}
                              >
                                {det.standalone_cy
                                  ? <span className="font-inter">CY :
                                  <span className="font-sans">{moment(det.standalone_cy).format( "MMMM DD, YYYY")}</span> </span> 
                                   : 
                                  <span className="font-inter">
                                  CY :<span className="font-sans">{workspaceCy(det.workspace_id, "id")}</span>
                                  </span>
                                }
                              </div>
                              <div
                                style={{
                                  marginTop: "8px",
                                  fontWeight: " 600",
                                  color: " #4F4F4F",
                                  fontSize: "12px",
                                  cursor: "pointer",

                                }}
                                onClick={() => handleClickData(det._id)}
                              >
                                {det.standalone_py
                                  ? <span className="font-inter">PY : <span className="font-sans">{moment(det.standalone_py).format("MMMM DD, YYYY")}</span></span> 
                                  : 
                                    <span className="font-inter">
                                    PY :<span className="font-sans">{workspacePy(det.workspace_id, "id")}</span>
                                    </span>
                                  }
                              </div>
                            </CardContent>
                          </Card>
                        </div>
                      </>
                    );
                  })}

                
                </div>
              </div>

              {/* <div className="col-2 text-right">
                {allWorkspace.slice(0, 3).length > 2 ? (
                  <div style={{ cursor: "pointer" }} onClick={nextWorkspace}>
                    <div className="myDIV">
                      <ArrowForwardIcon />
                    </div>
                    <div className="hide">View all</div>
                  </div>
                ) : (
                  ""
                )}
              </div>*/}
            </div>
          </div>
        </>
      ) : (
        <center>
          <div className="dahboardHeaderV2">
            <Card>
              <Card.Body>
                <Card.Title>
                  <h3 className="fs-inter dashboard-h3">Let's get started! </h3>
                </Card.Title>

                <Card.Text>
                  <p className="dm-sans db-wp-empty-p">
                    Create a productive space for your team and start
                    collaborating on
                    <br /> your accounting process
                  </p>
                  {permissions?.companyData[0]?.components[0]?.permission?.create === true || permissions === null ? (
                      <Button className="wp-btn-fill" onClick={nextStep} disabled={users.responsibilities==="approver"||users.responsibilities==="view"}>
                        <AddIcon /> Create new workspace
                      </Button>
                    ) : (
                      <Button className="wp-btn-fill" disabled>
                        <AddIcon /> Create new workspace
                      </Button>
                    )
                    }

                  <div className="db-wp-empty-logos">
                    <img src={Logo} alt="sdsd" />
                    <img src={Logo1} alt="sdsd" />
                    <img src={Logo2} alt="sdsd" />
                    <img src={Logo4} alt="sdsd" />
                    <img src={Logo3} alt="sdsd" />
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </center>
      )}
      <SendRequest
       openRequestModal={openRequestModal}
       handleCloseRequestModal={handleCloseRequestModal}
       companyId={companyId}
       WID={WID}
       WSPDATA={WSPDATA}
       />
      <SendApproval
       user={users}
       approverId={approverId}
       openApproveModal={openApproveModal}
       handleCloseApprovalModal={handleCloseApprovalModal}
       handleApproverChange={handleApproverChange}
       WID={WID}
       WSPDATA={WSPDATA}/>
      <Modal
        open={openRenameModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="col-12">
            <h5>Rename Workspace</h5>
            <form>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={workspace(renameId, "id")}
                  onChange={(e) => handleChangeData(e)}
                />
              </div>
              <input
                type="button"
                className="btn btn-primary1"
                value="Submit"
                onClick={submitCompany}
              />
              <input
                type="button"
                className="btn btn-defult"
                value="Close"
                onClick={handleClose}
              />
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default WorkSpaceData;
