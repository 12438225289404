import React, { useEffect, useState } from "react";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SearchIcon from "../../assets/Searchone.svg";
import filterIcon from "../../assets/filterone.svg";
import CalendarIcon from "../../assets/Calendar.svg";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  // Avatar,
  Stack,
  // Typography,
} from "@mui/material";

import {
  Box,
  Tab,
  Tabs,
  Typography,
  Button,
  Card,
  Avatar,
  MenuItem,
  Select,
} from "@mui/material";
import PropTypes from "prop-types";
import Pagination from "@mui/material/Pagination";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FilterLogsModal from "./FilterModal";
import ExportLogsModal from "./ExportModal";
import Sidebar from "./Sidebar.jsx";
import { useHistory } from "react-router";
import { GetAuditLogs } from "../../WorkSpace/Components/ApiServices/apiHandler.js";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AuditLogs = () => {
  const history = useHistory();
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [exportOpen, setExportOpen] = useState(false);
  const [auditData, setAuditData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filtersNo,setFiltersNo] = useState(0);
  const handleApplyFilter = (filters) => {
    setOpen(false);
  };
  const totalPages = Math.ceil(
    (auditData.auditLogs?.length || 0) / rowsPerPage
  );
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  
  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset to first page when changing rows per page
  };
  const paginatedData =
    auditData.auditLogs?.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    ) || [];
  useEffect(() => {
    getAllAuditData();
  }, []);

  // get All audit api call
  const getAllAuditData = async () => {
    try {
      const res = await GetAuditLogs();
      setAuditData(res.data);
    } catch (error) {
      console.log(error.message, error);
    }
  };

  return (
    <div>
      <div>
        <Sidebar />
      </div>

      <div  style={{marginLeft:"60px",paddingRight:"1rem"}}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ marginLeft: "5px" }}
        >
          <p className="flex items-center justify-center gap-2 font-size-24 custom-font-500 font-sans mt-0 mb-0">
            <KeyboardArrowLeftIcon
              sx={{
                width: "35px",
                height: "35px",
                color: "#205A46",
                cursor: "pointer",
              }}
              onClick={() => history.push("/dashboard")}
            />
            Audit Logs
          </p>

          <Tabs
            value={value}
            onChange={(event, newValue) => setValue(newValue)}
            aria-label="basic tabs example"
            variant="standard"
            className="mt-3"
            sx={{
              "& .MuiTabs-flexContainer": {
                borderRadius: 2,
                overflow: "hidden",
              },
              "& button": {
                color: "black",
                fontWeight: "500",
                opacity: "1",
                minWidth: "120px",
                minHeight: "35px", // Set minimum height to 35px
                height: "35px", // Set explicit height to 35px
                border: "2px solid transparent",
              },
              "& button:first-of-type": {
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
              },
              "& button:last-of-type": {
                borderTopRightRadius: "10px",
                borderBottomRightRadius: "10px",
              },
              "& button.Mui-selected": {
                backgroundColor: "#03565A",
                color: "white",
                opacity: "1",
                borderColor: "#03565A",
              },
              "& button:not(.Mui-selected)": {
                borderColor: "#a9e0aa",
              },
            }}
          >
            <Tab
              className="font-sans"
              sx={{
                textTransform: "none",
                fontWeight: "500",
                fontSize: "13px",
                minHeight: "35px", // Set minimum height to 35px
                height: "35px", // Set explicit height to 35px
              }}
              label="List View"
              {...a11yProps(0)}
            />
            <Tab
              className="font-sans"
              sx={{
                textTransform: "none",
                fontWeight: "500",
                fontSize: "13px",
                minHeight: "35px", // Set minimum height to 35px
                height: "35px", // Set explicit height to 35px
              }}
              label="Timeline View"
              {...a11yProps(1)}
            />
          </Tabs>

          <Box
            display="flex"
            alignItems="center"
            gap="5px"
            sx={{ marginRight: "10px" }}
          >
            {value === 0 && (
              <img
                src={SearchIcon}
                alt="Search Icon"
                style={{ cursor: "pointer", width: "18px", height: "18px" }}
              />
            )}
            { filtersNo >=1 ? (
            <div
              style={{
                backgroundColor: "#E9F5EB",
                padding: "8px 13px",
                borderRadius: "17px",
                fontWeight: "bold",
                color:'#33584E'
              }}
            >
              Applied{" "}
              <span
                style={{
                  backgroundColor: "white",
                  fontWeight: 200,
                  padding: "5px 8px",
                  borderRadius: "10px",
                  color:'#33584E'
                }}
              >
                {filtersNo} Filters
              </span>
            </div>
            ):""
}
            <img
              src={filterIcon}
              alt="filter Icon"
              style={{ cursor: "pointer", width: "40px", height: "40px" }}
              onClick={() => setOpen(true)}
            />
            <Button
              variant="contained"
              startIcon={
                <FileDownloadOutlinedIcon sx={{ marginRight: "0px" }} />
              }
              sx={{
                width: "132px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textTransform: "none", // Keeps text normal case
                "& .MuiButton-startIcon": {
                  marginRight: "4px", // Adds margin to the icon
                },
              }}
              onClick={() => setExportOpen(true)}
              className="radius-10px font-size-16 custom-font-500 custom-common-theme-bg-color font-inter"
            >
              EXPORT
            </Button>
          </Box>
        </Box>
        <Box>
          <CustomTabPanel value={value} index={0}>
            <div
              className="px-1 ml-3 mt-3"
              style={{
                overflowY: "auto",
                maxHeight: "450px",
                minHeight: "75vh",
              }}
            >
              <TableContainer
                component={Paper}
                sx={{
                  width: "100%",
                  overflowX: "auto",
                  borderRadius: "10px",
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
                }}
              >
                <Table sx={{ borderCollapse: "collapse" }}>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                      <TableCell
                        sx={{
                          color: "#03565A",
                          fontWeight: "bold",
                          borderTop: "none",
                          backgroundColor: "white",
                        }}
                        className="font-inter"
                      >
                        USERNAME
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#03565A",
                          fontWeight: "bold",
                          textAlign: "left",
                          borderTop: "none",
                          backgroundColor: "white",
                        }}
                        className="font-inter"
                      >
                        MODULE
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#03565A",
                          fontWeight: "bold",
                          borderTop: "none",
                          backgroundColor: "white",
                        }}
                        align="center"
                        className="font-inter"
                      >
                        MESSAGE
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#03565A",
                          fontWeight: "bold",
                          borderTop: "none",
                          backgroundColor: "white",
                        }}
                        align="center"
                        className="font-inter"
                      >
                        DATE
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#03565A",
                          fontWeight: "bold",
                          borderTop: "none",
                          backgroundColor: "white",
                        }}
                        align="center"
                        className="font-inter"
                      >
                        TIME
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedData.length > 0 ? (
                      paginatedData.map((log, index) => (
                        <TableRow
                          key={log._id || index}
                          // sx={{ borderBottom: "1px solid #ddd" }}
                        >
                          <TableCell
                            sx={{ borderTop: "none", borderBottom: "none" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <Avatar
                                src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"
                                alt="Avatar"
                                sx={{ width: 30, height: 30 }}
                              />
                              <Typography variant="body2" color="black">
                                {log.username}
                              </Typography>
                            </div>
                          </TableCell>
                          <TableCell
                            sx={{ borderTop: "none", borderBottom: "none" }}
                          >
                            <Typography
                              variant="body2"
                              color="black"
                              sx={{ textAlign: "left" }}
                            >
                              {log.module}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ borderTop: "none", borderBottom: "none" }}
                          >
                            <Stack
                              direction="row"
                              spacing={2}
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography
                                variant="body2"
                                sx={{ color: "#000000", fontWeight: "400" }}
                                className="font-size-14 font-sans "
                              >
                                {log.message || "No message available"}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="primary"
                                sx={{ cursor: "pointer", color: "#2D8861" }}
                                className="custom-font-500 font-size-14 font-sans "
                              >
                                View more
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell
                            align="center"
                            sx={{ borderTop: "none", borderBottom: "none" }}
                          >
                            {new Date(log.createdAt).toLocaleDateString()}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ borderTop: "none", borderBottom: "none" }}
                          >
                            {new Date(log.createdAt).toLocaleTimeString('en-US', {
                              hour: 'numeric',
                              minute: '2-digit',
                              hour12: true
                             })
                            }
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          No audit logs found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <div
              style={{
                overflowY: "auto",
                maxHeight: "480px",
                minHeight: "75vh",
              }}
            >
              {paginatedData.length > 0
                ? paginatedData.map((log, index) => (
                    <div
                      key={index}
                      className="d-flex flex-row justify-content-center align-items-center"
                    >
                      <div className="d-flex align-items-center gap-1">
                        <Typography
                          variant="subtitle1"
                          className="font-sans mr-2 font-size-14  custom-font-500 text-dark"
                        >
                          {log.username}
                        </Typography>
                        <Avatar
                          src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"
                          alt="Avatar"
                          sx={{ width: 30, height: 30, marginRight: 1 }}
                        />
                      </div>
                      <div className="d-flex flex-column align-items-center position-relative ml-4">
                        <div
                          style={{
                            width: "2px",
                            height: "35px",
                            backgroundColor: "#B3C0BD",
                            position: "absolute",
                            top: "-16px",
                            left: "50%",
                            transform: "translateX(-50%)",
                          }}
                        ></div>
                        <Card
                          className="shadow-sm "
                          style={{
                            backgroundColor: "#EAEEED",
                            borderRadius: "10px",
                            width: "150px",
                            textAlign: "center",
                            padding: "10px",
                            height: "75px",
                            marginTop: "20px",
                          }}
                        >
                          <div className="d-flex align-items-center ml-3 mt-1">
                            <img
                              src={CalendarIcon}
                              alt="filter Icon"
                              style={{
                                cursor: "pointer",
                                width: "15px",
                                height: "15px",
                                color: "#1A4338",
                              }}
                            />
                            <span className="font-sans custom-font-500 font-size-14 text-dark ml-1">
                              {new Date(log.createdAt).toLocaleDateString()}
                            </span>
                          </div>
                          <div className="d-flex align-items-center mt-1 ml-4 ">
                            <AccessTimeIcon
                              fontSize="small"
                              style={{
                                color: "#1A4338",
                                width: "15px",
                                height: "15px",
                              }}
                            />
                            <span className="font-sans custom-font-500 font-size-14 ml-1 text-dark ">
                              {new Date(log.createdAt).toLocaleTimeString('en-US', {
                                hour: 'numeric',
                                minute: '2-digit',
                                hour12: true
                               })
                              }
                            </span>
                          </div>
                        </Card>
                      </div>
                      <div className="ml-5">
                        <Card
                          className="radius-12px no-shadow"
                          sx={{
                            mt: 3,
                            boxShadow: 1,
                            backgroundColor: "#E9F5EB",
                            border: "2px solid #C2E6D1",
                            width: 600,
                            minHeight: 87,
                            height: "auto", // Allows height to adjust based on content
                            overflow: "visible", // Ensures content is fully displayed
                            wordWrap: "break-word", // Prevents overflow of long words
                          }}
                        >
                          <div className="px-3 py-2">
                            <p
                              variant="body2"
                              className="font-sans text-black font-size-14 text-dark mt-0 mb-0"
                            >
                              {log.message}
                            </p>
                            <p
                              variant="caption"
                              className="font-sans  custom-font-500 font-size-14 mt-0 mb-0"
                            >
                              Module:{" "}
                              <span
                                className="font-weight-bold font-size-14 custom-font-600"
                                style={{ color: "#349F6F" }}
                              >
                                {log.module}
                              </span>
                            </p>
                          </div>
                        </Card>
                      </div>
                    </div>
                  ))
                : ""}
            </div>
          </CustomTabPanel>
        </Box>
        {/* Pagination  */}
        <div
          className="d-flex flex-row justify-content-between"
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",

            padding: "10px 20px",
          }}
        >
          <div className="d-flex flex-row justify-content-between">
            <div
              className="d-flex align-items-center gap-1"
              style={{ marginLeft: "50px" }}
            >
              <div className="pl-2">Rows per pages</div>
              <Select
                className="ml-2"
                sx={{
                  width: "65px",
                  height: "30px",
                  "& .MuiSvgIcon-root": {
                    color: "#7ECEA7", // Change the color of the dropdown arrow
                  },
                  // border: "1px solid red", // Change border color
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#7ECEA7", // Border color for outlined variant
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#7ECEA7", // Change border color on hover
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#7ECEA7", // Change border color when focused
                  },
                }}
                value={rowsPerPage}
                onChange={handleRowsPerPageChange}
              >
                <MenuItem value={5} sx={{ color: "#1A4338" }}>
                  5
                </MenuItem>
                <MenuItem value={10} sx={{ color: "#1A4338" }}>
                  10
                </MenuItem>
                <MenuItem value={15} sx={{ color: "#1A4338" }}>
                  15
                </MenuItem>
              </Select>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(event, page) => setCurrentPage(page)}
              shape="rounded"
              sx={{
                "& .MuiPaginationItem-root": {
                  color: "black", // Default text color
                },
                "& .Mui-selected": {
                  backgroundColor: "#E4F2E6 !important", // Active button background color
                  color: "#349F6F", // Text color for active button
                },
                "& .Mui-selected:hover": {
                  backgroundColor: "#C2E6D1 !important", // Hover effect for active button
                },
                "& .MuiPaginationItem-previousNext": {
                  display: "none",
                },
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "5px" }}>
            <div
              onClick={handlePreviousPage}
              style={{
                border: "1px solid #76d276",
                borderRadius: "5px",
                padding: "0px 7px",
              }}
            >
              <ChevronLeft
                className="cursor-pointer mt-1"
                style={{ color: "#205A46" }}
              />
            </div>
            <div
              onClick={handleNextPage}
              style={{
                border: "1px solid #76d276",
                borderRadius: "5px",
                padding: "0px 7px",
              }}
            >
              <ChevronRight
                className="cursor-pointer mt-1"
                style={{ color: "#205A46" }}
              />
            </div>
          </div>
        </div>
        <FilterLogsModal
          open={open}
          onClose={() => setOpen(false)}
          onApply={handleApplyFilter}
          setAuditData={setAuditData}
          auditData={auditData}
          getAllAuditData={getAllAuditData}
          setFiltersNo={setFiltersNo}
        />
        <ExportLogsModal
          open={exportOpen}
          onClose={() => setExportOpen(false)}
        />
      </div>
    </div>
  );
};

export default AuditLogs;
