import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useEffect, useState } from "react";
import {
  AllWorkspace,
  GetUser,
  accessWorkspacedata,
  cities,
  createCompany,
  deleteCompanyData,
  editCompanyData,
  findCompanyData,
  getAllWorkspace,
  getCompany,
  getApprovedCompany,
  state,
  getApproverForDeleteCompany,
} from "../ApiServices/apiHandler";
import Card from "react-bootstrap/Card";
import CardContent from "@mui/material/CardContent";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import moment from "moment";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Autocomplete from "@mui/material/Autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import Button from "react-bootstrap/Button";
import AddIcon from "@mui/icons-material/Add";
import { Navbar } from "../../../Components/Navbar";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover from "@mui/material/Popover";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Chip, FormControl, InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import Stack from "@mui/material/Stack";
import { fetchAllCompany } from "../../../redux/actions/workspace/actionCompany";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

import swal from "sweetalert";
import AddAccount from "../Modal/addAccount";
import EditCompany from "../Modal/editCompany";
import "../../../WorkSpace/SignUp2.css";
import { socket } from '../../../services/socket/socket';


const stylemodal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 5,
};

const AllCompanyData = (props) => {
  const all_company = useSelector((state) => state.CompanyReducer);
  const dispatch = useDispatch();
  // const [editData, setEditId] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [addCompanyshow, setAddCompany] = useState(false);
  const [company, setAllCompany] = useState([]);
  const [checkContinueBotton, setCheckContinueBotton] = useState(false);
  const [editModal2, setEditModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState([]);
  const [findData, setFindData] = useState(false);
  const [multipleCompany, setMultipleCompany] = useState([]);
  const [Open, setOpen] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [newcompany, setCompany] = useState({
    org_name: "",
    ci_no: "",
    pan_no: "",
    nature_of_company: "",
    functional: "",
    city: "",
    state: "",
    address: "",
  });

  const [editcompany, setCompanyEdit] = useState({
    org_name: "",
    ci_no: "",
    pan_no: "",
    nature_of_company: "",
    functional: "",
    city: "",
    state: "",
    address: "",
  });
  const [singleCompanyData,setSingleCompanyData]=useState({});
  const [companyData,setCompanyData]=useState({});
  const [allcities, setCitiesData] = useState([]);
  const [allstate, setStateData] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const [cid,setCid]=useState("");
  const [approver,setApprover]=useState({
    approver_id:""
  });
  const [openApprovalModal, setOpenApprovalModal] = useState(false);
  const [approvalId,setApprovalId]=useState([]);
  const [searchcomapny,setSearchComapny]=useState([]);


  const handleOpen = () => {
    setOpen3(true);
  };
  const handleClosechild = () => {
    setOpen3(false);
  };
  const opentab = Boolean(anchorEl);
  const id = opentab ? "simple-popover" : undefined;
  const permissions = JSON.parse(localStorage.getItem('permission'));
  const user = JSON.parse(localStorage.getItem('user'));
  useEffect(() => {
    if (all_company.isSuccess === false) {
      dispatch(fetchAllCompany());
      allCompany();

    }
    citiesData();
    stateData();
    allMultiCompany();
    getApprovalIds();
  }, []);
  const handleClosetab = () => {
    setAddCompany(false);
    setAnchorEl(null);
    handleChangeData('', 'search');
  };
  const citiesData = async () => {
    const rescities = await cities();
    const citiesall = rescities.data.CitiesData.cities.map((data) => data.name);
    setCitiesData(citiesall);
  };
  const onInputChangecity = (event, value) => {
    if (value && value.length > 3) {
      citiesData();
    } else {
      setCitiesData([]);
    }
  };
  const stateData = async () => {
    const res = await state();
    const stateall = res.data.StateData.state.map((data) => data.name);
    setStateData(stateall);
  };
  const onInputChange = (event, value) => {
    if (value && value.length > 3) {
      stateData();
    } else {
      setStateData([]);
    }
  };
  const handleDelete = () => {
    console.info("You clicked the delete icon.");
  };
  const handleClicktab = (event, c_id,data) => {
    setCompanyId(c_id);
    setCompanyData(data);
    setAnchorEl(event.currentTarget);
  };
  const allMultiCompany = async () => {
    const res = await getCompany();
    console.log(res.data, "dataaaaaa");
    if (res.status === 200) {
      const allCompanyData = res?.data?.getCompany?.map(
        (data) => data.company_name
      );
      setMultipleCompany(allCompanyData);
    } else {
      console.log("error");
    }
  };
  const handleClose = () => {
    setOpen(false);
    setEditModal(false);
  };
  const allCompany = async () => {
    const dataAll = await getCompany();
    console.log(dataAll, "ooooo");
    setAllCompany(dataAll.data.getCompany);
  };


const handleOpenApprovalModal = (id) =>{
  setOpenApprovalModal(true)
  setCid(id);
};
  const handleCloseApprovalModal = () => setOpenApprovalModal(false);

  const handleCompany = () => {
    if (user.responsibilities==="approver"||user.responsibilities==="view"){
      swal("Warning", "You Don't have Access to Create Company ", "warning"); 
    }else if (permissions?.unrestrictedAcessData[0]?.permission?.create === true || permissions === null){
      setAddCompany(true);
    }else{
      swal("Warning", "You Don't have Access to Create Company ", "warning"); 
    }
  };
  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleChange = (field, value) => {
    if (value.length > 0) {
      setCheckContinueBotton(true);
    }
    setSearchTerm([...searchTerm, value]);
  };
  const handleChangeData = (data, field) => {
    if (data.length > 0) {
      setCheckContinueBotton(true);
    }
    const checkData = multipleCompany.filter((det) => det.toLowerCase() === data.toLowerCase());
    if (checkData.length > 0) {
      setFindData(true);
      setSearchComapny(checkData);
    } else {
      setFindData(false);
    }
    setSearchTerm(data);
  };
  const handleChangeDataSubmit = (value, field) => {
    setCompany({ ...newcompany, [field]: value });
  };
  const submitCompany = async () => {
    let company_code =
      newcompany.org_name.slice(0, 3) + Math.floor(1000 + Math.random() * 9000);
      let data={}
    if(user.responsibilities==="preparer"){
    data = {
      companyName: newcompany.org_name,
      companyCode: company_code,
      ci_no: newcompany.ci_no,
      pan_no: newcompany.pan_no,
      nature_of_company: newcompany.nature_of_company,
      functional: newcompany.functional,
      city: newcompany.city,
      state: newcompany.state,
      address: newcompany.address,
      has_published:"draft",
      status:"waiting",
      approver_id:approver.approver_id,
      preparer_id:user._id
    };
  }else{
     data = {
      companyName: newcompany.org_name,
      companyCode: company_code,
      ci_no: newcompany.ci_no,
      pan_no: newcompany.pan_no,
      nature_of_company: newcompany.nature_of_company,
      functional: newcompany.functional,
      city: newcompany.city,
      state: newcompany.state,
      address: newcompany.address,
      has_published:"published",
    };
  }
    const result = await createCompany(data);
    if (result.status === 200) {
      handleClosechild();
      allCompany();
      allMultiCompany();
      setAddCompany(false);
      dispatch(fetchAllCompany());
      if(user.responsibilities==="preparer"){
      socket.emit("add-notification", {
        receiver_id: approver.approver_id,
        description: "creation of company",
        type:"sent approval",
        path:"/Notification"
      });
      socket.emit('get-notification');
     }
    }
    console.log(result.data);
    if (result.data.status === false) {
      swal("", result.data.message, "error");
    }
  };

  const deleteCompany = async (c_id) => {
    const  data = {
      companyName: singleCompanyData.company_name,
      companyCode:singleCompanyData.company_code,
      ci_no: singleCompanyData.ci_no,
      pan_no: singleCompanyData.pan_no,
      nature_of_company: singleCompanyData.nature_of_company,
      functional: singleCompanyData.functional,
      city: singleCompanyData.city,
      state: singleCompanyData.state,
      address: singleCompanyData.address,
      has_published:"draft",
      status:"waiting",
      approver_id:approver.approver_id,
      preparer_id:user._id
    };
    const res = await deleteCompanyData(c_id,data);
    if (res.status === 200) {
      setAnchorEl(null);
      setAllCompany();
      handleCloseApprovalModal();
      dispatch(fetchAllCompany());
      if(user.responsibilities==="preparer"){
        socket.emit("add-notification", {
          receiver_id: approver.approver_id,
          description: "deletion of company",
          type:"sent approval",
          path:"/Notification"
        });
        socket.emit('get-notification');
       }
    }
  };

  const editCompanyFetch = async (c_id) => {
    if (permissions?.unrestrictedAcessData[0]?.permission?.create === false){
      swal("Warning", "You Don't have Permission to edit Company ", "warning"); 
    }else{
      setEditModal(true);
      setAnchorEl(null);
      // setEditId(c_id);
      const res = await findCompanyData(c_id);
      if (res.status === 200) {
        setCompanyEdit(res?.data?.getOneCompany[0]);
      }
    }
  };
  const getOneCompany = async (c_id) => {
    const res = await findCompanyData(c_id);
    if (res.status === 200) {
      setSingleCompanyData(res?.data?.getOneCompany[0]);
    }
  };



  const handleChnageEdit = (value, field) => {
    setCompanyEdit({ ...editcompany, [field]: value });
  }

  const handleApproverChange = (value,field) => {
    setApprover({...approver,[field]: value});
  };


  const submitEdit = async (company_id) => {
    let company_code =
      editcompany.company_name.slice(0, 3) + Math.floor(1000 + Math.random() * 9000);
      let data={}
      if(user.responsibilities==="preparer"){
     data = {
      companyName: editcompany.company_name,
      companyCode: company_code,
      ci_no: editcompany.ci_no,
      pan_no: editcompany.pan_no,
      nature_of_company: editcompany.nature_of_company,
      functional: editcompany.functional,
      city: editcompany.city,
      state: editcompany.state,
      address: editcompany.address,
      has_published:"draft",
      status:"waiting",
      approver_id:approver.approver_id,
      preparer_id:user._id
    };
  }else{
    data ={
      companyName: editcompany.company_name,
      companyCode: company_code,
      ci_no: editcompany.ci_no,
      pan_no: editcompany.pan_no,
      nature_of_company: editcompany.nature_of_company,
      functional: editcompany.functional,
      city: editcompany.city,
      state: editcompany.state,
      address: editcompany.address,
      has_published:"published",
    }
  }
    const result = await editCompanyData(company_id, data);
    if (result.status === 200) {
      handleClose();
      allCompany();
      dispatch(fetchAllCompany());
      if(user.responsibilities==="preparer"){
        socket.emit("add-notification", {
          receiver_id: approver.approver_id,
          description: "updation of company",
          type:"sent approval",
          path:"/Notification"
        });
        socket.emit('get-notification');
       }
    }
    console.log(result.data);
    if (result.data.status === false) {
      swal("", result.data.message, "error");
    }
  }

  const getApprovalIds = async()=>{
    const parent_id = user.parent_id;
    getApproverForDeleteCompany(parent_id)
        .then(response => {
          setApprovalId(response.data.result);
        })
        .catch(error => {
          console.error('Error:', error);
        });
  }
  
  

  return (
    <>
      <div className="pb-2">
        <div>
          <Navbar />
        </div>

        <>
          <div className="dahboardHeaderV2">
            <div className="row">
              <div className="col-md-8">
                <h5 className="dbwcardhead">Company Directory </h5>
              </div>
              <div className="col-4 text-right">
                <button className="rwp-btn" onClick={handleCompany}>
                  <span>
                    <AddIcon />
                  </span>
                  Create new Company
                </button>
              </div>
            </div>

            <div className="row">
              {all_company.loading  ? (
                <div className="col-12 text-center" style={{ paddingTop: "100px" }}>
                  <CircularProgress />
                </div>
              ) : all_company.isSuccess && all_company.response.length > 0 ? (
                <div className="col-12 all-company" style={{ marginTop: "20px" }}>
                  <TableContainer component={Paper}>
                    <Table sx={{}} style={{ marginBottom: "0px" }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">COMPANY NAME</TableCell>
                          <TableCell align="center">
                            NATURE OF THE COMPANY
                          </TableCell>
                          <TableCell align="center">FUNCTIONAL CURRENCY</TableCell>
                          <TableCell align="center">ACTION</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {all_company.response?.map((det) => {
                          return (
                            <>
                              <TableRow>

                                <TableCell className="font-sans"
                                  style={{ backgroundColor: "white" }}
                                  align="left"
                                >
                                  {det.company_name}
                                </TableCell>
                                <TableCell
                                  className="font-sans"
                                  style={{ backgroundColor: "white" }}
                                  align="center"
                                >
                                  {det?.nature_of_company}
                                </TableCell>
                                <TableCell
                                  className="font-sans"
                                  style={{ backgroundColor: "white" }}
                                  align="center"
                                >
                                  {det?.functional}
                                </TableCell>
                                <TableCell
                                  style={{ backgroundColor: "white" }}
                                  align="center"
                                >
                                  <MoreVertIcon
                                    onClick={(e) => handleClicktab(e, det._id,det)}
                                    style={{ cursor: "pointer" }}
                                  />
                                  <Popover
                                    style={{}}
                                    id={id}
                                    open={opentab}
                                    anchorEl={anchorEl}
                                    onClose={handleClosetab}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                  >
                                    <Typography sx={{ p: 2 }}>
                                      <span
                                        style={{ cursor: "pointer", fontSize: "12px" }}
                                        onClick={() => editCompanyFetch(companyId)}
                                      >
                                        Edit
                                      </span>{" "}
                                      <br />
                                      <span
                                        style={{ cursor: "pointer", fontSize: "12px" }}
                                        onClick={() => {
                                          if (permissions?.unrestrictedAcessData[0]?.permission?.delete === false){
                                            swal("Warning", "You Don't have Permission to delete Company ", "warning"); 
                                          }
                                         else if(user.responsibilities === "preparer"&&companyData.created_by==="admin"||user.responsibilities === "preparer"&&companyData.user_id !== user._id){
                                            swal("Warning", "You Don't have Access to delete Company ", "warning"); 
                                          }
                                         else if (user.responsibilities === "preparer") {
                                            handleOpenApprovalModal(companyId);
                                            getOneCompany(companyId);
                                          }else if(user.responsibilities==="approver"||user.responsibilities==="view"){
                                            swal("Warning", "You Don't have Access to delete Company ", "warning"); 
                                          } else {
                                            deleteCompany(companyId);
                                          }
                                        }}
                                        
                                      >
                                        Delete
                                      </span>
                                      <br/>
                                      <a style={{ cursor: "pointer", fontSize: "12px",color:"black", textDecoration:"none" }} className="" href={`https://loan.test.myfinalyst.com/companyPolicy?companyid=${companyId}`}>Company Policy</a>
                                    </Typography>
                                  </Popover>
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : (
                <div className="col-12 text-center" style={{ paddingTop: "100px" }}>
                  {/* <CircularProgress /> */}No Company is Listed
                </div>
              )}
            </div>
          </div>
        </>
      </div>
      <Modal
        open={openApprovalModal}
        onClose={handleCloseApprovalModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={stylemodal}>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            onChange={(e) => handleApproverChange(e.target.value, "approver_id")}
          >
          {approvalId && approvalId.length>0 && approvalId.map((data)=>
            <MenuItem key={data._id} value={data._id}>{data.name}</MenuItem>
          )}
          </Select>
          </FormControl>
          <div className="d-flex flex-row justify-content-center mt-3 ">
          <Button style={{
              backgroundColor: "#03565a",
              color: "white",
              textTransform: "none",
              margin:"10px",
              height:"36px",
              width:"50%"
            }}
            className="font-sans font-size-14"
            onClick={handleCloseApprovalModal}
            >
              Return
          </Button>
          <Button
            style={{
              backgroundColor: "#03565a",
              color: "white",
              textTransform: "none",
              width:"50%",
              height:"36px",
              marginTop:"10px"
            }}
           className="font-sans font-size-14" 
           onClick={() =>{
           deleteCompany(cid)
         }}
          >
            Send for approval
          </Button>
        </div>
        </Box>
      </Modal>

      <AddAccount
        open2={addCompanyshow}
        handleClose2={handleClosetab}
        handleChangeData={handleChangeData}
        findData={findData}
        handleApproverChange={handleApproverChange}
        searchTerm={searchTerm}
        multipleCompany={multipleCompany}
        handleChangeDataSubmit={handleChangeDataSubmit}
        submitCompany={submitCompany}
        handleCloseModal={handleCloseModal}
        open={open3}
        handleClose={handleClosechild}
        handleOpen={handleOpen}
        checkData={searchcomapny}
      />
      <EditCompany open={editModal2} handleClose={handleClose} editData={editcompany} handleChnageEdit={handleChnageEdit} handleApproverChange={handleApproverChange} submitEdit={submitEdit} />
    </>
  );
};

export default AllCompanyData;
