import React from 'react';
import moment from "moment";
import { useSelector } from 'react-redux';
import { formatIndianCurrency } from '../../../helper/ConvertValue'
import { DisclosureNBFCTableBody, DisclosureNBFCTableHead } from './DisclosureHelper';



export function NBFC_32p22_32p37({ data, subIndex, HandleChange, calculateTotal, dis_index_id }) {
    const reduxProjectData = useSelector((initialState) => initialState?.DisclosureReducer?.projectData || {});
    const user = JSON.parse(localStorage.getItem('user'));


    return (
        <>
            <thead >
                {
                    dis_index_id === 3 && (
                        <>
                            <tr>
                                <th className='text-left w-50 pl-3 border-top-0 border-bottom-0'>Stages</th>
                                <th className='text-left w-50 pl-3 border-top-0 border-bottom-0'>Internal Rating Description</th>
                                <DisclosureNBFCTableHead isEmpty={false} />
                            </tr>
                        </>
                    )
                }
                {
                    (dis_index_id === 5 || dis_index_id === 6) && (
                        <>
                            <tr>
                                <th className='text-left w-50 pl-3 border-top-0 border-bottom-0' rowSpan={2}>Industry Analysis</th>
                                <th className='w-auto border-top-0 border-bottom-0' colSpan={4}>
                                    {dis_index_id === 5 ?
                                        `As at ${moment(reduxProjectData.current_year).format("DD MMMM YYYY")}` :
                                        `As at ${moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}`
                                    }
                                </th>
                                <DisclosureNBFCTableHead isEmpty={false} />

                            </tr>
                            <tr>
                                <th className='w-auto border-top-0 border-bottom-0'>Retail</th>
                                <th className='border-top-0 border-bottom-0' style={{ maxWidth: "21rem" }}>Structured Finance and corporate finance (Real Estate and Non Real Estate)</th>
                                <th className='w-auto border-top-0 border-bottom-0'>Financial Services</th>
                                <th className='w-auto border-top-0 border-bottom-0'>Total</th>
                                <DisclosureNBFCTableHead isEmpty={true} />
                            </tr>
                        </>
                    )
                }
                {
                    dis_index_id === 7 && (
                        <>
                            <tr>
                                <th className='border-top-0 border-bottom-0'>Business Segments</th>
                                <th className='border-top-0 border-bottom-0' colSpan={2}>Maximum exposure to credit risk (carrying amount before ECL)</th>
                                <th className='border-top-0 border-bottom-0' rowSpan={2}>Principal type of collateral </th>
                                <DisclosureNBFCTableHead isEmpty={false} />

                            </tr>
                            <tr>
                                <th className='border-top-0 border-bottom-0'></th>
                                <th className='w-auto bg-transparent border-top-0 border-bottom-0' > Year ended {" "} {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>


                                <th className='w-auto w-auto bg-transparent border-top-0 border-bottom-0' > Year ended {" "} {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
                                <DisclosureNBFCTableHead isEmpty={true} />
                            </tr>
                        </>
                    )
                }
                {
                    (dis_index_id === 8 || dis_index_id === 9) && (
                        <>
                            <tr>
                                <th className='text-left w-50 pl-3 border-top-0 border-bottom-0'> Financial assets measured at {dis_index_id === 8 ? " amortised cost" : " FVOCI"}</th>
                                <th className='w-auto border-top-0 border-bottom-0' style={{ minWidth: "110px" }} >Maximum exposure to credit risk (carrying amount before ECL)</th>
                                <th className='w-auto border-top-0 border-bottom-0' style={{ minWidth: "110px" }} > Associated ECL </th>
                                <th className='w-auto border-top-0 border-bottom-0' style={{ minWidth: "110px" }}>  Carrying Amount </th>
                                <th className='w-auto border-top-0 border-bottom-0' style={{ minWidth: "110px" }} >  Fair Value of Collateral </th>
                                <DisclosureNBFCTableHead isEmpty={false} />

                            </tr>
                        </>
                    )
                }
                {
                    dis_index_id === 11 && (
                        <>
                            <tr >
                                <th className='border-top-0 border-bottom-0'>Particulars</th>
                                <th className='border-top-0 border-bottom-0' style={{ minWidth: "70px" }}>1 day to 30/31 days (one month)</th>
                                <th className='border-top-0 border-bottom-0' style={{ minWidth: "70px" }}>1 month to 2 month</th>
                                <th className='border-top-0 border-bottom-0' style={{ minWidth: "70px" }}> 2 months to 3 month</th>
                                <th className='border-top-0 border-bottom-0' style={{ minWidth: "70px" }}> 3 months to 6 months</th>
                                <th className='border-top-0 border-bottom-0' style={{ minWidth: "70px" }}>  Over 6 months to 1 year</th>
                                <th className='border-top-0 border-bottom-0' style={{ minWidth: "70px" }}>  Over 1 year to 3 years</th>
                                <th className='border-top-0 border-bottom-0' style={{ minWidth: "70px" }}>  Over 3 year to 5 years</th>
                                <th className='border-top-0 border-bottom-0' style={{ minWidth: "70px" }}> Over 5 years</th>
                                <th className='border-top-0 border-bottom-0' style={{ minWidth: "70px" }}> Total</th>
                                <DisclosureNBFCTableHead isEmpty={false} />
                            </tr>
                        </>
                    )
                }
                {
                    dis_index_id === 12 && (
                        <>
                            <tr>
                                <th className='border-top-0 border-bottom-0'>Particulars</th>
                                <th className='border-top-0 border-bottom-0' style={{ minWidth: "70px" }}>1 day to 30/31 days (one month)</th>
                                <th className='border-top-0 border-bottom-0' style={{ minWidth: "70px" }}>1 month to 2 months</th>
                                <th className='border-top-0 border-bottom-0' style={{ minWidth: "70px" }}>2 month to 3 months</th>
                                <th className='border-top-0 border-bottom-0' style={{ minWidth: "70px" }}>3 month to 6 months</th>
                                <th className='border-top-0 border-bottom-0' style={{ minWidth: "70px" }}> Over 6 months to 1 year</th>
                                <th className='border-top-0 border-bottom-0' style={{ minWidth: "70px" }}> Over 1 year to 3 years</th>
                                <th className='border-top-0 border-bottom-0' style={{ minWidth: "70px" }}>Over 3 Years to 5 years</th>
                                <th className='border-top-0 border-bottom-0' style={{ minWidth: "70px" }}>Over 5 years</th>
                                <th className='border-top-0 border-bottom-0' style={{ minWidth: "70px" }}>Total</th>
                                <DisclosureNBFCTableHead isEmpty={false} />
                            </tr>
                        </>
                    )
                }
                {
                    dis_index_id === 13 && (
                        <>
                            <tr>
                                <th className='border-top-0 border-bottom-0' rowSpan={2}>As at {" "} {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
                                <th className='border-top-0 border-bottom-0' colSpan={3} style={{ minWidth: "110px" }}> Encumbered</th>
                                <th className='border-top-0 border-bottom-0' colSpan={2} style={{ minWidth: "110px" }}> Unencumbered</th>
                                <th className='border-top-0 border-bottom-0' rowSpan={2} style={{ minWidth: "110px" }}> Total carrying amount</th>
                                <DisclosureNBFCTableHead isEmpty={false} />
                            </tr>
                            <tr>
                                <th className='border-top-0 border-bottom-0'> Pledged as collateral</th>
                                <th className='border-top-0 border-bottom-0'> Contractually/ Legally restricted assets *</th>
                                <th className='border-top-0 border-bottom-0'>Others $</th>
                                <th className='border-top-0 border-bottom-0'> Available as collateral</th>
                                <th className='border-top-0 border-bottom-0'> Others #</th>
                                <DisclosureNBFCTableHead isEmpty={true} />
                            </tr>
                        </>
                    )
                }
                {
                    dis_index_id === 14 && (
                        <>
                            <tr>
                                <th className='border-top-0 border-bottom-0' rowSpan={2}>As at {" "} {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
                                <th className='border-top-0 border-bottom-0' colSpan={3}> Encumbered</th>
                                <th className='border-top-0 border-bottom-0' colSpan={2}> Unencumbered</th>
                                <th className='border-top-0 border-bottom-0' rowSpan={2}> Total carrying amount</th>
                                <DisclosureNBFCTableHead isEmpty={false} />
                            </tr>
                            <tr>
                                <th className='border-top-0 border-bottom-0'> Pledged as collateral</th>
                                <th className='border-top-0 border-bottom-0'> Contractually/ Legally restricted assets *</th>
                                <th className='border-top-0 border-bottom-0'>Others $</th>
                                <th className='border-top-0 border-bottom-0'> Available as collateral</th>
                                <th className='border-top-0 border-bottom-0'> Others #</th>
                                <DisclosureNBFCTableHead isEmpty={true} />
                            </tr>

                        </>
                    )
                }
                {
                    dis_index_id === 17 && (
                        <>
                            <tr>
                                <th className='border-top-0 border-bottom-0'>For the year ended {" "} {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
                                <th className='border-top-0 border-bottom-0'> Increase / (Decrease) in basis points</th>
                                <th className='border-top-0 border-bottom-0'>Increase in profit after tax</th>
                                <th className='border-top-0 border-bottom-0'>Decrease in profit after tax</th>
                                <DisclosureNBFCTableHead isEmpty={false} />
                            </tr>
                        </>
                    )
                }
                {
                    dis_index_id === 19 && (
                        <>
                            <tr>
                                <th className='border-top-0 border-bottom-0' rowSpan={2}>Particulars</th>
                                <th className='border-top-0 border-bottom-0' colSpan={3}> As at{" "} {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
                                <th className='border-top-0 border-bottom-0' colSpan={3}> As at {" "} {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
                                <DisclosureNBFCTableHead isEmpty={false} />
                            </tr>
                            <tr>
                                <th className='border-top-0 border-bottom-0'>Carrying Amount</th>
                                <th className='border-top-0 border-bottom-0'>Traded Risk</th>
                                <th className='border-top-0 border-bottom-0'>Non traded Risk</th>
                                <th className='border-top-0 border-bottom-0'> Carrying Amount</th>
                                <th className='border-top-0 border-bottom-0'>Traded Risk</th>
                                <th className='border-top-0 border-bottom-0'>Non traded Risk</th>
                                <DisclosureNBFCTableHead isEmpty={true} />
                            </tr>
                        </>
                    )
                }
                {
                    dis_index_id === 20 && (
                        <>
                            <tr>
                                <th className='border-top-0 border-bottom-0' rowSpan={2}>Particulars</th>
                                <th className='border-top-0 border-bottom-0' colSpan={3}>As at {" "} {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
                                <th className='border-top-0 border-bottom-0' colSpan={3}> As at {" "} {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
                                <DisclosureNBFCTableHead isEmpty={false} />
                            </tr>
                            <tr>
                                <th className='border-top-0 border-bottom-0'>Carrying Amount</th>
                                <th className='border-top-0 border-bottom-0'>Traded Risk</th>
                                <th className='border-top-0 border-bottom-0'>Non traded Risk</th>
                                <th className='border-top-0 border-bottom-0'> Carrying Amount</th>
                                <th className='border-top-0 border-bottom-0'>Traded Risk</th>
                                <th className='border-top-0 border-bottom-0'>Non traded Risk</th>
                                <DisclosureNBFCTableHead isEmpty={true} />
                            </tr>
                        </>
                    )
                }
            </thead>
            <tbody >
                {
                    data &&
                    data.length > 0 &&
                    data.map((subDis, subDisIndex) => (
                        <>
                            {
                                dis_index_id === 3 && (
                                    <tr>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"}`}
                                                type={"text"}
                                                value={subDis?.header}
                                                name="header"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0' >
                                            <input style={{ backgroundColor: "inherit" }}
                                                className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"}`}
                                                type={"text"}
                                                value={subDis?.header_2}
                                                disabled={user.responsibilities === "view"||user.responsibilities === "approver"}
                                                name="header_2"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} 
                                            isReference={subDis?.isReference} keyValue={9} index={subIndex}  sub_index={subDisIndex} />
                                    </tr>
                                )
                            }
                            {
                                dis_index_id === 5 && (
                                    <>
                                        <tr>
                                            <td className='border-top-0 border-bottom-0' >
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"}`}
                                                    type={"text"}
                                                    value={subDis?.header}
                                                    name="header"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis?.retail !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis?.retail)}
                                                    name="retail"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis?.structured_finance_corporate_finance !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis?.structured_finance_corporate_finance)}
                                                    name="structured_finance_corporate_finance"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis?.financial_services !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis?.financial_services)}
                                                    name="financial_services"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>

                                            <td className='border-top-0 border-bottom-0 text-right pr-3'  >
                                                {subDisIndex !== 0 && formatIndianCurrency(calculateTotal(data, "dis_57_1", { type: "type_3", index: subDisIndex }))}
                                            </td>
                                            <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} 
                                                 isReference={subDis?.isReference} keyValue={9} index={subIndex}  sub_index={subDisIndex} />
                                        </tr>
                                        {
                                            data.length - 1 === subDisIndex && (
                                                <tr>
                                                    <td className='text-left pl-3 font-weight-bold text-dark custom-bg-gray-200'> Total</td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "retail", { start: 0, end: 8 }))} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "structured_finance_corporate_finance", { start: 0, end: 8 }))} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "financial_services", { start: 0, end: 8 }))} </td>
                                                    <td className='font-weight-bold text-dark custom-bg-gray-200 text-right pr-3'>{
                                                      formatIndianCurrency(calculateTotal(data, "retail", { start: 0, end: 8 }) +
                                                        calculateTotal(data, "structured_finance_corporate_finance", { start: 0, end: 8 }) +
                                                        calculateTotal(data, "financial_services", { start: 0, end: 8 }))
                                                    }</td>
                                                    <DisclosureNBFCTableBody isEmpty={true} refData={[]} isReference={""} />
                                                </tr>
                                            )
                                        }
                                    </>
                                )
                            }
                            {
                                dis_index_id === 6 && (
                                    <>
                                        <tr>
                                            <td className='border-top-0 border-bottom-0' >
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"}`}
                                                    type={"text"}
                                                    value={subDis?.header}
                                                    name="header"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0' >
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis?.retail !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis?.retail)}
                                                    name="retail"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis?.structured_finance_corporate_finance !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis?.structured_finance_corporate_finance)}
                                                    name="structured_finance_corporate_finance"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0' >
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis?.financial_services !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={subDis?.financial_services}
                                                    name="financial_services"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0 text-right pr-3'>
                                                {subDisIndex !== 0 && formatIndianCurrency(calculateTotal(data, "dis_57_1", { type: "type_3", index: subDisIndex }))}
                                            </td>
                                            <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} 
                                               isReference={subDis?.isReference} keyValue={9} index={subIndex}  sub_index={subDisIndex} />
                                        </tr>
                                        {
                                            data.length - 1 === subDisIndex && (
                                                <tr>
                                                    <td className='text-left pl-3 font-weight-bold text-dark custom-bg-gray-200'> Total</td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "retail", { start: 0, end: 8 }))} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "structured_finance_corporate_finance", { start: 0, end: 8 }))} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "financial_services", { start: 0, end: 8 }))} </td>
                                                    <td className='font-weight-bold text-dark custom-bg-gray-200 text-right pr-3'>{
                                                      formatIndianCurrency(calculateTotal(data, "retail", { start: 0, end: 8 }) +
                                                        calculateTotal(data, "structured_finance_corporate_finance", { start: 0, end: 8 }) +
                                                        calculateTotal(data, "financial_services", { start: 0, end: 8 }))
                                                    }</td>
                                                    <DisclosureNBFCTableBody isEmpty={true}  refData={[]} isReference={""} />
                                                </tr>
                                            )
                                        }
                                    </>
                                )
                            }
                            {
                                dis_index_id === 7 && (
                                    <>
                                        <tr>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                        }`}
                                                    type={"text"}
                                                    value={subDis?.header}
                                                    name="header"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis?.cy !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis?.cy)}
                                                    name="cy"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis?.py !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis?.py)}
                                                    name="py"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"}`}
                                                    type={"text"}
                                                    value={subDis?.header_2}
                                                    name="header_2"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                                />
                                            </td>
                                            <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} 
                                                isReference={subDis?.isReference}  keyValue={9} index={subIndex}  sub_index={subDisIndex} />
                                        </tr>
                                        {
                                            (dis_index_id === 7 && subDis.sub_dis_index_id === 2) && (
                                                <tr>
                                                    <td className='text-left pl-3 font-weight-bold text-dark custom-bg-gray-200'> Total(A)</td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "cy", { start: 0, end: 2 }))} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "py", { start: 0, end: 2 }))} </td>
                                                    <td className='custom-bg-gray-200'></td>
                                                    <DisclosureNBFCTableBody isEmpty={true} refData={[]} isReference={""} />
                                                </tr>
                                            )
                                        }
                                    </>
                                )
                            }
                            {
                                (dis_index_id === 8 || dis_index_id === 9) && (
                                    <>
                                        <tr>
                                            <td className='text-dark text-left pl-3 border-top-0 border-bottom-0' >
                                                {
                                                    subDisIndex === 0 ?
                                                        moment(reduxProjectData.current_year).format("DD MMMM YYYY") :
                                                        moment(reduxProjectData.previous_year).format("DD MMMM YYYY")
                                                }

                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis?.maximum_exposure !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis?.maximum_exposure)}
                                                    name="maximum_exposure"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis?.associated_ecl !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis?.associated_ecl)}
                                                    name="associated_ecl"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis?.carrying_amount !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis?.carrying_amount)}
                                                    name="carrying_amount"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis?.fair_value !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis?.fair_value)}
                                                    name="fair_value"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                                />
                                            </td>
                                            <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} 
                                                isReference={subDis?.isReference}  keyValue={9} index={subIndex}  sub_index={subDisIndex}/>
                                        </tr>

                                    </>
                                )
                            }
                            {
                                dis_index_id === 11 && (
                                    <>
                                        <tr>
                                            <td className='w-25 border-top-0 border-bottom-0' >
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                        }`}
                                                    type={"text"}
                                                    value={subDis?.header}
                                                    name="header"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["1_day_to_30_days"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["1_day_to_30_days"])}
                                                    name="1_day_to_30_days"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["1_months_to_2_months"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["1_months_to_2_months"])}
                                                    name="1_months_to_2_months"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["2_months_to_3_months"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["2_months_to_3_months"])}
                                                    name="2_months_to_3_months"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["3_months_to_6_months"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["3_months_to_6_months"])}
                                                    name="3_months_to_6_months"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["6_months_to_1_years"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["6_months_to_1_years"])}
                                                    name="6_months_to_1_years"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["1_years_to_3years"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["1_years_to_3years"])}
                                                    name="1_years_to_3years"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["3_years_to_5years"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["3_years_to_5years"])}
                                                    name="3_years_to_5years"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0' >
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["over_5years"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["over_5years"])}
                                                    name="over_5years"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                           
                                            <td className='border-top-0 border-bottom-0 text-right pr-3' >
                                                {![0, 6].includes(subDisIndex) && formatIndianCurrency(calculateTotal(data, "dis_57_2", { type: "type_3", index: subDisIndex }))}
                                            </td>
                                            <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData}
                                                isReference={subDis?.isReference}  keyValue={9} index={subIndex}  sub_index={subDisIndex}/>
                                        </tr>
                                    </>
                                )
                            }
                            {
                                dis_index_id === 12 && (
                                    <>
                                        <tr>
                                            <td className='w-25 border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                        }`}
                                                    type={"text"}
                                                    value={subDis?.header}
                                                    name="header"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["1_day_to_30_days"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["1_day_to_30_days"])}
                                                    name="1_day_to_30_days"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["1_months_to_2_months"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["1_months_to_2_months"])}
                                                    name="1_months_to_2_months"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["2_months_to_3_months"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["2_months_to_3_months"])}
                                                    name="2_months_to_3_months"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["3_months_to_6_months"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["3_months_to_6_months"])}
                                                    name="3_months_to_6_months"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0' >
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["6_months_to_1_years"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["6_months_to_1_years"])}
                                                    name="6_months_to_1_years"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["1_years_to_3years"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["1_years_to_3years"])}
                                                    name="1_years_to_3years"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["3_years_to_5years"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["3_years_to_5years"])}
                                                    name="3_years_to_5years"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0' >
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["over_5years"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["over_5years"])}
                                                    name="over_5years"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0 text-right pr-3'>
                                                {![0, 6].includes(subDisIndex) && formatIndianCurrency(calculateTotal(data, "dis_57_2", { type: "type_3", index: subDisIndex }))}
                                            </td>
                                            <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} 
                                               isReference={subDis?.isReference}  keyValue={9} index={subIndex}  sub_index={subDisIndex}/>
                                        </tr>
                                    </>
                                )
                            }
                            {
                                dis_index_id === 13 && (
                                    <>
                                        <tr>
                                            <td className='w-25 border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                        }`}
                                                    type={"text"}
                                                    value={subDis?.header}
                                                    name="header"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["pledged_as_collateral"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["pledged_as_collateral"])}
                                                    name="pledged_as_collateral"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["Legally_restricted_assets"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["Legally_restricted_assets"])}
                                                    name="Legally_restricted_assets"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["encumbered_other"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["encumbered_other"])}
                                                    name="encumbered_other"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0' >
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["available_as_collateral"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["available_as_collateral"])}
                                                    name="available_as_collateral"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["unencumbered_other"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["unencumbered_other"])}
                                                    name="unencumbered_other"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0 text-right pr-3' >
                                                {formatIndianCurrency(calculateTotal(data, "dis_57_3", { type: "type_3", index: subDisIndex }))}
                                            </td>
                                            <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} 
                                                 isReference={subDis?.isReference}  keyValue={9} index={subIndex}  sub_index={subDisIndex}/>
                                        </tr>
                                        {
                                            (dis_index_id === 13 && subDis.sub_dis_index_id === 7) && (
                                                <tr>
                                                    <td className='text-left pl-3 font-weight-bold text-dark custom-bg-gray-200'> </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "pledged_as_collateral"))} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "Legally_restricted_assets"))} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "encumbered_other"))} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "available_as_collateral"))} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "unencumbered_other"))} </td>
                                                    <td className='font-weight-bold text-dark custom-bg-gray-200 text-right pr-3'>
                                                        {
                                                         formatIndianCurrency(calculateTotal(data, "pledged_as_collateral") +
                                                            calculateTotal(data, "Legally_restricted_assets") +
                                                            calculateTotal(data, "encumbered_other") +
                                                            calculateTotal(data, "available_as_collateral") +
                                                            calculateTotal(data, "unencumbered_other"))
                                                        }
                                                    </td>
                                                    <DisclosureNBFCTableBody isEmpty={true} refData={[]} isReference={""} />
                                                </tr>
                                            )
                                        }
                                    </>
                                )
                            }
                            {
                                dis_index_id === 14 && (
                                    <>
                                        <tr>
                                            <td className='w-25 border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                        }`}
                                                    type={"text"}
                                                    value={subDis?.header}
                                                    name="header"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["pledged_as_collateral"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["pledged_as_collateral"])}
                                                    name="pledged_as_collateral"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["Legally_restricted_assets"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["Legally_restricted_assets"])}
                                                    name="Legally_restricted_assets"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["encumbered_other"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["encumbered_other"])}
                                                    name="encumbered_other"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["available_as_collateral"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["available_as_collateral"])}
                                                    name="available_as_collateral"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["unencumbered_other"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["unencumbered_other"])}
                                                    name="unencumbered_other"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0 text-right pr-3'>
                                                {formatIndianCurrency(calculateTotal(data, "dis_57_3", { type: "type_3", index: subDisIndex }))}
                                            </td>
                                            <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData}
                                                 isReference={subDis?.isReference}  keyValue={9} index={subIndex}  sub_index={subDisIndex} />
                                        </tr>
                                        {
                                            (dis_index_id === 14 && subDis.sub_dis_index_id === 7) && (
                                                <tr>
                                                    <td className='text-left pl-3 font-weight-bold text-dark custom-bg-gray-200'> </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "pledged_as_collateral"))} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "Legally_restricted_assets"))} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "encumbered_other"))} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "available_as_collateral"))} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "unencumbered_other"))} </td>
                                                    <td className='font-weight-bold text-dark custom-bg-gray-200 text-right pr-3'>
                                                        {
                                                          formatIndianCurrency(calculateTotal(data, "pledged_as_collateral") +
                                                            calculateTotal(data, "Legally_restricted_assets") +
                                                            calculateTotal(data, "encumbered_other") +
                                                            calculateTotal(data, "available_as_collateral") +
                                                            calculateTotal(data, "unencumbered_other"))
                                                        }
                                                    </td>
                                                    <DisclosureNBFCTableBody isEmpty={true} refData={[]} isReference={""} />

                                                </tr>
                                            )
                                        }
                                    </>
                                )
                            }
                            {
                                dis_index_id === 17 && (
                                    <>
                                        <tr>
                                            <td className='w-25 border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                        }`}
                                                    type={"text"}
                                                    value={subDis?.header}
                                                    name="header"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["increase_decrease_points"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["increase_decrease_points"])}
                                                    name="increase_decrease_points"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["increase_profit_after_tax"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["increase_profit_after_tax"])}
                                                    name="increase_profit_after_tax"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0' >
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["decrease_profit_after_tax"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["decrease_profit_after_tax"])}
                                                    name="decrease_profit_after_tax"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} 
                                                 isReference={subDis?.isReference}  keyValue={9} index={subIndex}  sub_index={subDisIndex}/>
                                        </tr>
                                    </>
                                )
                            }
                            {
                                dis_index_id === 19 && (
                                    <>
                                        <tr>
                                            <td className='w-25 border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                        }`}
                                                    type={"text"}
                                                    value={subDis?.header}
                                                    name="header"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0' >
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["cy_carrying_amount"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["cy_carrying_amount"])}
                                                    name="cy_carrying_amount"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["cy_traded_risk"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["cy_traded_risk"])}
                                                    name="cy_traded_risk"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["cy_Non_traded_Risk"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["cy_Non_traded_Risk"])}
                                                    name="cy_Non_traded_Risk"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0' >
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["py_carrying_amount"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["py_carrying_amount"])}
                                                    name="py_carrying_amount"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["py_traded_risk"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["py_traded_risk"])}
                                                    name="py_traded_risk"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["py_Non_traded_Risk"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["py_Non_traded_Risk"])}
                                                    name="py_Non_traded_Risk"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData}
                                             isReference={subDis?.isReference}  keyValue={9} index={subIndex}  sub_index={subDisIndex}/>

                                        </tr>
                                        {
                                            (dis_index_id === 19 && subDis.sub_dis_index_id === 8) && (
                                                <tr>
                                                    <td className='text-left pl-3 font-weight-bold text-dark custom-bg-gray-200'> Total</td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "cy_carrying_amount", { start: 1, end: 8 }))} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "cy_traded_risk", { start: 1, end: 8 }))} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "cy_Non_traded_Risk", { start: 1, end: 8 }))} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "py_carrying_amount", { start: 1, end: 8 }))} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "py_traded_risk", { start: 1, end: 8 }))} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200 '> {formatIndianCurrency(calculateTotal(data, "py_Non_traded_Risk", { start: 1, end: 8 }))} </td>
                                                    <DisclosureNBFCTableBody isEmpty={true} refData={[]} isReference={""} />
                                                </tr>

                                            )
                                        }
                                    </>
                                )
                            }
                            {
                                dis_index_id === 20 && (
                                    <>
                                        <tr>
                                            <td className='w-25 border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                        }`}
                                                    type={"text"}
                                                    value={subDis?.header}
                                                    name="header"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["cy_carrying_amount"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["cy_carrying_amount"])}
                                                    name="cy_carrying_amount"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0' >
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["cy_traded_risk"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["cy_traded_risk"])}
                                                    name="cy_traded_risk"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["cy_Non_traded_Risk"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["cy_Non_traded_Risk"])}
                                                    name="cy_Non_traded_Risk"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["py_carrying_amount"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["py_carrying_amount"])}
                                                    name="py_carrying_amount"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["py_traded_risk"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["py_traded_risk"])}
                                                    name="py_traded_risk"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                                />
                                            </td>
                                            <td className='border-top-0 border-bottom-0'>
                                                <input style={{ backgroundColor: "inherit" }}
                                                    className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                    readOnly={typeof subDis["py_Non_traded_Risk"] !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                    type={"text"}
                                                    value={formatIndianCurrency(subDis["py_Non_traded_Risk"])}
                                                    name="py_Non_traded_Risk"
                                                    onChange={(event) => HandleChange(event, subIndex, subDisIndex)}

                                                />
                                            </td>
                                            <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} isReference={subDis?.isReference}  keyValue={9} index={subIndex}  sub_index={subDisIndex} />

                                        </tr>
                                        {
                                            (dis_index_id === 20 && subDis.sub_dis_index_id === 9) && (
                                                <tr>
                                                    <td className='text-left pl-3 font-weight-bold text-dark custom-bg-gray-200'> Total</td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "cy_carrying_amount", { start: 1, end: 9 }))} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "cy_traded_risk", { start: 1, end: 9 }))} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "cy_Non_traded_Risk", { start: 1, end: 9 }))} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "py_carrying_amount", { start: 1, end: 9 }))} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "py_traded_risk", { start: 1, end: 9 }))} </td>
                                                    <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "py_Non_traded_Risk", { start: 1, end: 9 }))} </td>
                                                    <DisclosureNBFCTableBody isEmpty={true} refData={[]} isReference={""} />
                                                </tr>

                                            )
                                        }
                                    </>
                                )
                            }
                        </>

                    ))
                }
            </tbody>
        </>
    );
}