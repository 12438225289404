import React from 'react';
import moment from "moment";
import { useSelector } from 'react-redux';
import { formatIndianCurrency } from '../../../helper/ConvertValue'
import { DisclosureNBFCTableBody, DisclosureNBFCTableHead } from './DisclosureHelper';


export function NBFC35p1of2({ data, subIndex, HandleChange, dis_index_id }) {
    const reduxProjectData = useSelector((initialState) => initialState?.DisclosureReducer?.projectData || {});
    const user = JSON.parse(localStorage.getItem('user'));

    return (
        <>
            <thead>
                {
                    dis_index_id === 1 && (
                        <tr>
                            <th className='text-left w-75 pl-3 border-top-0 border-bottom-0' >As at</th>
                            <th className='w-auto border-top-0 border-bottom-0' >No. of accounts restructured</th>
                            <th className='w-auto border-top-0 border-bottom-0' >Amount outstanding</th>
                            <DisclosureNBFCTableHead isEmpty={false}/>
                        </tr>
                    )
                }

                {
                    (dis_index_id === 2 || dis_index_id === 3) && (
                        <>
                            <tr>
                                <th className='border-top-0 border-bottom-0'>Format-B</th>
                                <th className='border-top-0 border-bottom-0'></th>
                                <th className='border-top-0 border-bottom-0'></th>
                                <th className='border-top-0 border-bottom-0'></th>
                                <th className='border-top-0 border-bottom-0'></th>
                                <th className='border-top-0 border-bottom-0'>
                                    {dis_index_id === 2
                                        ? `As at ${moment(reduxProjectData.current_year).format("DD MMMM YYYY")}`
                                        : `As at ${moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}`}
                                </th>
                                <DisclosureNBFCTableHead isEmpty={false} />

                            </tr>
                            <tr>
                                <th className='text-left pl-3 border-top-0 border-bottom-0' style={{ width: "30%" }}>Type of borrower</th>
                                <th className='w-auto border-top-0 border-bottom-0' >Exposure to accounts classified as Standard consequent to implementation of resolution plan – Position as at the end of the previous half-year (A)</th>
                                <th className='w-auto border-top-0 border-bottom-0' >Of (A), aggregate debt that slipped into NPA during the half-year</th>
                                <th className='w-auto border-top-0 border-bottom-0' >Of (A) amount written off during the half-year</th>
                                <th className='w-auto border-top-0 border-bottom-0' >Of (A) amount paid by the borrowers during the half-year</th>
                                <th className='w-auto border-top-0 border-bottom-0' >Exposure to accounts classified as Standard consequent to implementation of resolution plan – Position as at the end of this half-year</th>
                                <DisclosureNBFCTableHead isEmpty={true} />
                            </tr>
                        </>
                    )
                }
            </thead>
            <tbody>
                {
                    data &&
                    data.length > 0 &&
                    data.map((subDis, subDisIndex) => (
                        <>
                            {
                                dis_index_id === 1 && (
                                    <tr>
                                        <td className='pl-3 text-left border-top-0 border-bottom-0  '>
                                            {subDis.sub_dis_index_id === 1
                                                ? moment(reduxProjectData.current_year).format("DD MMMM YYYY")
                                                : subDis.sub_dis_index_id === 2
                                                    ? moment(reduxProjectData.previous_year).format("DD MMMM YYYY")
                                                    : subDis.sub_dis_index_id === 3
                                                        ? "Total"
                                                        : ""}
                                        </td>

                                        <td className='border-top-0 border-bottom-0'>

                                            <input
                                                style={{ backgroundColor: "inherit" }}
                                                className='p-0 m-0 w-100 border-0 pr-3 text-right'
                                                type={"text"}
                                                readOnly={typeof subDis?.no_of_accounts !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                value={formatIndianCurrency(subDis?.no_of_accounts)}
                                                name="no_of_accounts"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input
                                                style={{ backgroundColor: "inherit" }}
                                                className='p-0 m-0 w-100 border-0 pr-3 text-right'
                                                type={"text"}
                                                readOnly={typeof subDis?.amount !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                value={formatIndianCurrency(subDis?.amount)}
                                                name="amount"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData}
                                              isReference={subDis?.isReference}  keyValue={14} index={subIndex}  sub_index={subDisIndex}/>
                                    </tr>
                                )
                            }
                            {
                                (dis_index_id === 2 || dis_index_id === 3) && (
                                    <tr>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input
                                                style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pl-3 text-left"
                                                type={"text"}
                                                value={subDis?.header}
                                                name="header"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input
                                                style={{ backgroundColor: "inherit" }}
                                                className='p-0 m-0 w-100 border-0 pr-3 text-right'
                                                type={"text"}
                                                readOnly={typeof subDis?.position_end_previous_half_year !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                value={formatIndianCurrency(subDis?.position_end_previous_half_year)}
                                                name="position_end_previous_half_year"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input
                                                style={{ backgroundColor: "inherit" }}
                                                className='p-0 m-0 w-100 border-0 pr-3 text-right'
                                                type={"text"}
                                                readOnly={typeof subDis?.npa_during_the_half_year !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                value={formatIndianCurrency(subDis?.npa_during_the_half_year)}
                                                name="npa_during_the_half_year"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input
                                                style={{ backgroundColor: "inherit" }}
                                                className='p-0 m-0 w-100 border-0 pr-3 text-right'
                                                type={"text"}
                                                readOnly={typeof subDis?.amount_written_during_half_year !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                value={formatIndianCurrency(subDis?.amount_written_during_half_year)}
                                                name="amount_written_during_half_year"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input
                                                style={{ backgroundColor: "inherit" }}
                                                className='p-0 m-0 w-100 border-0 pr-3 text-right'
                                                type={"text"}
                                                readOnly={typeof subDis?.amount_paid_during_half_year !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                value={formatIndianCurrency(subDis?.amount_paid_during_half_year)}
                                                name="amount_paid_during_half_year"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input
                                                style={{ backgroundColor: "inherit" }}
                                                className='p-0 m-0 w-100 border-0 pr-3 text-right'
                                                type={"text"}
                                                readOnly={typeof subDis?.position_end_this_half_year !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                value={formatIndianCurrency(subDis?.position_end_this_half_year)}
                                                name="position_end_this_half_year"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData}
                                            isReference={subDis?.isReference}  keyValue={14} index={subIndex}  sub_index={subDisIndex}/>
                                    </tr>
                                )
                            }
                        </>
                    ))
                }
            </tbody>
        </>
    );
}
