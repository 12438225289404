import ProgressBar from '@ramonak/react-progress-bar';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchConventionalMode } from '../../redux/actions/TemplateTabs/actionCM';
import axios from 'axios';


const CommonProgressBar = () => {
    const dispatch = useDispatch();
    const project_id = localStorage.getItem("project_id");
    const tb_id = localStorage.getItem("tb_id");

    const conventionModeData = useSelector(
        (state) => state.ConventionModeReducer
    );
    const [percentageValue, setPercentageValue] = useState(0);

    useEffect(() => {
        getConventionalMode();
    }, [])

    const getConventionalMode = async () => {
        if (
            !conventionModeData.isSuccess ||
            conventionModeData.set_payload !== JSON.stringify({ project_id, tb_id })
        ) {
            dispatch(fetchConventionalMode(project_id, tb_id));
        }
    };

    useEffect(() => {
        const wholeValue = conventionModeData?.response?.records;
        const partValue = conventionModeData?.response?.data?.line_items.reduce((count, lineItem) => {
            const hasCyMap = lineItem?.map_data?.cy_map?.fs_grp;
            const hasPyMap = lineItem?.map_data?.py_map?.fs_grp;

            if (hasCyMap && hasPyMap) {
                return count + 1;  // Both exist, count as 1
            } else if (hasCyMap || hasPyMap) {
                return count + 0.5;  // Only one exists, count as 0.5
            }

            return count;
        }, 0) || 0;
        const completedValue = Math.floor(((partValue / wholeValue) * 100) || 0);
        setPercentageValue(completedValue)
    }, [conventionModeData])

    const ChooseColor = (percentage) => percentage < 20 ? "#FF5959" :
        percentage >= 20 && percentage <= 50 ? "#FFA04C" :
            percentage > 50 && percentage <= 80 ? "#FAEB65" :
                percentage > 80 && percentage <= 100 ? "#0FC755" : '';

    return (
        <ProgressBar bgColor={ChooseColor(percentageValue)} completed={percentageValue} />
    );
};

export default CommonProgressBar;

