import React from 'react';
import { useSelector } from 'react-redux';
import moment from "moment";
import { formatIndianCurrency } from '../../../helper/ConvertValue'
import { DisclosureNBFCTableBody, DisclosureNBFCTableHead } from './DisclosureHelper';



export function NBFC_31({ data, subIndex, dis_index_id, HandleChange }) {
    const reduxProjectData = useSelector((initialState) => initialState.DisclosureReducer.projectData);
    const user = JSON.parse(localStorage.getItem('user'));


    return (
        <>
            <thead>
                {dis_index_id === 2 && (
                    <tr>
                        <th className='text-left w-50 pl-3 border-top-0 border-bottom-0'>
                            Nature of Relationship
                        </th>
                        <th className='text-left w-50 pl-3 border-top-0 border-bottom-0'>Name of Related Party</th>
                    </tr>
                )}
                {dis_index_id === 3 && (
                    <>
                        <tr>
                            <th className='text-left w-75 pl-3 border-top-0 border-bottom-0'>
                                Nature of Transactions
                            </th>
                            <th className='w-auto border-top-0 border-bottom-0 w-25' style={{ minWidth: "110px" }} > For year ended {" "} {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
                            <th className='w-auto border-top-0 border-bottom-0 ' style={{ minWidth: "110px" }} > For year ended {" "} {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
                            <DisclosureNBFCTableHead isEmpty={false} />
                        </tr>

                    </>
                )}
                {dis_index_id === 4 && (
                    <>
                        <tr>
                            <th className='text-left w-75 pl-3 border-top-0 border-bottom-0' >
                                Balance outstanding as at the year end
                            </th>
                            <th className='w-auto border-top-0 border-bottom-0' style={{ minWidth: "110px" }} > As at {" "} {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
                            <th className='w-auto border-top-0 border-bottom-0' style={{ minWidth: "110px" }} > As at {" "} {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
                            <DisclosureNBFCTableHead isEmpty={false} />
                        </tr>

                    </>
                )}
                {dis_index_id === 5 && (
                    <>
                        <tr>
                            <th className='text-left w-75 pl-3 border-top-0 border-bottom-0'>
                                Maximum outstanding during the year
                            </th>
                            <th className='w-auto border-top-0 border-bottom-0' style={{ minWidth: "110px" }}  > As at  {" "} {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>

                            <th className='w-auto border-top-0 border-bottom-0' style={{ minWidth: "110px" }} >
                                As at  {" "} {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}
                            </th>
                            <DisclosureNBFCTableHead isEmpty={false} />
                        </tr>

                    </>
                )}
                {dis_index_id === 6 && (
                    <>
                        <tr>
                            <th className='text-left w-75 pl-3 border-top-0 border-bottom-0'>
                                Key Management Personnel For year ended
                            </th>
                            <th className='w-auto border-top-0 border-bottom-0' style={{ minWidth: "110px" }} >
                                For year ended  {" "} {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}
                            </th>
                            <th className='w-auto border-top-0 border-bottom-0' style={{ minWidth: "110px" }} >
                                For year ended  {" "} {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}
                            </th>
                            <DisclosureNBFCTableHead isEmpty={false}/>
                        </tr>

                    </>
                )}
            </thead>

            <tbody>
                {data &&
                    data.length > 0 &&
                    data.map((subDis, subDisIndex) => (
                        <>
                            {dis_index_id === 2 && (
                                <>
                                    <tr key={subDisIndex}>
                                        <td className='border-top-0 border-bottom-0' rowSpan={Array.isArray(subDis.name_related_party) ? subDis.name_related_party.length : 1}>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                    }`}
                                                type={"text"}
                                                value={subDis?.header}
                                                name="header"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                    }`}
                                                type={"text"}
                                                disabled={user.responsibilities === "view"||user.responsibilities === "approver"}
                                                value={Array.isArray(subDis.name_related_party) ? subDis.name_related_party[0] : subDis.name_related_party}
                                                name="name_related_party"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex, true, 0)}
                                            />
                                        </td>
                                    </tr>

                                    {Array.isArray(subDis.name_related_party) &&
                                        subDis.name_related_party.slice(1).map((name, subIndexName) => (
                                            <tr key={`${subDisIndex}-${subIndexName}`}>
                                                <td className='border-top-0 border-bottom-0'>
                                                    <input style={{ backgroundColor: "inherit" }}
                                                        className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                            }`}
                                                        type={"text"}
                                                        value={name}
                                                        disabled={user.responsibilities === "view"||user.responsibilities === "approver"}
                                                        name="name_related_party"
                                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex, true, (subIndexName + 1))}
                                                    />
                                                </td>

                                            </tr>
                                        ))}
                                </>
                            )}
                            {dis_index_id === 3 && (
                                <>
                                    <tr >
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                    }`}
                                                type={"text"}
                                                value={subDis?.header}
                                                name="header"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>

                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                readOnly={typeof subDis?.cy !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                type={"text"}
                                                value={formatIndianCurrency(subDis?.cy)}
                                                name="cy"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={"text"}
                                                readOnly={typeof subDis?.py !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                value={formatIndianCurrency(subDis?.py)}
                                                name="py"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} 
                                            isReference={subDis?.isReference}  keyValue={3} index={subIndex}  sub_index={subDisIndex}/>
                                    </tr>
                                </>
                            )}
                            {dis_index_id === 4 && (
                                <>
                                    <tr >
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                    }`}
                                                type={"text"}
                                                value={subDis?.header}
                                                name="header"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>

                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                readOnly={typeof subDis?.cy !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                type={"text"}
                                                value={formatIndianCurrency(subDis?.cy)}
                                                name="cy"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={"text"}
                                                readOnly={typeof subDis?.py !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                value={formatIndianCurrency(subDis?.py)}
                                                name="py"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData}
                                         isReference={subDis?.isReference} keyValue={3} index={subIndex}  sub_index={subDisIndex} />
                                    </tr>
                                </>
                            )}
                            {dis_index_id === 5 && (
                                <>
                                    <tr >
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                    }`}
                                                type={"text"}
                                                value={subDis?.header}
                                                name="header"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                readOnly={typeof subDis?.cy !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                type={"text"}
                                                value={formatIndianCurrency(subDis?.cy)}
                                                name="cy"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={"text"}
                                                readOnly={typeof subDis?.py !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                value={formatIndianCurrency(subDis?.py)}
                                                name="py"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        {/* <td className='border-top-0 border-bottom-0'>
                                            
                                        </td> */}

                                        <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} 
                                           isReference={subDis?.isReference}  keyValue={3} index={subIndex}  sub_index={subDisIndex} />
                                    </tr>
                                </>
                            )}
                            {dis_index_id === 6 && (
                                    <tr>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input
                                                className={`p-0 m-0 w-100 border-0 pl-3 text-left bg-color-inherit ${subDis?.isHeading && "font-weight-bold"
                                                    }`}
                                                type={"text"}
                                                value={subDis?.header}
                                                name="header"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                                                readOnly={typeof subDis?.cy !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                type={"text"}
                                                value={formatIndianCurrency(subDis?.cy)}
                                                name="cy"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                                                type={"text"}
                                                readOnly={typeof subDis?.py !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                value={formatIndianCurrency(subDis?.py)}
                                                name="py"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData}
                                            isReference={subDis?.isReference}  keyValue={3} index={subIndex}  sub_index={subDisIndex} />
                                    </tr>
                            )}
                        </>
                    ))}
            </tbody>
        </>
    );
}