import { React, useEffect, useState } from "react";
import NavBarV2 from "../../../NavBarV2";
import Card from "react-bootstrap/Card";
import Logo5 from "../../../../assets/user.svg";
import SearchIcon from "@mui/icons-material/Search";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import AddIcon from "@mui/icons-material/Add";
import TableContainer from "@mui/material/TableContainer";
import Tab from "@mui/material/Tab";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import CloseIcon from '@mui/icons-material/Close';
// import DashboardLogo from "../../../../assets/dashboard1.svg";
// import CloudIcon from "@mui/icons-material/Cloud";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";
// import Radio from "@mui/material/Radio";
// import moment from "moment";
// import Worklogo from "../../../assets/dashboardworkspace.svg";
// import CardContent from "@mui/material/CardContent";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { InputAdornment, TextField } from "@mui/material";
import Table from "react-bootstrap/Table";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import "../../../../WorkSpace/SignUp2.css";
// import AddCompanyModal from "../../Modal/editCompanyModal";
import "../../../../WorkSpace/font.css";
import Tabsystem from "../../../Workspace-v2/Tabsystem";
import Tabs from "@mui/material/Tabs";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover from "@mui/material/Popover";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Navbar } from "../../../../Components/Navbar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import swal from "sweetalert";
import InvestmentModal from "../../Modal/investmentModal";
import { IrRegister } from "../../../../Console/Component/services/apiHandler";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  cities,
  createCompany,
  getCompany,
  state,
} from "../../ApiServices/apiHandler";
import NestedModal from "../../Modal/addAccount";
import AddAccount from "../../Modal/addAccount";
const style5 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 589,
  height: 751,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};

const CompanyDetails = (props) => {
  const {
    style,
    handleClosetab,
    handleClick,
    handleClose,
    continueStep,
    multipleCompany,
    countCompany,
    handleAllCompany,
    companyall
  } = props;
  const user = JSON.parse(localStorage.getItem('user'));
  const [checkContinueBotton, setCheckContinueBotton] = useState(false);
  const [findData, setFindData] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [Open, setOpen] = useState(false);
  const [allcities, setCitiesData] = useState([]);
  const [allstate, setStateData] = useState([]);
  const [addCompanyshow, setAddCompany] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [newcompany, setCompany] = useState({
    org_name: "",
    ci_no: "",
    pan_no: "",
    nature_of_company: "",
    functional: "",
    city: "",
    state: "",
    address: "",
  });
  const [approver,setApprover]=useState({
    approver_id:""
  });
  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleCloseW = () => {
    setAddCompany(false);
  };
  const addCompany = () => {
    console.log("000000000");
    setAddCompany(true);
  };
  const handleChangeData = (data, field) => {
    // console.log(data, "ooooo",field);
    if (data.length > 0) {
      setCheckContinueBotton(true);
    }
    const checkData = multipleCompany.filter((det)=>det === data);
    console.log(checkData,"0000000000");
    if (checkData.length > 0) {
        setFindData(true)
    }else{
      setFindData(false)
    }
    setSearchTerm(data);

  };

  const citiesData = async () => {
    const rescities = await cities();
    const citiesall = rescities.data.CitiesData.cities.map((data) => data.name);
    setCitiesData(citiesall);
  };
  const onInputChangecity = (event, value) => {
    if (value && value.length > 3) {
      citiesData();
    } else {
      setCitiesData([]);
    }
  };
  const stateData = async () => {
    const res = await state();
    const stateall = res.data.StateData.state.map((data) => data.name);
    setStateData(stateall);
  };
  const onInputChange = (event, value) => {
    if (value && value.length > 3) {
      stateData();
    } else {
      setStateData([]);
    }
  };
  const handleDelete = () => {
    console.info("You clicked the delete icon.");
  };
  const handleChangeDataSubmit = (value, field) => {
    setCompany({ ...newcompany, [field]: value });
  };

  const handleOpen = () => {
    setOpen3(true);
  };
  const handleClosechild = () => {
    setOpen3(false);
  };
  const handleApproverChange = (value,field) => {
    setApprover({...approver,[field]: value});
  };

  const submitCompany = async () => {
    let company_code =
      newcompany.org_name.slice(0, 3) + Math.floor(1000 + Math.random() * 9000);

      let data={}
      if(user.responsibilities==="preparer"){
        data = {
          companyName: newcompany.org_name,
          companyCode: company_code,
          ci_no: newcompany.ci_no,
          pan_no: newcompany.pan_no,
          nature_of_company: newcompany.nature_of_company,
          functional: newcompany.functional,
          city: newcompany.city,
          state: newcompany.state,
          address: newcompany.address,
          has_published:"draft",
          status:"waiting",
          approver_id:approver.approver_id,
          preparer_id:user._id
        };
      }else{
         data = {
          companyName: newcompany.org_name,
          companyCode: company_code,
          ci_no: newcompany.ci_no,
          pan_no: newcompany.pan_no,
          nature_of_company: newcompany.nature_of_company,
          functional: newcompany.functional,
          city: newcompany.city,
          state: newcompany.state,
          address: newcompany.address,
          has_published:"published",
        };
      }
    const result = await createCompany(data);
    if (result.status === 200) {
      handleClose2();
      handleClosechild();
    }
    console.log(result.data);
    if (result.data.status === false) {
      swal("", result.data.message, "error");
    }
  };

  const handleOpen2 = () => {
    if(user.responsibilities==="approver"|| user.responsibilities==="view"){
      swal("Warning", "You Don't have Access to Create Company", "warning");
    }else{
    setOpen2(true);
    }
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  return (
    <>
      {multipleCompany.length > 0 ? (
        <>
       <div>
        <h5 className="dbwcardhead">Companies list ({countCompany})</h5>
        <div className="dbwcardhead-viewall" style={{ cursor: "pointer" }}><span onClick={handleAllCompany}>View all</span> &nbsp; &nbsp; 
        <span  onClick={handleOpen2}><AddIcon /></span></div>
        <p className="dbwcardpar">Track the list of companies you work with in one place</p>

          <Card className="dahboardCard2V2">
            <Card.Body>
              <Card.Title style={{ textAlign: "center" }}></Card.Title>
              <div>
                <div
                  className="row"
                  style={{ overflowY: "scroll", maxHeight: "16rem" }}
                >
                  {multipleCompany?.slice(-5).map((rew) => {
                    return (
                      <>
                        <div className="col-12">
                          <div>
                            <div className="db-wp-company-list">
                                <BusinessCenterIcon
                                  style={{ color: "#03565A" }}
                                />
                              &nbsp;
                              <span>{rew}</span>
                            </div>
                            <div className="list_id">
                              <small
                                style={{
                                  color: "gray",
                                  marginLeft: "30px",
                                }}
                              >
                                {/* &nbsp;10 */}
                              </small>
                            </div>
                          </div>
                          <hr
                            style={{
                              width: "100%",
                              marginTop: "3px",
                            }}
                          />
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </Card.Body>
          </Card>
       </div>
        </>
      ) : (
        <Card className="db-empty-company-card">
          <Card.Body>
            <Card.Title style={{ textAlign: "center" }}>
              <img src={Logo5} alt="logo5" />
            </Card.Title>
            <div style={{ textAlign: "center" }}>
              <Card.Text>There are no companies listed</Card.Text>
              <Button className="wp-btn-no-fill"  onClick={handleOpen2}>
              <AddIcon /> Add companies
              </Button>
              
            </div>
          </Card.Body>
        </Card>
      )}

     
      <AddAccount
      open2 ={open2}
      handleClose2 ={handleClose2}
      handleChangeData={handleChangeData}
      findData={findData}
      searchTerm={searchTerm}
      multipleCompany={multipleCompany}
      handleChangeDataSubmit={handleChangeDataSubmit}
      submitCompany={submitCompany}
      handleCloseModal={handleCloseModal}
      open={open3}
      handleClose ={handleClosechild}
      handleOpen={handleOpen}
      checkContinueBotton={checkContinueBotton}
      handleApproverChange={handleApproverChange}
      />
    </>
  );
};
export default CompanyDetails;
