import React, { useEffect, useState, useContext } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ThemeContext } from '../../../helper/DarkModeContext';
import { level1, type, total, level2, Note, level4 } from './tableStyle';
import { formatIndianCurrency } from '../../../helper/ConvertValue';
import { useDispatch } from "react-redux";
import { addFsGrpForNoteGrp } from '../../../redux/actions/TemplateTabs/actionDragSelector';
import { socket } from '../../../services/socket/socket';
import { CurrentProjectCy, CurrentProjectPy } from '../../../helper/pnlCalculation';

const TableCell = ({ content, columnIndex, rowIndex, groupIndex, moveCell, isDraggable, cellKey, cellData, handleClick }) => {
    const [{ isDragging }, drag, preview] = useDrag({
      type: 'TABLE_CELL',
      item: { content, columnIndex, rowIndex, groupIndex, isDraggable },
      canDrag: () => isDraggable,
      options: {
        previewOptions: {
          captureDraggingState: true,
        },
      },
    });
  
    const [, drop] = useDrop({
      accept: 'TABLE_CELL',
      canDrop: () => isDraggable,
      drop: (draggedItem, monitor) => {
        if (
          draggedItem.rowIndex !== rowIndex ||
          draggedItem.columnIndex !== columnIndex ||
          draggedItem.groupIndex !== groupIndex
        ) {
          moveCell(draggedItem.columnIndex, draggedItem.rowIndex, columnIndex, rowIndex, groupIndex);
          draggedItem.rowIndex = rowIndex;
          draggedItem.columnIndex = columnIndex;
          draggedItem.groupIndex = groupIndex;
        }
      },
    });
  
    return (
        <>
        {cellKey === 'notes_no' ? 
            <td className="font-sans" style={Note} onClick={()=>handleClick(cellData.notes_index)}>
            {cellData.notes_index}
            </td>
            :
            <td
                className={`font-sans ${ ['cy', 'py'].includes(cellKey) ? "text-right pr-3" : "text-left pl-3" }`}
                ref={(node) => {
                preview(drop(node)); // Pass preview ref to drop
                drag(node); // Attach drag ref
                }}
                style={{
                ...level2,
                opacity: isDragging ? 0.5 : 1,
                transition: 'opacity 0.3s ease',
                cursor: isDraggable ? 'grab' : 'default', // Set cursor style based on draggability
                }}
            >
                {['cy', 'py'].includes(cellKey) ? formatIndianCurrency(content) : content}
            </td>
}
        </>
    );
};
  
const TableRow = ({ cells, rowIndex, groupIndex, moveCell, isDraggable, handleClick }) => {
return (
    <tr>
    {Object.keys(cells).filter(item => !['refData', 'notes_index', 'group', 'headings'].includes(item)).map((content, columnIndex) => (
        <TableCell
            key={columnIndex}
            content={cells[content]}
            cellKey={content}
            cellData={cells}
            columnIndex={columnIndex}
            rowIndex={rowIndex}
            groupIndex={groupIndex}
            moveCell={moveCell}
            isDraggable={isDraggable && columnIndex === 0}
            handleClick={handleClick}
        />
    ))}
    </tr>
);
};
  
export const BSTableDND = ({headerData, groupData, tableData, handleClick, TotalNoteGroupsValue, companyType, setAddNoteGrp}) => {
    const [headers, setHeaders] = useState([]);
    const [groups, setGroups] = useState([]);
    const [rows, setRows] = useState({});
    const { theme } = useContext(ThemeContext);
    const dispatch = useDispatch();
    const project_id = localStorage.getItem("project_id");
    const tb_id = localStorage.getItem("tb_id");
    const user = JSON.parse(localStorage.getItem('user'));
    useEffect(()=>{
        setHeaders(headerData);
        setGroups(groupData);
        setRows(tableData)
    }, [headerData, groupData, tableData])

    const TotalAssets = (type) => {
        const currentAssets = companyType === 'NBFC' ? 'Financial assets' : 'Current Assets';
        const nonCurrentAssets = companyType === 'NBFC' ? 'Non-financial Assets' : 'Non Current Assets';
        return TotalNoteGroupsValue(nonCurrentAssets, type) + TotalNoteGroupsValue(currentAssets, type)
      }
    
      const TotalLiabilities = (type) => {
        const currentLiabilities = companyType === 'NBFC' ? 'Financial liabilities' : 'Current Liabilities';
        const nonCurrentLiabilities = companyType === 'NBFC' ? 'Non-financial liabilities' : 'Non Current Liabilities';
        return TotalNoteGroupsValue(nonCurrentLiabilities, type) + TotalNoteGroupsValue(currentLiabilities, type)
      }

    const moveCell = (startColumnIndex, startRowIndex, endColumnIndex, endRowIndex, groupIndex) => {
        const updatedRows = { ...rows };
        const draggedCell = updatedRows[groups[groupIndex]][startRowIndex]
        const endDraggedCell = updatedRows[groups[groupIndex]][endRowIndex]
        updatedRows[groups[groupIndex]][startRowIndex] = {
        ...updatedRows[groups[groupIndex]][startRowIndex],
        notes_grp: endDraggedCell.notes_grp}
        
        updatedRows[groups[groupIndex]][endRowIndex] = { 
        ...endDraggedCell, 
        notes_grp: draggedCell.notes_grp};
        socket.emit("rearrange-balance-sheet", {
            project_id: project_id,
            tb_id: tb_id,
            fsGroup: groups[groupIndex], 
            draggedIndex: startRowIndex, 
            targetIndex: endRowIndex
        })
        setRows(updatedRows);
    };

    return (
        <DndProvider backend={HTML5Backend}>
        <div>
            <table style={{ width: '100%' }}>
            <thead>
                <tr>
                {headers.map((header, index) => (
                    <th
                    key={index}
                    className="font-size-18 font-sans"
                    style={{
                        padding: '8px',
                        border: '1px solid #ccc',
                        margin: '15px',
                        backgroundColor:"#a2c0c2",
                    }}
                    >
                    {header}
                    </th>
                ))}
                </tr>
            </thead>
            <tbody
            style={{
                background: `${theme ? "" : "#363535"}`,
                color: `${theme ? "" : "#fff"}`,
              }}
            >
                {groups.map((group, groupIndex) => (
                <React.Fragment key={groupIndex}>
                    <tr style={level1}>
                        <td className="font-sans text-left pl-3">
                            {group}
                        </td>
                        <td></td>
                        <td className='font-sans'>{formatIndianCurrency(TotalNoteGroupsValue(group, 'cy'))}</td>
                        <td className='font-sans'>{formatIndianCurrency(TotalNoteGroupsValue(group, 'py'))}</td>
                    </tr>
                    {rows[group].map((cells, rowIndex) => (
                    <TableRow
                        key={rowIndex}
                        cells={{...cells, group: group}}
                        rowIndex={rowIndex}
                        groupIndex={groupIndex}
                        moveCell={moveCell}
                        isDraggable={true}
                        handleClick={handleClick}
                    />
                    ))}
                  <tr>
                  <td
                  className="font-sans"
                    onClick={()=>{if (user.responsibilities !== "approver" && user.responsibilities !== "view"){setAddNoteGrp(true); dispatch(addFsGrpForNoteGrp({name:group, type: 'bs'}))}}}
                    style={{
                      width: "50%",
                      textAlign: "left",
                      fontWeight: "600",
                      paddingLeft: "1rem",
                      color: "var(--clr-accent)",
                      cursor: "pointer",
                    }}
                  >
                    + Add Note group
                  </td>
                  <td className="font-sans" style={{ width: "25%" }}></td>
                  <td className="font-sans" style={{ width: "25%" }}></td>
                  <td className="font-sans" style={{ width: "25%" }}></td>
                </tr>
                {
                  groupIndex === 1 && (
                    <tr className="font-sans" key={groupIndex} style={total}>
                      <td className="font-sans">
                        {companyType === 'NBFC'? 'Total Financial and Non Financial Assets' : "Total Non Current & Current Assets"}
                      </td>
                      <td></td>
                      <td className="font-sans">{ formatIndianCurrency(TotalAssets('cy')) }</td>
                      <td className="font-sans" style={{ textAlign:"right" }}>{ formatIndianCurrency(TotalAssets('py')) }</td>
                    </tr>
                  )
                }
                {
                  groupIndex === 4 && (
                    <React.Fragment>
                    <tr key={groupIndex} style={total}>
                      <td className="font-sans">
                      {companyType === 'NBFC'? 'Total Financial and Non-Financial liabilities' : "Total Non Current & Current Liabilities"}
                      </td>
                      <td></td>
                      <td className="font-sans">{ formatIndianCurrency(TotalLiabilities('cy')) }</td>
                      <td className="font-sans" style={{ textAlign:"right" }}>{ formatIndianCurrency(TotalLiabilities('py')) }</td>
                    </tr>
                    <tr key={groupIndex} style={total}>
                      <td className="font-sans">
                        {"Total Equity & Liabilities"}
                      </td>
                      <td></td>
                      <td className="font-sans">{ formatIndianCurrency(TotalNoteGroupsValue("Equity", 'cy') + TotalLiabilities('cy')) }</td>
                      <td className="font-sans" style={{ textAlign:"right" }}>{ formatIndianCurrency(TotalNoteGroupsValue("Equity", 'py') + TotalLiabilities('py')) }</td>
                    </tr>
                    </React.Fragment>
                  )
                }
                </React.Fragment>
                ))}
            </tbody>
            </table>
        </div>
        </DndProvider>
    );
};

export const PNLTableDND = ({headerData, groupData, tableData, handleClick, TotalNoteGroupsValue, companyType, setAddNoteGrp}) => {
    const [headers, setHeaders] = useState([]);
    const [groups, setGroups] = useState([]);
    const [rows, setRows] = useState({});
    const { theme } = useContext(ThemeContext);
    const dispatch = useDispatch();
    const project_id = localStorage.getItem("project_id");
    const tb_id = localStorage.getItem("tb_id");
    const user = JSON.parse(localStorage.getItem('user'));

    useEffect(()=>{
        setHeaders(headerData);
        setGroups(groupData);
        setRows(tableData)
    }, [headerData, groupData, tableData])

    const moveCell = (startColumnIndex, startRowIndex, endColumnIndex, endRowIndex, groupIndex) => {
        const updatedRows = { ...rows };
        const draggedCell = updatedRows[groups[groupIndex]][startRowIndex]
        const endDraggedCell = updatedRows[groups[groupIndex]][endRowIndex]
        updatedRows[groups[groupIndex]][startRowIndex] = {
        ...updatedRows[groups[groupIndex]][startRowIndex],
        notes_grp: endDraggedCell.notes_grp}
        
        updatedRows[groups[groupIndex]][endRowIndex] = { 
        ...endDraggedCell, 
        notes_grp: draggedCell.notes_grp};
        socket.emit("rearrange-pnl-statement", {
            project_id: project_id,
            tb_id: tb_id,
            fsGroup: groups[groupIndex], 
            draggedIndex: startRowIndex, 
            targetIndex: endRowIndex
        })
        setRows(updatedRows);
    };

    const calCulateProfitBefore = (type) => {
        try {
          const income = companyType === 'NBFC' ? 'Revenue from operations' : 'Income';
          const expenses = 'Expenses'
          const otherIncome = 'Other Income'
          if(type === "cy"){
            const currProject = CurrentProjectCy(tableData, income) + CurrentProjectCy(tableData, otherIncome)
            return ( companyType === 'NBFC' ? currProject : CurrentProjectCy(tableData, income) ) - CurrentProjectCy(tableData, expenses)
          }else if(type === "py"){
            const currProject = CurrentProjectPy(tableData, income) + CurrentProjectPy(tableData, otherIncome)
            return ( companyType === 'NBFC' ? currProject : CurrentProjectPy(tableData, income) ) - CurrentProjectPy(tableData, expenses)
          }
        } catch (error) {
          console.log("err", error.message)
          return "**"
        }
      }
    
      const calProfitBeforeTax = (type) => {
        if(type === 'cy'){
          return calCulateProfitBefore(type) - CurrentProjectCy(tableData, 'Profit before exceptional items and tax');
        }else if(type === "py"){
          return calCulateProfitBefore(type) - CurrentProjectPy(tableData, 'Profit before exceptional items and tax');
        }
      }
    
      const calProfitContinuing = (type) => {
        if(type === 'cy'){
          return calProfitBeforeTax(type) - CurrentProjectCy(tableData, 'Tax expense');
        }else if(type === "py"){
          return calProfitBeforeTax(type) - CurrentProjectPy(tableData, 'Tax expense');
        }
      }

    return (
        <DndProvider backend={HTML5Backend}>
            <div>
                <table style={{ width: '100%' }}>
                    <thead>
                        <tr>
                        {headers.map((header, index) => (
                            <th
                            key={index}
                            className="font-size-18 font-sans"
                            style={{
                                padding: '8px',
                                border: '1px solid #ccc',
                                margin: '15px',
                                backgroundColor:"#a2c0c2",
                            }}
                            >
                            {header}
                            </th>
                        ))}
                        </tr>
                    </thead>
                    <tbody
                        style={{
                            background: `${theme ? "" : "#363535"}`,
                            color: `${theme ? "" : "#fff"}`,
                        }}
                    >
                        {groups.map((group, groupIndex) => (
                        <React.Fragment key={groupIndex}>
                            <tr style={level1}>
                                <td className="font-sans text-left pl-3">
                                    {group}
                                </td>
                                <td></td>
                                <td className='font-sans'>
                                {companyType === 'NBFC' && group === 'Profit before exceptional items and tax' 
                                ? formatIndianCurrency(calCulateProfitBefore('cy'))
                                : companyType === 'NBFC' && group === 'Profit before tax' 
                                ? formatIndianCurrency(calProfitBeforeTax('cy'))
                                : companyType === 'NBFC' && group === 'Profit for the period from continuing operations'
                                ? formatIndianCurrency(calProfitContinuing('cy'))
                                : formatIndianCurrency(TotalNoteGroupsValue(group, 'cy'))
                                }
                                </td>
                                <td className='font-sans'>
                                    {companyType === 'NBFC' && group === 'Profit before exceptional items and tax' 
                                    ? formatIndianCurrency(calCulateProfitBefore('py'))
                                    : companyType === 'NBFC' && group === 'Profit before tax' 
                                    ? formatIndianCurrency(calProfitBeforeTax('py'))
                                    : companyType === 'NBFC' && group === 'Profit for the period from continuing operations'
                                    ? formatIndianCurrency(calProfitContinuing('py'))
                                    : formatIndianCurrency(TotalNoteGroupsValue(group, 'py'))
                                    }
                                </td>
                            </tr>
                            {rows[group].map((cells, rowIndex) => (
                              <>
                              {
                                group === "Expenses" && cells.notes_grp === "Current tax" &&
                                <tr style={level1}>
                                  <td> Tax expense </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              }
                            <TableRow
                                key={rowIndex}
                                cells={{...cells, group: group}}
                                rowIndex={rowIndex}
                                groupIndex={groupIndex}
                                moveCell={moveCell}
                                isDraggable={true}
                                handleClick={handleClick}
                            />
                            </>
                            ))}
                            {!(companyType === 'NBFC' && ['Profit before exceptional items and tax', 'Profit before tax', 'Tax expense', 'Profit for the period from continuing operations'].includes(group)) && 
                            <tr>
                              <td
                                onClick={()=>{if (user.responsibilities !== "approver" && user.responsibilities !== "view"){setAddNoteGrp(true); dispatch(addFsGrpForNoteGrp({name:group, type: 'pnl'}))}}}
                                style={{
                                    width: "50%",
                                    textAlign: "left",
                                    fontWeight: "600",
                                    paddingLeft: "1rem",
                                    color: "var(--clr-accent)",
                                    cursor: "pointer",
                                }}
                                >
                                + Add Note group
                                </td>
                                <td style={{ width: "25%" }}></td>
                                <td style={{ width: "25%" }}></td>
                                <td style={{ width: "25%" }}></td>
                            </tr>
                            }
                        </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        </DndProvider>
    )
}