import React from "react";
import CkEditor from '../Common/CkEditor';
import { socket } from '../../services/socket/socket';
import swal from 'sweetalert';

export default function Note1({notesData}) {
  const CommentRemarkChange = (comment) => {
    socket.emit("notes-comment-auto-save", {
        note_id: notesData?._id,
        comment
    });
}

socket.on('notes-comment-auto-save', (data) => {
    if(!data.success){
        swal("Error", data.message, "error")
    }
});

  return (
    <div>
      {/* <div style={{ padding: '0 25px', display: 'flex' }}>
        <div style={{ width: '300px' }}>
          <input type='text' style={{ border: 'none', fontWeight: '600', backgroundColor: 'transparent' }} placeholder='enter title here'></input>
        </div>
      </div> */}

      <div style={{ padding: '0 20px', minHeight: '500px', zIndex: '0' }}>

        <CkEditor
          value={notesData?.comment}
          onChange={(data) => CommentRemarkChange(data)}
        />
      </div>
    </div>
  );
}