import React, { useState, useEffect } from 'react'
import { Navbar } from '../../Components/Navbar'
import { Submit } from '../../Components/Button'
import swal from "sweetalert";
import axios from "axios";
import { comanyUpdate,getcomanyUpdate } from '../../WorkSpace/Components/ApiServices/apiHandler'
import { getProfile, logoutUser } from '../../services/api/apiHandler';
import { logout } from '../../authentication/authUtilities';
import { useHistory } from 'react-router';
import {  toast } from 'react-toastify';

const ProfileChange = () => {
  const history = useHistory();
  const [setprofile, setProfileData] = useState({
    id: "",
    email: "",
    oldemail: "",
    password: "",
    OldPassword:""
  });
  const [setdomain, setDomainData] = useState({
    domain: "",
  });
  const handleProfileChange = (id, e) => {
    const { name, value } = e.target;
    setProfileData({ ...setprofile, [name]: value })
  }

  const handledomainChange = (id, e) => {
    const { name, value } = e.target;
    setDomainData({ ...setdomain, [name]: value })
  }

  useEffect(() => {
    getProfile().then(response => {
      if (response.status === 200) {
        console.log("test profile api")
        setProfileData({
          id: response.data.user.data._id,
          email: response.data.user.data.email,
          oldemail: response.data.user.data.email,
          password: "",
          oldPassword:""
        });
        if(response.data.user.data._id) { getUpdateDomain(response.data.user.data._id); }
        swal("Success", response.data.message, "success")
      }
    }).catch((error) => {
      console.log(error.response, "error");
      // swal("", error.response.data.error, "error");
    });
  }, []);

  const getUpdateDomain = async(id) =>{
    try {
          const domaindata = await getcomanyUpdate(id)
          console.log(domaindata,"domaindata===")
          if(domaindata.status === 200){
            setDomainData({
               domain:domaindata.data.data.domain
            })

          }
      
    } catch (error) {
      console.log(error);
    }
  }


  const handleDomainUpdate = async(id, e) => {
    console.log(e);
    e.preventDefault();
    try {
        
      const data = {
        domain:setdomain.domain,
        user_id:id
      }

      const companydata = await comanyUpdate(data)

      if(companydata.status === 200){
        swal("Successfully Update")
        // data.domain("")
      }
      
    } catch (error) {
       console.log(error)
    }

  
  }

  const handleUpdate = (id, e) => {
    const auth = localStorage.getItem("auth_token");
    let headers = {
      "x-auth-token": auth,
      "Content-Type": "application/json",
    };
    axios
      .post(`api/v1/auth/update-profile`, setprofile, { headers })
      .then((response) => {
        if (response.status === 200) {
          swal("Success", "Password Updated Successfully", "success").then(() => {
          logoutUser().then(response => {
            toast.success("Logout Successfully");
            logout();
            history.push("/");
          }).catch(error => {
            toast.error(error.response?.data?.error || "Logout Failed!");
          })
        });
        
        }
      })
      .catch((error) => {
        swal("Error", error.response.data.message, "error");
      });
  }




  return (
    <>
      <div className="pb-2">
        <div>
          <Navbar />
        </div>
      </div>
      <div className="dahboardHeaderV2">
        <div className="row">
          <div className="col-md-12">
            <h5 className="dbwcardhead">Update Profile</h5>
          </div>
        </div>

        <div className="row" style={{ marginTop: "20px" }}>
          <div className="col-md-12">
            <label>Email</label>
            <div className='inputs in-1'>
              <input
                name='email'
                disabled
                onChange={(e) => handleProfileChange(setprofile.id, e)}
                value={setprofile.email}
              ></input>
            </div>
          </div>
          <div className="col-md-12">
            <label>Old Password</label>
            <div className='inputs in-1'>
              <input
                name='oldPassword'
                onChange={(e) => handleProfileChange(setprofile.id, e)}
                value={setprofile.oldPassword}
              ></input>
            </div>
          </div>
          <div className="col-md-12">
            <label>New Password</label>
            <div className='inputs in-1'>
              <input
                name='password'
                onChange={(e) => handleProfileChange(setprofile.id, e)}
                value={setprofile.password}
              ></input>
            </div>
          </div>
          <div className="col-md-12">
            <button className="rwp-btn" onClick={(e) => {
              handleUpdate(setprofile.id, e);
            }}>
              Update
            </button>
          </div>
        </div>
      </div>

      <div className="dahboardHeaderV2">
        <div className="row">
          <div className="col-md-12">
            <h5 className="dbwcardhead">Company Update</h5>
          </div>
        </div>

        <div className="row" style={{ marginTop: "20px" }}>
          <div className="col-md-12">
            <label>Domain</label>
            <div className='inputs in-1'>
              <input
                name='domain'
                onChange={(e) => handledomainChange(setdomain, e)}
                value={setdomain.domain}
              ></input>
            </div>
          </div>
          <div className="col-md-12">
            <label>Bio</label>
            <div className='inputs in-1'>
              <input
                // name='email'
                // onChange={(e) => handleProfileChange(setprofile.id, e)}
                // value={setprofile.email}
              ></input>
            </div>
          </div>
          <div className="col-md-12">
            <label>Logo</label>
            <div className='inputs in-1'>
              <input
                // name='email'
                // onChange={(e) => handleProfileChange(setprofile.id, e)}
                // value={setprofile.email}
              ></input>
            </div>
          </div>
          <div className="col-md-12">
            <button className="rwp-btn" onClick={(e) => {
              handleDomainUpdate(setprofile.id, e);
            }}>
              Update
            </button>
          </div>
        </div>
      </div>
    </>

  )
}
export default ProfileChange