import React from "react"
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import swal from "sweetalert";
import { softDeleteNotesData } from "../../../services/api/apiHandler";
import { socket } from "../../../services/socket/socket";
import { useDispatch } from "react-redux";
import { clearSoftDeleteNotes } from "../../../redux/actions/TemplateTabs/actionDN";


const NoteSoftDelete = (props) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const { softDeletedData } = props;
    const project_id = localStorage.getItem("project_id");
    const tb_id = localStorage.getItem("tb_id");
    const dispatch = useDispatch();

    const handleSoftDelete = () => {
        const payload = {
            "selectedNotes": softDeletedData
        }

        softDeleteNotesData(project_id, payload).then(()=> {
            socket.emit("request-mapped-grouping", {project_id, tb_id});
            socket.emit("request-balance-sheet", {project_id, tb_id})
            socket.emit("request-pnl-statement", {project_id, tb_id});
            socket.emit("request-fetch-console-notes", { pid: project_id });
            dispatch(clearSoftDeleteNotes());
        }).catch((err)=>{
            swal("Error!", err.message, "error", {
                button: "OK",
            });
        })
    }

    const handleClick = () => {
        swal({
            title: "Are you sure to delete the notes?",
            text: "you can still restore the deleted note!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
              handleSoftDelete();
              swal("Poof! Selected Notes has been deleted!", {
                icon: "success",
              });
            }
          });
    }

    return (
        <Tooltip title="Notes Soft Delete">
            <IconButton onClick={handleClick} disabled={user.responsibilities === "approver" || user.responsibilities === "view"}>
                <DeleteOutlineIcon/>
            </IconButton>
        </Tooltip>
    )
}


export default NoteSoftDelete