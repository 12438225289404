import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CalendarIcon from "../../assets/Calendar.svg"; // Import custom Calendar icon

export const customStyles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: "#F4FAF5",
        borderColor: state.isFocused ? "#C2E6D1" : provided.borderColor,
        boxShadow: state.isFocused ? "0 0 0 0" : provided.boxShadow,
        "&:hover": {
            borderColor: state.isFocused
                ? "#C2E6D1"
                : provided["&:hover"].borderColor,
        },
        minHeight: "50px",
        height: "50px",
        borderRadius: "10px", 
    }),
    menu: (provided) => ({
        ...provided,
        borderRadius: "10px", 
        overflow: "hidden", 
    }),
    indicatorSeparator: () => ({
        display: "none",
        width: 0,
    }),
    placeholder: (provided) => ({
        ...provided,
        color: "#4D6D64",
        fontWeight: "400",
    }),
    singleValue: (provided) => ({
        ...provided,
        color: "#4D6D64",
        fontWeight: "600",
    }),
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? "#FFFFFF" : "#4D6D64",
        backgroundColor: state.isSelected ? "#4D6D64" : provided.backgroundColor,
        "&:hover": {
            backgroundColor: "#C2E6D1",
            color: "#205A46",
        },
    }),
};

const FilterLogsModal = ({ open, onClose, onApply,setAuditData,auditData,getAllAuditData,setFiltersNo }) => {
  const animatedComponents = makeAnimated();
  const [filters, setFilters] = useState({
    module: "",
    operation:"",
    username: "",
    fromDate: "",
    toDate: "",
  });


  const resetFilters = () => {
    setFilters({
      module: "",
      operation:"",
      username: "",
      fromDate: "",
      toDate: "",
    });
    getAllAuditData();
    setFiltersNo(0);
  };

    const handleChange = (e) => {
        setFilters({ ...filters, [e.target.name]: e.target.value });
    };
    const handleFilterChange = (selectedOption, field) => {
        setFilters((prev) => ({
            ...prev,
            [field]: selectedOption?.value || "",
        }));
    };
    const handelClose = () => {
        getAllAuditData();
        resetFilters();
        onClose();
    }

  

    const moduleOptions = auditData.moduleFilterList?.map((mod) => ({ value: mod, label: mod })) || [];
    const operationOptions = auditData.operationFilterList?.map((mod) => ({ value: mod, label: mod })) || [];
    const usernameOptions = [
        ...new Set(auditData?.auditLogs?.map((log) => log?.username)),
    ]
        .filter((username) => username)
        .map((username) => ({ value: username, label: username }));


    useEffect(() => {
        getAllAuditData();
    }, []);

  const applyFilters = () => {
    const { module, username, fromDate, toDate,operation } = filters;
    const activeFilters = [module, username, fromDate, toDate,operation].filter(value => value).length;
    
    setFiltersNo(activeFilters);

        const filtered = auditData.auditLogs.filter((log) => {
            const matchesModule = module ? log.module === module : true;
            const matchesUsername = username ? log.username === username : true;
            const matchesDate =
                fromDate && toDate
                    ? new Date(log.createdAt) >= new Date(fromDate) && new Date(log.createdAt) <= new Date(toDate)
                    : true;
            const matchesOperation = operation ? log.operation === operation : true;
            return matchesModule && matchesUsername && matchesDate&&matchesOperation;

        });
        setAuditData((prevData) => ({
            ...prevData,
            auditLogs: filtered,
        }));
        onApply(filtered);
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 600,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    borderRadius: 5,
                    border: "none",
                    outline: "none",
                }}
            >
                <div className="p-2">
                    <Box
                        display="flex"
                        justifyContent="space-between "
                        alignItems="center"
                    >
                        <Typography
                            variant="h6"
                            className="px-2 py-2 ml-1 font-sans font-size-20 custom-font-500"
                        >
                            {" "}
                            Filter Logs
                        </Typography>
                        <Button
                            onClick={resetFilters}
                            sx={{ color: "#349F6F" }}
                            className="custom-font-500 text-capitalize font-size-14 font-sans mr-1 "
                        >
                            Reset all
                        </Button>
                    </Box>
                </div>
                <hr className="mt-0 mb-0" />
                <div className="px-4 py-4">
                    <div className="mb-1">
                        <label
                            className=" font-sans custom-font-500"
                            style={{ color: "#33584E" }}
                        >
                            Module
                        </label>
                        <Select
                            isMulti={false}
                            placeholder="Select Module"
                            name="module"
                            value={
                                filters.module
                                    ? { value: filters.module, label: filters.module }
                                    : null
                            }
                            onChange={(selectedOption) => handleFilterChange(selectedOption, "module")}
                            options={moduleOptions}
                            className="basic-single w-100 mt-1 radius-10px "
                            classNamePrefix="select"
                            components={animatedComponents}
                            closeMenuOnSelect={true}
                            styles={customStyles}
                        />
                    </div>
                    <div className="mb-1">
                        <label
                            className=" font-sans custom-font-500"
                            style={{ color: "#33584E" }}
                        >
                            Operation
                        </label>
                        <Select
                            isMulti={false}
                            placeholder="Select Operation"
                            name="operation"
                            value={
                                filters.operation
                                    ? { value: filters.operation, label: filters.operation }
                                    : null
                            }
                            onChange={(selectedOption) => handleFilterChange(selectedOption, "operation")}
                            options={operationOptions}
                            className="basic-single w-100 mt-1 radius-10px "
                            classNamePrefix="select"
                            components={animatedComponents}
                            closeMenuOnSelect={true}
                            styles={customStyles}
                        />
                    </div>
                    <div className="mb-1">
                        <label
                            className=" font-sans custom-font-500"
                            style={{ color: "#33584E" }}
                        >
                            Username
                        </label>

                        <Select
                            isMulti={false}
                            placeholder="Select Username"
                            name="username"
                            value={
                                filters.username
                                    ? { value: filters.username, label: filters.username }
                                    : null
                            }
                            onChange={(selectedOption) => handleFilterChange(selectedOption, "username")}
                            options={usernameOptions}
                            className="basic-single w-100 mt-1 "
                            classNamePrefix="select"
                            components={animatedComponents}
                            closeMenuOnSelect={true}
                            styles={customStyles}
                        />
                    </div>
                    <div className="d-flex mt-1 mb-2">
                        <div className="w-50 mr-2">
                            <label
                                className=" font-sans custom-font-500"
                                style={{ color: "#33584E" }}
                            >
                                From Date
                            </label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div className="custom-date-picker">
                                    <DatePicker
                                        sx={{
                                            "& .MuiInputBase-root.MuiOutlinedInput-root": {
                                                border: "1px solid #B3C0BD",
                                                borderRadius: "11px",
                                                outline: "none",
                                                boxShadow: "none", 
                                                "&:hover": {
                                                    borderColor: "#B3C0BD", 
                                                },
                                                "&.Mui-focused": {
                                                    border: "1px solid #B3C0BD", 
                                                    outline: "none",
                                                    boxShadow: "none",
                                                },
                                                "& fieldset": {
                                                    border: "none", 
                                                },
                                            },
                                            "& .MuiInputBase-input": {
                                                "&:focus": {
                                                    outline: "none",
                                                    boxShadow: "none",
                                                },
                                            },
                                        }}
                                        value={filters.fromDate}
                                        onChange={(newValue) => handleChange({ target: { name: 'fromDate', value: newValue } })}
                                        slots={{
                                            openPickerIcon: () => <img src={CalendarIcon} alt="Calendar" width="24px" height="24px" />,
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                type="date"
                                                variant="outlined"
                                                placeholder="fromDate"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    disableUnderline: true,
                                                }}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    placeholder:"fromDate" ,
                                                }}
                                            />
                                        )}
                                    />
                                </div>


                            </LocalizationProvider>
                        </div>
                        <div className="w-50 ml-2">
                            <label
                                className=" font-sans custom-font-500"
                                style={{ color: "#33584E" }}
                            >
                                To Date
                            </label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div className="custom-date-picker">
                                    <DatePicker
                                        sx={{
                                            "& .MuiInputBase-root.MuiOutlinedInput-root": {
                                                border: "1px solid #B3C0BD", 
                                                borderRadius: "11px",
                                                outline: "none", 
                                                boxShadow: "none", 
                                                "&:hover": {
                                                    borderColor: "#B3C0BD", 
                                                },
                                                "&.Mui-focused": {
                                                    border: "1px solid #B3C0BD", 
                                                    outline: "none",
                                                    boxShadow: "none",
                                                },
                                                "& fieldset": {
                                                    border: "none", 
                                                },
                                            },
                                            "& .MuiInputBase-input": {
                                                "&:focus": {
                                                    outline: "none",
                                                    boxShadow: "none",
                                                },
                                            },
                                        }}

                                        value={filters.toDate}
                                        onChange={(newValue) => handleChange({ target: { name: 'toDate', value: newValue } })}
                                        slots={{
                                            openPickerIcon: () => <img src={CalendarIcon} alt="Calendar" width="24px" height="24px" />,
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                type="date"
                                                variant="outlined"
                                                placeholder="toDate" 
                                                InputProps={{
                                                    ...params.InputProps,
                                                    disableUnderline: true, 
                                                }}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    placeholder: "toDate",
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            </LocalizationProvider>

                        </div>
                    </div>
                </div>
                <hr className="mt-0 mb-0" />
                <div className="px-4 py-2 mb-2">
                    <Box display="flex" justifyContent="space-between">
                        <Button
                            onClick={handelClose}
                            sx={{
                                border: 1,
                                width: "100px",
                                height: "50px",
                                borderColor: "#A0DABC",
                            }}
                            className=" px-4 radius-10px font-size-14 custom-font-500 custom-common-theme-text-color mt-1 text-capitalize"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={applyFilters}
                            style={{ width: "200px", height: "50px" }}
                            className=" text-white text-capitalize radius-10px custom-font-500 custom-common-theme-bg-color mt-1 font-size-14 font-sans "
                        >
                            Apply Filter
                        </Button>
                    </Box>
                </div>
            </Box>
        </Modal>
    );
};

export default FilterLogsModal;
