import * as React from "react";
import Sidebar from "../../Workspace2/Sidebar";
// import FormGroup from "@mui/material/FormGroup";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
// import CardContent from "@mui/material/CardContent";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../Workspace2/sidebar.css";
import "./../..././../../../WorkSpace/SignUp2.css";
import { useHistory, useLocation, useParams } from "react-router";
import {
  CreateIr,
  CreatePresetCheck,
  GetAllCompanyData,
  // GetAllProject,
  GetCheckedIR,
  UpdateStepStatus,
  fetchCoa,
  getCheckData,
  getWorkspace,
} from "../ApiServices/apiHandler";
import { useEffect } from "react";
import { useState } from "react";
import "../../../WorkSpace/font.css";
// for accordian
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// end accordian
import moment from "moment";
import { getProjects } from "../../../Console/Component/services/apiHandler";
import WorkspaceHeader from "./WorkspaceHeader";
// import CircleIcon from '@mui/icons-material/Circle';
// import Checkbox from "@mui/material/Checkbox";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const ImportPresetV2 = (props) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const [allProject, setAllProject] = useState([]);
  const [selected, setSelected] = useState([]);
  // const [selectedAllPreset, setSelectedPreset] = useState([]);
  const [checkStatus, setCheckStatus] = useState(false);
  const [allCompany, setAllCompany] = useState([]);
  const [allCoaData, setAllCoa] = useState([]);
  const [workspace, setWorkspace] = useState("");
  const [singleWorkspace, setSingleWorkspace] = useState([]);

  const { id } = useParams();
  let history = useHistory();
  const nextStep = async () => {
    try {
      const data = {
        steps: "step5",
        has_published:"draft",
        status:singleWorkspace.status,
        approver_id:singleWorkspace.approver_id,
        cancel_reason:singleWorkspace.cancel_reason,
      };
      const res = await UpdateStepStatus(id, data);
      if (res.status === 200) {
        history.push(`/inviteteammember/${id}`);
      }
    } catch (error) {
      console.log("error", error.message);
      return 0;
    }
  };
  const skipNext = async () => {
    history.push(`/inviteteammember/${id}`);
  };

  useEffect(() => {
    FetchProject();
    getIr();
    companyData();
    allCoa();
    getWorkspaceDetails();
    getSingleWorkspace();
  }, []);

  const companyData = async () => {
    try {
      const res = await GetAllCompanyData();
      if (res.status === 200) {
        setAllCompany(res.data.getAllCompanyData);
      }
    } catch (error) {
      console.log(error.message, "error");
    }
  };
  const FetchProject = async () => {
    const res = await getProjects();
    if (res.status === 200) {
      setAllProject(res.data?.project);
    }
  };
  const getSingleWorkspace = async () => {
    const res = await getWorkspace(id);
    if (res.status === 200) {
      setSingleWorkspace(res.data?.getworkspace[0])
    }
  }

  const handleSelectClick = async () => {
    try {
      if (allProject?.length > 0) {
        const newSelecteds = allProject.map((n) => n._id);
        setSelected(newSelecteds);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSelectAllClick = async () => {
    try {
      if (allProject?.length > 0) {
        const newSelecteds = allProject.map((n) => n._id);
        setSelected(newSelecteds);
        // AllIr.push(Ir);
        let data = {
          w_id: id,
          all_Preset_checked: newSelecteds,
        };
        const res = await CreatePresetCheck(data);
        if (res.status === 200) {
          setCheckStatus(true);
          getIr();
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const getIr = async () => {
    try {
      const res = await GetCheckedIR(id);
      if (res.status === 200) {
        setSelected(res.data.getIR[0]?.all_preset_checked);
      }
    } catch (error) {}
  };

  const handleChangePreset = (field, e, id) => {
    const selectedIndex = selected?.indexOf(id);
    let Preset = [];
    if (selectedIndex === -1) {
      Preset = Preset.concat(selected, id);
    } else if (selectedIndex === 0) {
      Preset = Preset.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      Preset = Preset.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      Preset = Preset.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(Preset);
  };

  const allCoa = async () => {
      const res = await fetchCoa();
      if (res.status === 200) {
        setAllCoa(res.data.coa);
      }
  };

  const handleSelected = async () => {
    try {
      let data = {
        w_id: id,
        all_Preset_checked: selected,
      };
      const res = await CreatePresetCheck(data);
      if (res.status === 200) {
        setCheckStatus(true);
        getIr();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const CompanyName = (c_id) => {
    try {
      const nameCompany = allCompany.filter((adj) => adj._id === c_id);
      const result = nameCompany[0]?.company_name
        ? nameCompany[0]?.company_name
        : "unknown";
      return result;
    } catch (error) {
      console.log(error, "2222");
      return 0;
    }
  };

  const CoaName =(p_id)=>{
    
    const nameOfCoa = allCoaData.filter((adj) => adj.project_id === p_id);
      const result = nameOfCoa[0]?.name ? `(${nameOfCoa[0]?.name})` : "";
      return result;
  }

  const getWorkspaceDetails =async ()=>{
    const res =await getCheckData(id);
    if (res.status === 200) {
      setWorkspace(res.data.getIR[0])
    }
  }

  return (
    <>
      <div className="row mr-0 ml-0">
        <div className="col-3 bg-white">
          <Sidebar activePage="5" id={id} />
        </div>
        <div className="col-9 main-workspace-content">
          <WorkspaceHeader currentStep="5" title="Import presets" />

          {/* Sales Tab */}
          <div className="row mt-3">
            <div className="col-12 mt-2">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                  <div className="d-flex">
                    <p className="addcompconthead font-sans" style={{marginTop:"0px", marginLeft:"0px"}}>Presets created &nbsp; 
                        {selected?.length > 0 && checkStatus === true || workspace?.all_preset_checked?.length > 0 ? (
                          <Badge className="badge_css font-sans">Imported</Badge>
                          ) : ""}
                    </p>
                        <Button
                          className="datebtn font-sans"
                          style={{
                            border: "2px solid #03565A",
                            color: "#03565A",
                            textTransform: "none",
                            position: "absolute",
                            right: "50px",
                            padding:"0px"
                          }}
                          onClick={handleSelectClick}
                          disabled={user.responsibilities==="approver"||singleWorkspace.status==="waiting"||user.responsibilities === "view"}
                        >
                          Select all preset
                    </Button>
                  </div>
                  </Typography>
                  <hr />
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    style={{
                      overflowY: "scroll",
                      height: "15rem",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                    }}
                  >
                    {allProject?.length > 0 &&
                      allProject?.map((det) => {
                        const { _id } = det;
                        const selectedUser = selected?.indexOf(_id) !== -1;
                        return (
                            <>
                              <div className="d-flex ">
                                <input
                                  type="checkbox"
                                  checked={selectedUser}
                                  disabled={user.responsibilities==="approver"||singleWorkspace.status==="waiting"||user.responsibilities === "view"}
                                  onChange={(event) =>
                                    handleChangePreset(
                                      "check_company",
                                      event,
                                      _id
                                    )
                                  }
                                />
                                <span className="d-flex justify-content-between w-100 mt-2">
                                  <h6 className="font-sans">
                                    {det.project_name
                                      ? det.project_name
                                      : "unknown"} 
                                       {CoaName(det._id)}
                                  </h6>
                                  <strong className="font-sans" style={{ paddingRight: "5rem", textTransform:"capitalize",color:"#8B909A" }}>
                                    {CompanyName(det.company)} 
                                  </strong>
                                </span>
                              </div>
                              <div className="d-flex justify-content-between">
                                <div
                                  className="d-flex"
                                  style={{ marginLeft: "22px" }}
                                >
                                  <div
                                    style={{
                                      color: "gray",
                                      marginLeft: "15px",
                                    }}
                                  >
                                    <CalendarMonthIcon
                                      style={{ fontSize: "14px" }}
                                    />
                                    <span style={{ fontSize: "12px" }}>
                                      {moment(det.previous_year).format(
                                        "MMMM DD, YYYY"
                                      )}
                                    </span>
                                  </div>
                                  <div style={{ color: "gray" }}>
                                    <CalendarMonthIcon
                                      style={{ fontSize: "14px" }}
                                    />
                                    <span style={{ fontSize: "12px" }}>
                                      {moment(det.createdAt).format(
                                        "MMMM DD, YYYY"
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </>
                        );
                      })}
                  </Typography>
                  <hr />
                  <div className="d-flex">
                    <div>
                      <Button
                      className="font-sans"
                        style={{
                          marginLeft: "0px",
                          backgroundColor: "#03565A",
                          color: "white",
                          border: "2px solid #03565A",
                          textTransform: "none",
                        }}
                        onClick={handleSelected}
                        disabled={user.responsibilities==="approver"||singleWorkspace.status==="waiting"||user.responsibilities === "view"}
                      >
                        Import selected Preset
                      </Button>
                    </div>
                    <div className="ml-3">
                      <Button
                      className="font-sans"
                        style={{
                          border: "2px solid #03565A",
                          color: "#03565A",
                          textTransform: "none",
                        }}
                        onClick={handleSelectAllClick}
                        disabled={user.responsibilities==="approver"||singleWorkspace.status==="waiting"||user.responsibilities === "view"}
                      >
                        Import all Preset
                      </Button>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          
          <div className="row mt-5 mb-5">
            <div className="col-md-6">
              <div
                className="backicon"
                onClick={() => history.push(`/importregister/${id}`)}
                style={{ cursor: "pointer" }}
              >
                <ArrowBackIcon />
                <div className="font-sans">Back to the Previous step</div>
              </div>
            </div>
            <div className="col-md-6">
                  <div className="nextbtn-v2 text-right">
                    <Button
                      className="datebtn font-sans"
                      style={{
                        border: "2px solid #03565A",
                        color: "#03565A",
                        textTransform: "none",
                      }}
                      onClick={skipNext}
                    >
                      Skip for now
                    </Button>
                    &nbsp;
                    <Button
                      className="datebtn font-sans"
                      style={{
                        backgroundColor: "#03565A",
                        color: "white",
                        textTransform: "none",
                      }}
                      onClick={nextStep}
                    >
                      Next Step
                    </Button>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ImportPresetV2;
