import React from 'react';
import { useSelector } from 'react-redux';
import moment from "moment";
import CommonToolTip from '../../Common/CommonToolTip';
import ErrorIcon from "@mui/icons-material/Error";
import { formatIndianCurrency } from '../../../helper/ConvertValue'
import { DisclosureNBFCTableBody, DisclosureNBFCTableHead } from './DisclosureHelper';



export function NBFC_32p6({ data, subIndex, HandleChange, calculateTotal, dis_index_id, originalArray }) {
    const reduxProjectData = useSelector((initialState) => initialState.DisclosureReducer.projectData);
    const user = JSON.parse(localStorage.getItem('user'));

    const checkCondition = (element) => {
        return {
            cy: (element?.cy_within_1_year + element?.cy_after_1_year !== element?.total_cy) || false,
            py: (element?.py_within_1_year + element?.py_after_1_year !== element?.total_py) || false
        };
    };

    const calculateGrandTotal = data => data.reduce((totals, item) => {
        item.sub_disclosure.forEach(subItem => {
            totals.grandTotalWithin1Year += typeof subItem.cy_within_1_year === 'number' ? subItem.cy_within_1_year : 0;
            totals.grandTotalAfter1Year += typeof subItem.cy_after_1_year === 'number' ? subItem.cy_after_1_year : 0;
            totals.grandTotalTotalCy += typeof subItem.total_cy === 'number' ? subItem.total_cy : 0;
            totals.grandTotalWithin1YearPy += typeof subItem.py_within_1_year === 'number' ? subItem.py_within_1_year : 0;
            totals.grandTotalAfter1YearPy += typeof subItem.py_after_1_year === 'number' ? subItem.py_after_1_year : 0;
            totals.grandTotalTotalPy += typeof subItem.total_py === 'number' ? subItem.total_py : 0;
        });
        return totals;
    }, { grandTotalWithin1Year: 0, grandTotalAfter1Year: 0, grandTotalTotalCy: 0, grandTotalWithin1YearPy: 0, grandTotalAfter1YearPy: 0, grandTotalTotalPy: 0 });

    return (
        <>
            <thead>
                <tr>
                    <th style={{ width: "40%" }} className='text-left pl-3 border-top-0 border-bottom-0' rowSpan={2}>{`${dis_index_id === 1 ? "Assets" : "Liabilities"}`}</th>
                    <th className='w-auto border-top-0 border-bottom-0' colSpan={3} >{moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
                    <th className='w-auto border-top-0 border-bottom-0' colSpan={3}>{moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
                    <DisclosureNBFCTableHead isEmpty={false} />
                </tr>
                <tr>
                    <th className='w-auto border-top-0 border-bottom-0'>Within 1 year</th>
                    <th className='w-auto border-top-0 border-bottom-0'>After 1 year</th>
                    <th className='w-auto border-top-0 border-bottom-0'>Total</th>
                    <th className='w-auto border-top-0 border-bottom-0'>Within 1 year</th>
                    <th className='w-auto border-top-0 border-bottom-0'>After 1 year</th>
                    <th className='w-auto border-top-0 border-bottom-0'>Total</th>
                    <DisclosureNBFCTableHead isEmpty={true} />
                </tr>
            </thead>

            <tbody>
                {
                    data &&
                    data.length > 0 &&
                    data.map((subDis, subDisIndex) => (
                        <>
                            <tr>
                                <td className='border-top-0 border-bottom-0'>
                                    <input style={{ backgroundColor: "inherit" }}
                                        className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"}`}
                                        type={"text"}
                                        value={subDis?.header}
                                        name="header"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>
                                <td className='border-top-0 border-bottom-0'>
                                    <input style={{ backgroundColor: "inherit" }} className="w-100 border-0 pr-3 text-right"
                                        type={"text"}
                                        readOnly={typeof subDis?.cy_within_1_year !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                        value={formatIndianCurrency(subDis?.cy_within_1_year)}
                                        name="cy_within_1_year"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>
                                <td className='border-top-0 border-bottom-0'>
                                    <input style={{ backgroundColor: "inherit" }} className="w-100 border-0 pr-3 text-right"
                                        type={"text"}
                                        readOnly={typeof subDis?.cy_after_1_year !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                        value={formatIndianCurrency(subDis?.cy_after_1_year)}
                                        name="cy_after_1_year"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>
                                <td className='border-top-0 border-bottom-0'>
                                    <div className='d-flex'>
                                        <input style={{ backgroundColor: "inherit" }} className="w-100 border-0 pr-2 text-right"
                                            type={"text"}
                                            readOnly={typeof subDis?.total_cy !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                            value={formatIndianCurrency(subDis?.total_cy)}
                                            name="total_cy"
                                            onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                        />
                                        {checkCondition(subDis)?.cy &&
                                            <CommonToolTip
                                                variant="primary"
                                                title={"The value is not matching with financial statement value."}
                                                placement="top"
                                            >
                                                <ErrorIcon
                                                    style={{
                                                        color: "darkorange",
                                                        cursor: "pointer",
                                                    }}
                                                    className="financialInstrument-hoverIcon"
                                                />
                                            </CommonToolTip>
                                        }
                                    </div>
                                </td>
                                <td className='border-top-0 border-bottom-0'>
                                    <input style={{ backgroundColor: "inherit" }} className="w-100 border-0 pr-3 text-right"
                                        type={"text"}
                                        readOnly={typeof subDis?.py_within_1_year !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                        value={formatIndianCurrency(subDis?.py_within_1_year)}
                                        name="py_within_1_year"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>
                                <td className='border-top-0 border-bottom-0'>
                                    <input style={{ backgroundColor: "inherit" }} className="w-100 border-0 pr-3 text-right"
                                        type={"text"}
                                        readOnly={typeof subDis?.py_after_1_year !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                        value={(subDis?.py_after_1_year)}
                                        name="py_after_1_year"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>
                                <td className='border-top-0 border-bottom-0'>
                                    <div className='d-flex'>
                                        <input style={{ backgroundColor: "inherit" }} className="w-100 border-0 pr-3 text-right"
                                            type={"test"}
                                            readOnly={typeof subDis?.total_py !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                            value={formatIndianCurrency(subDis?.total_py)}
                                            name="total_py"
                                            onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                        />
                                        {checkCondition(subDis)?.py &&
                                            <CommonToolTip
                                                variant="primary"
                                                title={"The value is not matching with financial statement value."}
                                                placement="top"
                                            >
                                                <ErrorIcon
                                                    style={{
                                                        color: "darkorange",
                                                        cursor: "pointer",
                                                    }}
                                                    className="financialInstrument-hoverIcon"
                                                />
                                            </CommonToolTip>
                                        }
                                    </div>
                                </td>
                                <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} 
                                     isReference={subDis?.isReference}  keyValue={6} index={subIndex}  sub_index={subDisIndex} />
                            </tr>
                            {data.length - 1 === subDisIndex && (
                                <>
                                    <tr>
                                        <td className='text-left pl-3 font-weight-bold text-dark custom-bg-gray-200'>{subDis.sub_dis_index_id === 16 ? "Total Assets" : "Total Liabilities"}</td>
                                        <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "cy_within_1_year"))} </td>
                                        <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "cy_after_1_year"))} </td>
                                        <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'>{formatIndianCurrency(calculateTotal(data, "total_cy"))}</td>
                                        <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "py_within_1_year"))} </td>
                                        <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "py_after_1_year"))} </td>
                                        <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'>{formatIndianCurrency(calculateTotal(data, "total_py"))}</td>
                                        <DisclosureNBFCTableBody isEmpty={true} refData={[]} isReference={""} />
                                    </tr>
                                    {dis_index_id === 2 && (
                                        <tr>
                                            <td className='text-left pl-3 font-weight-bold text-dark custom-bg-gray-200'>Net</td>
                                            <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateGrandTotal(originalArray)?.grandTotalWithin1Year)} </td>
                                            <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateGrandTotal(originalArray)?.grandTotalAfter1Year)} </td>
                                            <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateGrandTotal(originalArray)?.grandTotalTotalCy)} </td>
                                            <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateGrandTotal(originalArray)?.grandTotalWithin1YearPy)} </td>
                                            <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateGrandTotal(originalArray)?.grandTotalAfter1YearPy)} </td>
                                            <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateGrandTotal(originalArray)?.grandTotalTotalPy)} </td>
                                            <DisclosureNBFCTableBody isEmpty={true} refData={[]} isReference={""}/>
                                        </tr>
                                    )}
                                </>
                            )
                            }
                        </>
                    ))
                }
            </tbody>
        </>
    );
}