import * as React from "react";
import Sidebar from "../../Workspace2/Sidebar";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Badge from "@mui/material/Badge";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// import Checkbox from "@mui/material/Checkbox";
// import button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../../Workspace2/sidebar.css";
// import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router";
import { GetCheckedIR, UpdateStepStatus, getWorkspace } from "../ApiServices/apiHandler";
import { useEffect } from "react";
import { useState } from "react";
import useFetchApi from "../../../services/CustomHook/useFetchApi";
import { Link, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import "..//../../WorkSpace/font.css";
import WorkspaceHeader from "./WorkspaceHeader";
import swal from "sweetalert";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import "./../..././../../../WorkSpace/SignUp2.css";
import CircleIcon from '@mui/icons-material/Circle';

import {
  CreateCompany,
  GetLoan,
  // getCheckData,
  GetAllCompany,
  GetLease,
  GetPayable,
  GetReceivable,
  getActivePlan
} from "../ApiServices/apiHandler";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const ImportRegistersV2 = (props) => {
  const { id } = useParams();
  let history = useHistory();
  const user = JSON.parse(localStorage.getItem('user'));
  const [company, setCompany] = useState([]);
  const [singleWorkspace, setSingleWorkspace] = useState([]);

  const [loans, setLoans] = useState([]);
  const [selectedLoans, setSelectedLoans] = useState([]);
  const [loanImported, setLoanImported] = useState(false)
  const [isOpenLoan, setIsOpenLoan] = useState(false);

  const [leases, setLeases] = useState([]);
  const [selectedLeases, setSelectedLeases] = useState([]);
  const [leasesImported, setLeasesImported] = useState(false);
  const [isOpenLeases, setIsOpenLeases] = useState(false);

  const [receivable, setReceivable] = useState([]);
  const [selectedReceivable, setSelectedReceivable] = useState([]);
  const [receivableImported, setReceivableImported] = useState(false);
  const [isOpenReceivable, setIsOpenReceivable] = useState(false);

  const [payable, setPayable] = useState([]);
  const [selectedPayable, setSelectedPayable] = useState([]);
  const [payableImported, setPayableImported] = useState(false);
  const [isOpenPayable, setIsOpenPayable] = useState(false);

  const [investments, setInvestments] = useState([]);
  const [selectedInvestments, setSelectedInvestments] = useState([]);
  const [investmentImported, setInvestmentImported] = useState(false);
  const [isOpenInvestment, setIsOpenInvestment] = useState(false);

  const [activePlanData, setActivePlanData] = useState([]);

  const nextPageData = async () => {
    try {
      const data = {
        steps: "step4",
        has_published: "draft",
        status: singleWorkspace.status,
        approver_id: singleWorkspace.approver_id,
        cancel_reason: singleWorkspace.cancel_reason,
      };
      const res = await UpdateStepStatus(id, data);
      if (res.status === 200) {
        history.push(`/importPreset/${id}`);
      }
    } catch (error) {
      console.log("error", error.message);
      return 0;
    }
  };

  const skipNext = async () => {
    history.push(`/importPreset/${id}`);
  };

  const { loading, response, error } = useFetchApi(
    `api/v1/company-data/all-IR`
  );
  useEffect(() => {
    if (response && Object.keys(response).length > 0) {
      setInvestments(response.data.fetchIR);
    }
    if (error) {
      swal("Error", error, "error");
    }
    getIr();
    companyData();
    getLoans();
    getLeases();
    getPayable();
    getReceivable();
    getSingleWorkspace();
  }, [response || error]);

  useEffect(() => {
    activePlan();
  }, [])

  const getSingleWorkspace = async () => {
    const res = await getWorkspace(id);
    if (res.status === 200) {
      setSingleWorkspace(res.data?.getworkspace[0])
    }
  }

  const handleOpenLoan = () => {
    setIsOpenLoan(!isOpenLoan);
  }
  const handleOpenLeases = () => {
    setIsOpenLeases(!isOpenLeases);
  }
  const handleOpenInvestment = () => {
    setIsOpenInvestment(!isOpenInvestment);
  }
  const handleOpenReceivable = () => {
    setIsOpenReceivable(!isOpenReceivable);
  }
  const handleOpenPayable = () => {
    setIsOpenPayable(!isOpenPayable);
  }

  const companyData = async () => {
    try {
      const res = await GetAllCompany();
      if (res.status === 200) {
        setCompany(res.data.getAllCompanyData)
        console.log(company, '=========>')
      }
    } catch (error) {
      console.log(error.message, "error");
    }
  };

  const companyName = (c_id) => {
    try {
      const nameCompany = company.filter((adj) => adj._id === c_id);
      const result = nameCompany[0]?.company_name
        ? nameCompany[0]?.company_name
        : "unknown";
      return result;
    } catch (error) {
      console.log(error, "2222");
      return 0;
    }
  };
  const updateCheckedIR = (key, checked_data) => {
    const data = {
      w_id: id,
      [key]: checked_data
    }
    CreateCompany(data).then(res => {
      getIr();
      return res
    }).catch(err => {
      swal("Error", err, "error");
      return err
    })
  }

  const getLoans = async () => {
    try {
      const res = await GetLoan();
      if (res.status === 200) {
        setLoans(res.data.loans);
      }
    } catch (error) {
      console.log(error.message, "error");
    }
  };

  const getPayable = async () => {
    try {
      const res = await GetPayable();
      if (res.status === 200) {
        setPayable(res.data.payables);
      }
    } catch (error) {
      console.log(error.message, "error");
    }
  };

  const getReceivable = async () => {
    try {
      const res = await GetReceivable();
      if (res.status === 200) {
        setReceivable(res.data.receivables);
      }
    } catch (error) {
      console.log(error.message, "error");
    }
  };

  const handleSelectLoans = (event, id) => {
    setSelectedLoans((prevItems) => {
      // If the value is checked, push it to the array; otherwise, remove it
      if (prevItems.includes(id)) {
        updateCheckedIR("all_checked_loans", selectedLoans.filter((item) => item !== id));
        return prevItems.filter((item) => item !== id);
      } else {
        setLoanImported(false)
        return [...prevItems, id];
      }
    })
  };

  const importAllLoans = async () => {
    const allLoansIds = loans.map((loan) => loan._id);
    setSelectedLoans(allLoansIds);
    await updateCheckedIR("all_checked_loans", allLoansIds);
  }

  const getLeases = async () => {
    try {
      const res = await GetLease();
      if (res.status === 200) {
        setLeases(res.data.projects);
      }
    } catch (error) {
      console.log(error.message, "error");
    }
  };

  const handleSelectLeases = (event, id) => {
    setSelectedLeases((prevItems) => {
      // If the value is checked, push it to the array; otherwise, remove it
      if (prevItems.includes(id)) {
        updateCheckedIR("all_checked_leases", selectedLeases.filter((item) => item !== id));
        return prevItems.filter((item) => item !== id);
      } else {
        setLeasesImported(false)
        return [...prevItems, id];
      }
    })
  };

  const importAllLeases = async () => {
    const allLeasesIds = leases.map((lease) => lease._id)
    setSelectedLeases(allLeasesIds)
    await updateCheckedIR("all_checked_leases", allLeasesIds);
  }

  const handleSelectInvestment = (event, id) => {
    setSelectedInvestments((prevItems) => {
      // If the value is checked, push it to the array; otherwise, remove it
      if (prevItems.includes(id)) {
        updateCheckedIR("all_checked_investment", selectedInvestments.filter((item) => item !== id));
        return prevItems.filter((item) => item !== id);
      } else {
        setInvestmentImported(false)
        return [...prevItems, id];
      }
    })
  };

  const importAllInvestment = async () => {
    const allInvestmentIds = investments.map((investment) => investment._id)
    setSelectedInvestments(allInvestmentIds)
    await updateCheckedIR("all_checked_investment", allInvestmentIds);
  }

  const handleSelectReceivable = (event, id) => {
    setSelectedReceivable((prevItems) => {
      // If the value is checked, push it to the array; otherwise, remove it
      if (prevItems.includes(id)) {
        updateCheckedIR("all_checked_receivable", selectedReceivable.filter((item) => item !== id));
        return prevItems.filter((item) => item !== id);
      } else {
        setReceivableImported(false)
        return [...prevItems, id];
      }
    })
  };

  const importAllReceivable = async () => {
    const allReceivableIds = receivable.map((receivable) => receivable._id);
    setSelectedReceivable(allReceivableIds);
    await updateCheckedIR("all_checked_receivable", allReceivableIds);
  }

  const handleSelectPayable = (event, id) => {
    setSelectedPayable((prevItems) => {
      // If the value is checked, push it to the array; otherwise, remove it
      if (prevItems.includes(id)) {
        updateCheckedIR("all_checked_payable", selectedPayable.filter((item) => item !== id));
        return prevItems.filter((item) => item !== id);
      } else {
        setPayableImported(false)
        return [...prevItems, id];
      }
    })
  };

  const importAllPayable = async () => {
    const allPayableIds = payable.map((payable) => payable._id);
    setSelectedPayable(allPayableIds);
    await updateCheckedIR("all_checked_payable", allPayableIds);
  }

  const getIr = async () => {
    const res = await GetCheckedIR(id);
    if (res.status === 200) {
      if (res.data.getIR.length > 0) {
        if (res.data.getIR[0].all_checked_loans.length > 0) {
          setLoanImported(true)
          setSelectedLoans(res.data.getIR[0].all_checked_loans)
        } else {
          setLoanImported(false)
        }

        if (res.data.getIR[0].all_checked_leases.length > 0) {
          setLeasesImported(true)
          setSelectedLeases(res.data.getIR[0].all_checked_leases)
        } else {
          setLeasesImported(false)
        }

        if (res.data.getIR[0].all_checked_investment.length > 0) {
          setInvestmentImported(true)
          setSelectedInvestments(res.data.getIR[0].all_checked_investment)
        } else {
          setInvestmentImported(false)
        }

        if (res.data.getIR[0]?.all_checked_receivable?.length > 0) {
          setReceivableImported(true)
          setSelectedReceivable(res.data.getIR[0]?.all_checked_receivable)
        } else {
          setReceivableImported(false)
        }

        if (res.data.getIR[0]?.all_checked_payable?.length > 0) {
          setPayableImported(true)
          setSelectedPayable(res.data.getIR[0]?.all_checked_payable)
        } else {
          setPayableImported(false)
        }
      }
    }
  };

  const activePlan = async () => {
    try {
      const res = await getActivePlan();
      console.log(res.data.categorizedBenefits.dataset, "res--------->");
      setActivePlanData(res.data.categorizedBenefits.dataset);

    } catch (error) {
      console.log(error.message)
    }
  }


  return (
    <>
      <div className="row mr-0 ml-0">
        <div className="col-3 bg-white">
          <Sidebar activePage="4" id={id} />
        </div>
        <div className="col-9 main-workspace-content">
          <WorkspaceHeader currentStep="4" title="Import registers" />

          {/* Sales Tab */}
          {activePlanData.includes('Sales') &&
            <div className="row mt-3">
              <div className="col-12 mt-2">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <CircleIcon
                        style={{
                          color: "grey",
                        }}
                      />
                      <p className="addcompconthead font-sans">Sales </p>
                    </Typography>
                    <hr />
                  </AccordionSummary>
                </Accordion>
              </div>
            </div>
          }
          {/* Purchase Tab */}
          {activePlanData.includes('Purchases') &&
            <div className="row mt-3">
              <div className="col-12 mt-2">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <CircleIcon
                        style={{
                          color: "grey",
                        }}
                      />
                      <p className="dm-sans addcompconthead font-sans">Purchase </p>
                    </Typography>
                    <hr />
                  </AccordionSummary>
                </Accordion>
              </div>
            </div>
          }
          {/* Leases Tab */}
          {activePlanData.includes('Leases') &&
            <div className="row mt-3">
              <div className="col-12 mt-2">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onClick={() => handleOpenLeases()}
                  >
                    <Typography>
                      {isOpenLeases ? (
                        <div className="open-radio-outer">
                          <div className="open-radio-inner"></div>
                        </div>
                      ) : leasesImported ? (
                        <CheckCircleIcon
                          style={{
                            color: "#03565A",
                          }}
                        />
                      ) : (
                        <RadioButtonUncheckedIcon
                          style={{
                            color: "#03565A",
                          }}
                        />
                      )}
                      <p className="dm-sans addcompconthead font-sans">Leases
                        {/* ({leases.length}) */}
                        &nbsp;
                        {leasesImported ? (
                          <Badge className="badge_css font-sans">Imported</Badge>
                        ) : ''}
                      </p>
                    </Typography>
                    <hr />
                  </AccordionSummary>
                  <AccordionDetails>

                    <Typography
                      style={{
                        overflowY: "scroll",
                        height: "15rem",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                      }}
                    >
                      <div>
                        {leases.length > 0 &&
                          leases.map((lease) => {
                            const { _id } = lease;
                            return (
                              <div className="d-flex" key={_id}>
                                <input
                                  type="checkbox"
                                  checked={selectedLeases.includes(lease._id)}
                                  disabled={user.responsibilities === "approver" || singleWorkspace.status === "waiting"||user.responsibilities === "view"}
                                  id={lease._id}
                                  onChange={(event) =>
                                    handleSelectLeases(
                                      event,
                                      lease._id
                                    )
                                  }
                                />
                                <span className="w-100 d-flex justify-content-between mt-2 pr-4">
                                  <strong className="font-sans">{lease?.name}</strong>
                                  <strong className="font-sans text-capitalize" style={{ color: "#8B909A" }}>{lease?.company}</strong>
                                </span>
                              </div>
                            );
                          })}
                      </div>
                    </Typography>
                    <hr />
                    <div className="d-flex">
                      <div>
                        <Button
                          className="font-sans"
                          style={{
                            marginLeft: "0px",
                            backgroundColor: "#03565A",
                            color: "white",
                            textTransform: "none",
                            border: "2px solid #03565A",
                          }}
                          onClick={() => updateCheckedIR("all_checked_leases", selectedLeases)}
                          disabled={user.responsibilities === "approver" || singleWorkspace.status === "waiting"||user.responsibilities === "view"}
                        >
                          Import selected Leases
                        </Button>
                      </div>
                      <div className="ml-3">
                        <Button
                          className="font-sans"
                          style={{
                            border: "2px solid #03565A",
                            color: "#03565A",
                            textTransform: "none",
                          }}
                          onClick={importAllLeases}
                          disabled={user.responsibilities === "approver" || singleWorkspace.status === "waiting"||user.responsibilities === "view"}
                        >
                          Import all Leases
                        </Button>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          }


          {/* Investment Tab */}
          {activePlanData.includes('Investment') &&
            <div className="row mt-3">
              <div className="col-12 mt-2">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onClick={() => handleOpenInvestment()}
                  >
                    <Typography>
                      {isOpenInvestment ? (
                        <div className="open-radio-outer">
                          <div className="open-radio-inner"></div>
                        </div>
                      ) : investmentImported ? (
                        <CheckCircleIcon
                          style={{
                            color: "#03565A",
                          }}
                        />
                      ) : (
                        <RadioButtonUncheckedIcon
                          style={{
                            color: "#03565A",
                          }}
                        />
                      )}
                      <p className="dm-sans addcompconthead font-sans">Investment
                        {/* ({leases.length}) */}
                        &nbsp;
                        {investmentImported ? (
                          <Badge className="badge_css font-sans">Imported</Badge>
                        ) : ''}
                      </p>
                    </Typography>
                    <hr />
                  </AccordionSummary>
                  <AccordionDetails>

                    <Typography
                      style={{
                        overflowY: "scroll",
                        height: "15rem",
                        paddingLeft: "30px",
                        paddingRight: "30px"
                      }}
                    >
                      {investments.length > 0 &&
                        investments.map((investment, i) => {
                          return (
                            <>
                              {investment.ir_name &&
                                <div className="d-flex">

                                  <input
                                    type="checkbox"
                                    checked={selectedInvestments.includes(investment._id)}
                                    disabled={user.responsibilities === "approver" || singleWorkspace.status === "waiting"||user.responsibilities === "view"}
                                    id={investment._id}
                                    onChange={(event) =>
                                      handleSelectInvestment(
                                        event,
                                        investment._id
                                      )
                                    }
                                  />
                                  <span className="w-100 d-flex justify-content-between mt-2 pr-4">
                                    <strong>{investment.ir_name}</strong>
                                    <strong style={{ color: "#8B909A" }}>{companyName(investment.parent_company_id)}</strong>
                                  </span>
                                </div>
                              }
                            </>
                          );
                        })}
                    </Typography>
                    <hr />
                    <div className="d-flex">
                      <div>
                        <Button
                          className="font-sans"
                          style={{
                            marginLeft: "0px",
                            backgroundColor: "#03565A",
                            color: "white",
                            textTransform: "none",
                            border: "2px solid #03565A",
                          }}
                          onClick={() => updateCheckedIR("all_checked_investment", selectedInvestments)}
                          disabled={user.responsibilities === "approver" || singleWorkspace.status === "waiting"||user.responsibilities === "view"}
                        >
                          Import selected investment
                        </Button>
                      </div>
                      <div className="ml-3">
                        <Button
                          className="font-sans"
                          style={{
                            border: "2px solid #03565A",
                            color: "#03565A",
                            textTransform: "none",
                          }}
                          onClick={importAllInvestment}
                          disabled={user.responsibilities === "approver" || singleWorkspace.status === "waiting"||user.responsibilities === "view"}
                        >
                          Import all investment
                        </Button>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          }
          {/* Fixed Assets Tab*/}

          {activePlanData.includes('Fixed Assets') &&
            <div className="row mt-3">
              <div className="col-12 mt-2">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <CircleIcon
                        style={{
                          color: "grey",
                        }}
                      />
                      <p className="dm-sans addcompconthead font-sans">Fixed Assets </p>
                    </Typography>
                  </AccordionSummary>
                </Accordion>
              </div>
            </div>
          }
          {/* Receivables Tab*/}
          {activePlanData.includes('Receivables') &&
            <div className="row mt-3">
              <div className="col-12 mt-2">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onClick={() => handleOpenReceivable()}
                  >
                    <Typography>
                      {isOpenReceivable ? (
                        <div className="open-radio-outer">
                          <div className="open-radio-inner"></div>
                        </div>
                      ) : receivableImported ? (
                        <CheckCircleIcon
                          style={{
                            color: "#03565A",
                          }}
                        />
                      ) : (
                        <RadioButtonUncheckedIcon
                          style={{
                            color: "#03565A",
                          }}
                        />
                      )}
                      <p className="dm-sans addcompconthead font-sans">Receivable
                        {/* ({receivable.length}) */}
                        &nbsp;
                        {receivableImported ? (
                          <Badge className="badge_css font-sans">Imported</Badge>
                        ) : ''}
                      </p>
                    </Typography>
                    <hr />
                  </AccordionSummary>
                  <AccordionDetails>

                    <Typography
                      style={{
                        overflowY: "scroll",
                        height: "15rem",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                      }}
                    >
                      <div>
                        {receivable.length > 0 &&
                          receivable.map((receivable) => {
                            const { _id } = receivable;
                            return (
                              <div className="d-flex" key={_id}>
                                <input
                                  type="checkbox"
                                  checked={selectedReceivable.includes(receivable._id)}
                                  disabled={user.responsibilities === "approver" || singleWorkspace.status === "waiting"||user.responsibilities === "view"}
                                  id={receivable._id}
                                  onChange={(event) =>
                                    handleSelectReceivable(
                                      event,
                                      receivable?._id
                                    )
                                  }
                                />
                                <span className="w-100 d-flex justify-content-between mt-2 pr-4">
                                  <strong className="font-sans">{receivable?.name}</strong>
                                  <strong className="font-sans" style={{ color: "#8B909A" }}>{receivable?.company}</strong>
                                </span>
                              </div>
                            );
                          })}
                      </div>
                    </Typography>
                    <hr />
                    <div className="d-flex">
                      <div>
                        <Button
                          className="font-sans"
                          style={{
                            marginLeft: "0px",
                            backgroundColor: "#03565A",
                            color: "white",
                            textTransform: "none",
                            border: "2px solid #03565A",
                          }}
                          onClick={() => updateCheckedIR("all_checked_receivable", selectedReceivable)}
                          disabled={user.responsibilities === "approver" || singleWorkspace.status === "waiting"||user.responsibilities === "view"}
                        >
                          Import selected Receivables
                        </Button>
                      </div>
                      <div className="ml-3">
                        <Button
                          className="font-sans"
                          style={{
                            border: "2px solid #03565A",
                            color: "#03565A",
                            textTransform: "none",
                          }}
                          onClick={importAllReceivable}
                          disabled={user.responsibilities === "approver" || singleWorkspace.status === "waiting"||user.responsibilities === "view"}
                        >
                          Import all Receivables
                        </Button>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          }
          {/* Payables Tab */}      
          {activePlanData.includes('Payables') &&
            <div className="row mt-3">
              <div className="col-12 mt-2">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onClick={() => handleOpenPayable()}
                  >
                    <Typography>
                      {isOpenPayable ? (
                        <div className="open-radio-outer">
                          <div className="open-radio-inner"></div>
                        </div>
                      ) : payableImported ? (
                        <CheckCircleIcon
                          style={{
                            color: "#03565A",
                          }}
                        />
                      ) : (
                        <RadioButtonUncheckedIcon
                          style={{
                            color: "#03565A",
                          }}
                        />
                      )}
                      <p className="dm-sans addcompconthead font-sans">Payable
                        {/* ({payable.length}) */}
                        &nbsp;
                        {payableImported ? (
                          <Badge className="badge_css font-sans">Imported</Badge>
                        ) : ''}
                      </p>
                    </Typography>
                    <hr />
                  </AccordionSummary>
                  <AccordionDetails>

                    <Typography
                      style={{
                        overflowY: "scroll",
                        height: "15rem",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                      }}
                    >
                      <div>
                        {payable.length > 0 &&
                          payable.map((payable) => {
                            const { _id } = payable;
                            return (
                              <div className="d-flex" key={_id}>
                                <input
                                  type="checkbox"
                                  checked={selectedPayable.includes(payable?._id)}
                                  disabled={user.responsibilities === "approver" || singleWorkspace.status === "waiting"||user.responsibilities === "view"}
                                  id={payable?._id}
                                  onChange={(event) =>
                                    handleSelectPayable(
                                      event,
                                      payable?._id
                                    )
                                  }
                                />
                                <span className="w-100 d-flex justify-content-between mt-2 pr-4">
                                  <strong className="font-sans">{payable?.name}</strong>
                                  <strong className="font-sans" style={{ color: "#8B909A" }}>{payable?.company}</strong>
                                </span>
                              </div>
                            );
                          })}
                      </div>
                    </Typography>
                    <hr />
                    <div className="d-flex">
                      <div>
                        <Button
                          className="font-sans"
                          style={{
                            marginLeft: "0px",
                            backgroundColor: "#03565A",
                            color: "white",
                            textTransform: "none",
                            border: "2px solid #03565A",
                          }}
                          onClick={() => updateCheckedIR("all_checked_payable", selectedPayable)}
                          disabled={user.responsibilities === "approver" || singleWorkspace.status === "waiting"||user.responsibilities === "view"}
                        >
                          Import selected Payables
                        </Button>
                      </div>
                      <div className="ml-3">
                        <Button
                          className="font-sans"
                          style={{
                            border: "2px solid #03565A",
                            color: "#03565A",
                            textTransform: "none",
                          }}
                          onClick={importAllPayable}
                          disabled={user.responsibilities === "approver" || singleWorkspace.status === "waiting"||user.responsibilities === "view"}
                        >
                          Import all Payables
                        </Button>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          }
          {/* Presets Tab*/}
          {activePlanData.includes('Presets') &&
            <div className="row mt-3">
              <div className="col-12 mt-2">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <CircleIcon
                        style={{
                          color: "grey",
                        }}
                      />
                      <p className="dm-sans addcompconthead font-sans">Presets </p>
                    </Typography>
                    <hr />
                  </AccordionSummary>
                </Accordion>
              </div>
            </div>
          }
          {/* Chart of Accounts Tab*/}
          {activePlanData.includes('Chart of Accounts') &&
            <div className="row mt-3">
              <div className="col-12 mt-2">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <CircleIcon
                        style={{
                          color: "grey",
                        }}
                      />
                      <p className="dm-sans addcompconthead font-sans">Chart of Accounts </p>
                    </Typography>
                    <hr />
                  </AccordionSummary>
                </Accordion>
              </div>
            </div>
          }
          {/* Loans Tab             */}
          {activePlanData.includes('Loans') &&
            <div className="row mt-3">
              <div className="col-12 mt-2">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onClick={() => handleOpenLoan()}
                  >
                    <Typography>
                      {isOpenLoan ? (
                        <div className="open-radio-outer">
                          <div className="open-radio-inner"></div>
                        </div>
                      ) : loanImported ? (
                        <CheckCircleIcon
                          style={{
                            color: "#03565A",
                          }}
                        />
                      ) : (
                        <RadioButtonUncheckedIcon
                          style={{
                            color: "#03565A",
                          }}
                        />
                      )}
                      <p className="dm-sans addcompconthead font-sans">Loans
                        {/* ({loans.length}) */}
                        &nbsp;
                        {loanImported ? (
                          <Badge className="badge_css font-sans">Imported</Badge>
                        ) : ''}
                      </p>
                    </Typography>
                    <hr />
                  </AccordionSummary>
                  <AccordionDetails>

                    <Typography
                      style={{
                        overflowY: "scroll",
                        height: "15rem",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                      }}
                    >
                      <div>
                        {loans.length > 0 &&
                          loans.map((loan) => {
                            const { _id } = loan;
                            return (
                              <div className="d-flex" key={_id}>
                                <input
                                  type="checkbox"
                                  checked={selectedLoans.includes(loan._id)}
                                  disabled={user.responsibilities === "approver" || singleWorkspace.status === "waiting"||user.responsibilities === "view"}
                                  id={loan._id}
                                  onChange={(event) =>
                                    handleSelectLoans(
                                      event,
                                      loan._id
                                    )
                                  }
                                />
                                <span className="w-100 d-flex justify-content-between mt-2 pr-4">
                                  <strong className="font-sans">{loan?.name}</strong>
                                  <strong className="font-sans" style={{ color: "#8B909A" }}>{loan?.company}</strong>
                                </span>
                              </div>
                            );
                          })}
                      </div>
                    </Typography>
                    <hr />
                    <div className="d-flex">
                      <div>
                        <Button
                          className="font-sans"
                          style={{
                            marginLeft: "0px",
                            backgroundColor: "#03565A",
                            color: "white",
                            textTransform: "none",
                            border: "2px solid #03565A",
                          }}
                          onClick={() => updateCheckedIR("all_checked_loans", selectedLoans)}
                          disabled={user.responsibilities === "approver" || singleWorkspace.status === "waiting"||user.responsibilities === "view"}
                        >
                          Import selected Loans
                        </Button>
                      </div>
                      <div className="ml-3">
                        <Button
                          className="font-sans"
                          style={{
                            border: "2px solid #03565A",
                            color: "#03565A",
                            textTransform: "none",
                          }}
                          onClick={importAllLoans}
                          disabled={user.responsibilities === "approver" || singleWorkspace.status === "waiting"||user.responsibilities === "view"}
                        >
                          Import all Loans
                        </Button>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          }


          {/* Book closure Tab*/}
          {activePlanData.includes('Book Closure') &&
            <div className="row mt-3">
              <div className="col-12 mt-2">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <CircleIcon
                        style={{
                          color: "grey",
                        }}
                      />
                      <p className="dm-sans addcompconthead font-sans">Book closure </p>
                    </Typography>
                    <hr />
                  </AccordionSummary>
                </Accordion>
              </div>
            </div>
          }




          <div className="row mt-5 mb-5">
            <div className="col-md-6">
              <div
                className="backicon"
                onClick={() => history.push(`/addCompanies/${id}`)}
                style={{ cursor: "pointer" }}
              >
                <ArrowBackIcon />
                <div className="font-sans">Back to the Previous step</div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="nextbtn-v2 text-right">
                <Button
                  className="datebtn font-sans"
                  style={{
                    border: "2px solid #03565A",
                    color: "#03565A",
                    textTransform: "none",
                  }}
                  onClick={skipNext}
                >
                  Skip for now
                </Button>
                &nbsp;
                <Button
                  className="datebtn font-sans"
                  style={{
                    backgroundColor: "#03565A",
                    color: "white",
                    textTransform: "none",
                  }}
                  onClick={nextPageData}
                >
                  Next Step
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImportRegistersV2;
