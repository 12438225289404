import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Submit } from "../Button";
import axios from "axios";
import { useSelector } from "react-redux";
import { socket } from "../../services/socket/socket";
import swal from "sweetalert";

const screen = {
  width: "100%",
  height: "100%",
  position: "fixed",
  zIndex: "999",
  backgroundColor: "#00000036",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
};
const container = {
  // width:'30%',
  minHeight: "20vh",
  borderRadius: "10px",
  // maxHeight:'90vh',
  padding: "2rem 4rem",

  zIndex: "999",
  backgroundColor: "#f5f7fa",
};

const head = {
  display: "flex",
};

const header = {
  fontSize: "2rem",
  fontWeight: "600",
};
const para = {
  fontSize: "14px",
  fontWeight: "400",
  marginTop: "0px",
  color: "#696F79",
};

const input = {
  width: "20vw",
  height: "3rem",
  borderRadius: "6px",
  outline: "none",
  border: "1px solid #D0D0D0",
  padding: "10px",
  fontSize: "20px",
  margin: "15px",
  color: "var(--clr-font-dark)",
};
const footSelect = {
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  marginTop: "1.2rem",
};

const presetHeader = {
  fontSize: "14px",
  color: "var(--clr-font-mid)",
  margin: "0 15px 5px 0px",
  // width:'1px'
};

const grid = {
  display: "grid",
  gridTemplateColumns: "48% 48% ",
  gridColumnGap: "5%",
  // gridRowGap: '10px'
};

const AddGrouping = (props) => {

  const { note_grp, fs_grp, notes_no } = props.newSg;


  // const dispatch = useDispatch();
  const init = {
    ledger_name: "",
    sub_grp: "",
  };
  const [field, setField] = useState(init);
  const [disableBtn, setDisableBtn] = useState(true);

  const R_tb_id = useSelector((initialState) => initialState.reducer.tb_id);
  const LOCAL_tb_id = localStorage.getItem("tb_id");
  let tb_id = R_tb_id ? R_tb_id : LOCAL_tb_id;
  const project_id = localStorage.getItem("project_id");

  function handleClose(e) {
    if (e.target.classList.contains("screenDiv")) {
      props.setAddGrouping(false);
    }
  }

  function handleChange(inp, e) {
    setField({ ...field, [inp]: e.target.value });
  }

  useEffect(() => {
    if (field.sub_grp.length > 1) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [field]);

  function handleSubmit() {
    if (field.sub_grp.length < 1) {
      return swal("", "please enter a valid sub grouping name", "warning");
    }
    const auth = localStorage.getItem("auth_token");
    let headers = {
      "x-auth-token": auth,
    };
    setDisableBtn(true);
    const parsedNoteGrp = (typeof note_grp === "string" && note_grp.startsWith("{") && note_grp.endsWith("}")) ? JSON.parse(note_grp) : note_grp;
    const fd = new FormData();
    fd.append("notes_grp", parsedNoteGrp?.notes_grp || note_grp);
    fd.append("sg", field.sub_grp);
    fd.append("fs_grp", props.newSg.fs_grp);
    fd.append("notes_no", notes_no || parsedNoteGrp?.notes_no);
    axios
      .post(`/api/v1/grouping/sg/${project_id}`, fd, { headers })
      .then(() => {
        swal("New Sub Group Added Successfully!", {
          icon: "success",
        });
        socket.emit("request-mapped-grouping", { project_id, tb_id });
        setDisableBtn(false);
        props.setAddGrouping(false);
        // props.SetMultiOptions(field);
      })
      .catch((err) => {
        setDisableBtn(false);
        swal(`${err?.response?.data?.error || err.message}`, {
          icon: 'error'
        })
      });
  }

  return (
    <motion.div
      style={screen}
      className="screenDiv"
      onClick={handleClose}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <motion.div
        style={container}
        className="col-md-5 col-sm-8"
        initial={{ y: "-5vh" }}
        animate={{ y: 0 }}
      >
        <div style={head}>
          <div>
            <h2 style={header}>Create Sub Grouping</h2>
            {/* <p style={para}>Ledger Codes, Ledger Names, Curent and year amount, Groupings and Trial balance Adjustments are saved to use next time</p> */}
          </div>
          <div style={{ paddingTop: "13px", marginLeft: "auto" }}>
            <h5
              style={{
                color: "var(--clr-accent)",
                fontSize: "1rem",
                fontWeight: "700",
                cursor: "pointer",
              }}
              onClick={() => {
                props.setAddGrouping(false);
              }}
            >
              close
            </h5>
          </div>
        </div>
        <div style={grid}>
          <p style={presetHeader}>
            Add new Sub Group to {note_grp}/{notes_no} of {fs_grp}<span style={{ color: "red" }}>*</span>
          </p>
        </div>
        <div style={grid}>
          <div
            style={{ padding: "0 0", margin: "10px 0 ", display: "flex" }}
            className="presetName"
          >
            <input
              style={input}
              value={field.sub_grp}
              type="text"
              onChange={(e) => {
                handleChange("sub_grp", e);
              }}
            />
          </div>
        </div>
        <div style={footSelect}>
          <div
            onClick={() => {
              handleSubmit();
            }}
          >
            <Submit value="Create New Input" sm disable={disableBtn} />
          </div>
        </div>

        <div></div>
      </motion.div>
    </motion.div>
  );
};

export default AddGrouping;
