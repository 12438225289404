import React from 'react';
import { useSelector } from 'react-redux';
import moment from "moment";
import CommonToolTip from '../../Common/CommonToolTip';
import ErrorIcon from "@mui/icons-material/Error";
import { formatIndianCurrency } from '../../../helper/ConvertValue'
import { DisclosureNBFCTableBody, DisclosureNBFCTableHead } from './DisclosureHelper';


export function NBFC_8p1({data, subIndex, HandleChange, dis_index_id}) {
    const reduxProjectData = useSelector((initialState) => initialState.DisclosureReducer.projectData);
    const user = JSON.parse(localStorage.getItem('user'));
    
    const calculateTotal = (key) => {
        const copy = [...data];
        return copy.reduce((total, item) => total + item[key], 0);
    };

    const checkCondition = (element) => {
        return (element?.cy + element?.represented_cash_flow + element?.others) !== element?.py;
    } 

    return (
        <>
            <thead>
            <tr>
                <th className='text-left w-50 pl-3 font-sans border-top-0 border-bottom-0' >Particulars</th>
                <th className='w-auto font-sans border-top-0 border-bottom-0' >As at  {" "}
                    {
                        dis_index_id === 2 ? moment(reduxProjectData.previous_year).subtract(1, 'year').format("DD MMMM YYYY") : 
                        moment(reduxProjectData.previous_year).format("DD MMMM YYYY")
                    } 
                </th>
                <th className='w-auto font-sans border-top-0 border-bottom-0' >As represented in Cash Flow Statement </th>
                <th className='w-auto font-sans border-top-0 border-bottom-0' >Others # </th>
                <th className='w-auto font-sans border-top-0 border-bottom-0' >As at {" "}
                    {
                        dis_index_id === 2 ? moment(reduxProjectData.previous_year).format("DD MMMM YYYY") : 
                        moment(reduxProjectData.current_year).format("DD MMMM YYYY")
                    }
                </th>
                <DisclosureNBFCTableHead isEmpty={false} />
                <th className='w-auto border-top-0 border-bottom-0' style={{minWidth:"50px"}}></th>
            </tr>
            </thead>

            <tbody>
                {
                    data &&
                    data.length > 0 &&
                    data.map((subDis, subDisIndex) => (
                        <>
                            <tr>
                                <td className="border-top-0 border-bottom-0" >
                                    <textarea         
                                        style={{backgroundColor:"inherit", wordBreak:"break-all",fontSize:"16px",height:"24px"}}
                                        className="w-100 border-0 pl-3 text-left font-sans"
                                        type={"text"}
                                        value={subDis?.header}
                                        name="header"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>
                                <td className="border-top-0 border-bottom-0" style={{minWidth:"100px"}} >
                                    <input
                                        style={{backgroundColor:"inherit"}}
                                        className='p-0 m-0 w-100 border-0 pr-3 text-right'
                                        type={"text"}
                                        readOnly={typeof subDis?.cy !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                        value={formatIndianCurrency(subDis?.cy)}
                                        name="cy"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>
                                <td className="border-top-0 border-bottom-0"style={{minWidth:"100px"}} >
                                    <input
                                        style={{backgroundColor:"inherit"}}
                                        className='p-0 m-0 w-100 border-0 pr-3 text-right'
                                        type={"text"}
                                        readOnly={typeof subDis?.represented_cash_flow !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                        value={formatIndianCurrency(subDis?.represented_cash_flow)}
                                        name="represented_cash_flow"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>
                                <td className="border-top-0 border-bottom-0" style={{minWidth:"100px"}} >
                                    <input 
                                        style={{backgroundColor:"inherit"}}
                                        className='p-0 m-0 w-100 border-0 pr-3 text-right'
                                        type={"text"}
                                        readOnly={typeof subDis?.others !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                        value={formatIndianCurrency(subDis?.others)}
                                        name="others"
                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                    />
                                </td>
                                <td  className="border-top-0 border-bottom-0" style={{minWidth:"100px"}}>
                                    <div className="d-flex">
                                        <input 
                                            style={{backgroundColor:"inherit"}}
                                            className='p-0 m-0 w-100 border-0 pr-3 text-right'
                                            type={"text"}
                                            readOnly={typeof subDis?.py !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                            value={formatIndianCurrency(subDis?.py)}
                                            name="py"
                                            onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                        />
                                        {
                                            subDis?.py !== subDis.from_financial_statement && 
                                            <CommonToolTip
                                                variant="primary"
                                                title={"The value is not matching with financial statement value." }
                                                placement="top"
                                            >
                                                <ErrorIcon
                                                style={{
                                                    color: "darkorange",
                                                    cursor: "pointer",
                                                }}
                                                className="financialInstrument-hoverIcon"
                                            />
                                            </CommonToolTip> 
                                        }
                                    </div>
                                </td>
                                <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} isReference={subDis?.isReference} keyValue={0} index={subIndex}  sub_index={subDisIndex} />
                                <td className="border-top-0 border-bottom-0 "  style={{minWidth:"100px"}}>
                                    {
                                        checkCondition(subDis) && 
                                        <CommonToolTip
                                            variant="primary"
                                            title={"The value is not matching with financial statement value."}
                                            placement="top"
                                        >
                                            <ErrorIcon
                                                style={{
                                                    color: "darkorange",
                                                    cursor: "pointer",
                                                }}
                                                className="financialInstrument-hoverIcon"
                                            />
                                        </CommonToolTip> 
                                    }
                                </td>
                            </tr>
                        </>
                    ))
                }
                <tr>
                    <td className='custom-bg-gray-200'>  </td>
                    <td className='pr-3 text-right custom-font-600 custom-bg-gray-200' > {formatIndianCurrency(calculateTotal('cy'))} </td>
                    <td className='pr-3  text-right custom-font-600 custom-bg-gray-200' > {formatIndianCurrency(calculateTotal("represented_cash_flow"))} </td>
                    <td className='pr-3 text-right custom-font-600 custom-bg-gray-200' > {formatIndianCurrency(calculateTotal("others"))} </td>
                    <td className='pr-3 text-right custom-font-600 custom-bg-gray-200' > {formatIndianCurrency(calculateTotal("py"))} </td>
                    <DisclosureNBFCTableBody isEmpty={true} refData={[]} isReference="" />
                    <td className='custom-bg-gray-200'></td> 
                </tr>
                
            </tbody>
            
        </>
    );
}