import React, { useState, useEffect } from "react";
// import { Tabs, Tab, Accordion } from "react-bootstrap";
import PropTypes from 'prop-types';
import { Tabs, Tab, Typography, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import "./financialStatement.css";
import { Navbar } from "../../Components/Navbar";
import BalanceSheet from "../../Components/BalanceSheet/BalanceSheet";
import PNLStatement from "../../Components/pnlStatement/PNLStatement";
import Notes from "../../Components/Notes/Notes";
import DynamicNotes from "../../Components/Notes/DynamicNotes";
import PnlNotes from "../../Components/PnlNotes/PnlNotes";
import CreateInput from "../../Components/createInput/CreateInput";
import arrowLeftGreen from "../../assets/arrowLeftGreen.svg";
import Disclosures from "../../Components/Disclosures/Disclosures";
import CashFlow from "../../Components/CashFlow/CashFlow";
import GeneralNote from "../../Components/GeneralNotes/GeneralNote";
import DisclosuresChecklist from "../../Components/DisclosuresChecklist/DisclosuresChecklist";
import { GetProject } from "../../WorkSpace/Components/ApiServices/apiHandler";
import DynamicPnlNotes from "../../Components/Notes/DynamicPnlNotes"
// import StyleExport from "../../Components/excelExport/styleExport";
import PrimaryButton from "../../Components/Common/PrimaryButton";
import UpdatedSocie from "../../Components/socie/UpdatedSocie";
import NoteSoftDelete from "../../Components/templateTabs/NoteSoftDelete/softDelete";
import RestoreSoftDelete from "../../Components/templateTabs/NoteSoftDelete/restoreSoftDelete";

const main = {
  // padding: "2rem 4rem",
  backgroundColor: "rgb(229, 229, 229)",
  minHeight: "calc(100vh - 80px)",
  minWidth: "1045px",
  marginTop: "2.4rem",
  overflowX: "hidden",
};

const header = {
  height: "80px",
  width: "100%",
  backgroundColor: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: 'space-between',
  padding: "0rem 1rem",
  borderRadius: "3px",
  marginBottom: "2rem",


};


const leftheader = {
  display: 'flex',
  alignItems: 'center',
  gap: '30px'
}

const line = {
  height: "50px",
  width: "2px",
  border: "1px solid rgb(229, 229, 229)",
};

const name = {
  fontSize: "15px",
  fontWeight: "500",
  paddingLeft: "1%",
  paddingRight: "2%",
};

const mainTag = {
  fontSize: "1.15rem",
  fontWeight: "700",
};

const backNav = {
  fontSize: "1.2rem",
  fontWeight: "600",
  color: "#03565A",
  cursor: "pointer",
};

// for material ui tab function
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div

      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
// end tab function

const FinancialStatement = () => {
  const project_id = localStorage.getItem("project_id");
  const user = JSON.parse(localStorage.getItem('user'));
  const history = useHistory();
  const dispatch = useDispatch();
  const financialStatementTabs = ["Balance Sheets", "P&L Statement", "CashFlow", "SOCIE", "General Notes", "Balance Sheet Notes", "PNL Statement Notes", "Disclosures", "Disclosures Checklist"];
  const presetNoteKey = useSelector((initialState) => initialState.HandlePopulateFsNotesReducer.noteKey);
  const softDeletedData = useSelector((state) => state.NotesSoftDelete.selectedNotes)
  const [key, setKey] = useState("Balance Sheets");
  const [note, setNote] = useState(0);
  const [showCreateInput, setShowCreateInput] = useState(false);
  const [sid, setSid] = useState(null);
  const [nid, setNid] = useState(null);
  const [NoteNumber, setNoteNumber] = useState(1); // the one with the addition logic
  const [notenum, setNoteNum] = useState(1); //
  const [noteName, setNoteName] = useState(""); //
  const [notenum2, setNoteNum2] = useState(1);
  const [rows, setRows] = useState([]);
  const [str, setStr] = useState("");
  const [render, setRender] = useState("");
  const [precision, setPrecision] = useState("Units");
  const [floatdata, setFloat] = useState();
  const [note4, setNote4] = useState(null);
  const [currentProfit, setCurrentProfit] = useState("");
  const [currentYearProfit, setCurrentYearProfit] = useState("");
  const [comProfit, setComProfit] = useState("");
  const [comYearProfit, setComYearProfit] = useState("");
  const [projectData, setProjectData] = useState({});
  const [allProject, setAllProject] = useState([]);
  const [headerdata, setDataHeader] = useState("");

  const [fromScoie, setFromScoie] = useState({
    SAMoney_pending_allotment_cy: 0,
    SAMoney_pending_allotment_py: 0,
    otherEquity_cy: 0,
    otherEquity_py: 0,
  });

  const units = [
    { value: "Units", label: "Units" },
    // { value: 'Tens', label: 'Tens' },
    { value: "Hundreds", label: "Hundreds" },
    { value: "Thousands", label: "Thousands" },
    { value: "Lakhs", label: "Lakhs" },
    { value: "Crores", label: "Crores" },
  ];

  const decimal = [
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
  ];
  useEffect(() => {
    if (noteName == 1) {
      console.log("one clicked da");
      window.scrollTo({
        bottom: 0,
        behavior: "smooth",
      });
      return;
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const auth = localStorage.getItem("auth_token");
    let headers = {
      "x-auth-token": auth,
    };

    fetchProject(headers);
    AllProject();
  }, [noteName]);

  const fetchProject = (headers) => {
    axios
      .get(`/api/v1/project/get-project-details/${project_id}`, { headers })
      .then((response) => {
        setProjectData(response.data.project);
        console.log(response.data, "11111111");
      })
      .catch((error) => {
        console.log(error.response, "here error");
      });
  };

  const scrollToTop = () => { 
    window.scrollTo({         
      top: 0,
      behavior: "smooth",
    });
  };

  const handleHeader = (e) => {
    saveHeader(e.target.value);
  };

  const saveHeader = (val) => {
    const auth = localStorage.getItem("auth_token");
    const project_id = localStorage.getItem("project_id");
    let headers = {
      "x-auth-token": auth,
    };
    let data = {
      header_name: val,
    };
    axios
      .post(`/api/v1/pnl/save-header/${project_id}`, data, { headers })
      .then((response) => {
        console.log(response.data, "KKK");
        // setRows(response.data.balance_sheet);
      })
      .catch((error) => {
        console.log(error.response, "error");
      });
  };
  const brackets = (val) => {
    // const summa = 'Hundreds'
    // const summa = 'Thousands'
    // const summa = 'Lakhs'
    const summa = precision;
    const float_value = floatdata;
    const k = [
      { value: 1, label: "Units", suffix: "" },
      // { value: 10 , label: 'Tens',suffix:''},
      { value: 100, label: "Hundreds", suffix: "" },
      { value: 1000, label: "Thousands", suffix: "" },
      { value: 100000, label: "Lakhs", suffix: "" },
      { value: 10000000, label: "Crores", suffix: "" },
    ];

    const object = k.find((e) => e.label === summa); 
    const total = parseFloat(val / object.value).toFixed(float_value); 

    if (val < 0) {
      const negNum = -1 * total;
      console.log(object, "object hered object"); 

      return "(" + negNum.toLocaleString("en-IN") + `${object.suffix}` + ")";
    }
    return total.toLocaleString("en-IN") + `${object.suffix}`; 
  };
  const AllProject = async () => {
    const res = await GetProject(); 
    if (res.status === 200) {
      console.log(res.data, "00000");
      setAllProject(res.data.project);
    }
  };
  const workspaceBack = () => {
    const project_id = localStorage.getItem("project_id");
    const nameOfType = allProject.filter((adj) => adj._id === project_id);
    const result = nameOfType[0]?.workspace_id
      ? nameOfType[0]?.workspace_id
      : "";
    history.push(`/financialstatement/${result}`);
  }
  const tabManagement = (i) => {
    switch (i) {
      case 0:
        return <div style={{ minHeight: "100%", backgroundColor: "white" }}><BalanceSheet
          scrollToTop={scrollToTop}
          projectData={projectData}
          fromScoie={fromScoie}
        />
        </div>
      case 1:
        return <div style={{ minHeight: "100%", backgroundColor: "white" }}> <PNLStatement
          precision={precision}
          NoteNumber={NoteNumber}
          notenum2={notenum2}
          setKey={setKey}
          scrollToTop={scrollToTop}
          rows={rows}
          setCurrentProfit={setCurrentProfit}
          setCurrentYearProfit={setCurrentYearProfit}
          setComYearProfit={setComYearProfit}
          currentProfit={currentProfit}
          setNote={setNote}
          setComProfit={setComProfit}
          projectData={projectData}
          floatdata={floatdata}
        /></div>
      case 2:
        return <div style={{ maxHeight: "100%", backgroundColor: "white" }}> <CashFlow /> </div>
      case 3:
        return <UpdatedSocie />
      // <div style={{ height: "55vh", overflowX:"scroll", backgroundColor: "white" }}> 
      // <Socie
      //   currentProfit={currentProfit}
      //   comProfit={comProfit}
      //   currentYearProfit={currentYearProfit}
      //   comYearProfit={comYearProfit}
      //   projectData={projectData}
      //   setFromScoie={setFromScoie}
      //   fromScoie={fromScoie}
      // />
      // </div>
      case 4:
        return <div style={{ maxHeight: "100%", backgroundColor: "white" }}> <GeneralNote /> </div>
      case 5:
        return <div style={{ minHeight: "100%", backgroundColor: "white" }}>{projectData.company_type === 'NBFC' ?
          <DynamicNotes
            note={note}
            setShowCreateInput={setShowCreateInput}
            setNoteName={setNoteName}
            setStr={setStr}
            setNid={setNid}
            setSid={setSid}
            setNoteNum={setNoteNum}
            brackets={brackets}
            render={render}
          /> :
          <Notes
            note={note}
            setShowCreateInput={setShowCreateInput}
            setNoteName={setNoteName}
            setStr={setStr}
            setNid={setNid}
            setSid={setSid}
            setNoteNum={setNoteNum}
            brackets={brackets}
            render={render}
            projectData={projectData}
          />}</div>
      case 6:
        return <div style={{ minHeight: "100%", backgroundColor: "white" }}>{projectData.company_type === 'NBFC' ?
          <DynamicPnlNotes
            note={note}
            setShowCreateInput={setShowCreateInput}
            setNoteName={setNoteName}
            setStr={setStr}
            setNid={setNid}
            setSid={setSid}
            setNoteNum={setNoteNum}
            brackets={brackets}
          />
          :
          <PnlNotes
            note={note}
            setShowCreateInput={setShowCreateInput}
            setNid={setNid}
            setSid={setSid}
            setNoteNum={setNoteNum}
            projectData={projectData}
          />}</div>
      case 7:
        return <div style={{ maxHeight: "100%", backgroundColor: "white" }}> <Disclosures
          scrollToTop={scrollToTop}
          setKey={setKey}
          setNoteName={setNoteName}
        /> </div>
      case 8:
        return <div style={{ maxHeight: "100%", backgroundColor: "white" }}> <DisclosuresChecklist /> </div>
      default:
        return <></>;
    }
  }
  return (
    <>
      <div style={{ backgroundColor: "rgb(229, 229, 229)", }}>
        <Navbar text="Financial Year: 2021 - 2022" />
        {showCreateInput ? (
          <CreateInput
            setShowCreateInput={setShowCreateInput}
            setRender={setRender}
            str={str}
            sid={sid}
            nid={nid}
            setNote4={setNote4}
            notenum={notenum}
          />
        ) : null}
        <div style={main}>
          <div style={header}>
            <div style={leftheader}>
              <div style={name}>
                <div
                  style={backNav}
                  onClick={() => {
                    history.push(`/preview`);
                  }}
                >
                  <img src={arrowLeftGreen} style={{ marginRight: "10px", }} />
                </div>
              </div>
              <div style={line}></div>
              <div style={mainTag}>
                <input
                className="custom-placeholder"
                  placeholder="Financial Statements"
                  style={{ border: "0", width: "20rem", color: "black",fontSize:"18px" }}
                  defaultValue={headerdata}
                  onChange={(e) => handleHeader(e)}
                />

              </div>

            </div>
            {/* <div style={button3} title="units conversion of the tb amount">
            <Select
              placeholder=""
              as="select"
              options={units}
              isClearable
              onChange={(e) => {
                setPrecision(e.value);
              }}
              defaultInputValue="Units"
              theme={(theme) => ({
                ...theme,
                borderRadius: "6px",
                cursor: "pointer",
                colors: {
                  ...theme.colors,
                  backgroundColor: "white",
                  primary25: "#03565a98",
                  primary: "#03565A",
                },
                spacing: {
                  ...theme.spacing,
                  controlHeight: 50,
                },
              })}
            />
          </div> 
           <div style={button2}>
            <Select
              placeholder="0"
              as="select"
              options={decimal}
              isClearable
              onChange={(e) => {
                setFloat(e.value);
              }}
              defaultInputValue="2"
            />
          </div> */}
            <div className="d-flex align-items-center">
              {softDeletedData.length > 0 ?
                <NoteSoftDelete softDeletedData={softDeletedData}/>
                :
                <RestoreSoftDelete/>
              }
              <PrimaryButton name="Back to Workspace" variant={"outlined"} onClick={workspaceBack} />
              <PrimaryButton name="Link Registers" variant={"contained"} onClick={() => history.push('/link-register')} />
              <PrimaryButton name="Finalize FS" variant={"contained"} onClick={() => history.push('/finalize-fs')} />
              {/* <StyleExport /> */}
            </div>
            {/* <PopulateFSExportExcel /> */}
          </div>
          <div>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={presetNoteKey}
                  onChange={(event, newValue) => dispatch({ type: "NOTE_KEY", payload: newValue })}
                  aria-label="basic tabs example"
                  className="paneltabcolor"
                  variant="scrollable"

                >
                  {
                    financialStatementTabs.map((label, index) => (
                      <Tab label={label} {...a11yProps(index)} className={`paneltab p-2 ml-2 font-inter`} />
                    ))
                  }
                </Tabs>
              </Box>
              {
                financialStatementTabs.map((label, index) => (
                  <CustomTabPanel value={presetNoteKey} index={index} >
                    {tabManagement(index)}
                  </CustomTabPanel>
                ))
              }
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinancialStatement;
