import React from "react";
import { useState } from "react";
import "../../Workspace2/sidebar.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
// import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import InProgress from "../../../assets/Image/InProgress.png";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import { CreateWorkspace, getWorkspace } from "../ApiServices/apiHandler";
import WorkspaceHeader from "./WorkspaceHeader";
import swal from 'sweetalert';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useEffect } from "react";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { createBookClosure } from "../../../Bookclosure/Services/ApiHandler";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { getActivePlan } from "../ApiServices/apiHandler";

const label = { inputProps: { "aria-label": "Checkbox demo" } };


const style = {
  position: "absolute",
  top: "75%",
  left: "36%",
  transform: "translate(-50%, -50%)",
  height: 250,
  width: 320,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const GettingStarted = () => {
  const { workspaceId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [activePlandata, setActiveplanData] = useState([]);
  const user = JSON.parse(localStorage.getItem('user'));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElConsole, setAnchorElConsole] = React.useState(null);
  const [checkstatus, setCheckStatus] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedconsole, setIsCheckedConsole] = useState(false);
  const [yearData, setYearData] = useState({
    standalone_py: "",
    standalone_cy: "",
    console_py: "",
    console_cy: "",
    bookClosureAsOnDate: "",
  });
  const [hasWorkspace, setHasWorkspace] = useState(false);
  const [workspace, setWorkspace] = useState([]);

  const [isBookClosureOpen, setIsBookClosureOpen] = useState(false);
  const [isBookClosureCheckBox, setIsBookClosureCheckBox] = useState(false);

  const openConsole = Boolean(anchorElConsole);
  const id_c = openConsole ? "simple-popover" : undefined;
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (typeof workspaceId !== 'undefined' && workspaceId !== null) {
      getWorkspaceDetails(workspaceId);
    }
  }, [])

  const getWorkspaceDetails = async (w_id) => {
    const res = await getWorkspace(w_id);
    if (res.status === 200) {
      setHasWorkspace(true)
      setWorkspace(res.data.getworkspace[0])
    }
  }
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose2 = () => {
    setAnchorElConsole(null);
  };

  useEffect(() => {

    activePlan();

  }, [])



  const activePlan = async () => {
    try {
      const res = await getActivePlan();
      console.log(res.data.categorizedBenefits.workspaceFunctions, "res--------->");
      setActiveplanData(res.data.categorizedBenefits.workspaceFunctions);

    } catch (error) {
      console.log(error.message)
    }
  }


  // Function to handle checkbox click
  const handleCheckboxClick = (event) => {
    setIsChecked(!isChecked);
    if (isChecked === false) {
      setAnchorEl(event.currentTarget);
      yearData.standalone_cy = "";
      yearData.standalone_py = ""
    }
  };
  const handleClickConsol = (event) => {
    if (isCheckedconsole === false) {
      setAnchorElConsole(event.currentTarget);
    }
    setIsCheckedConsole(!isCheckedconsole);
  };
  /// 


  const handleYearChange = (field, e) => {
    setYearData({ ...yearData, [field]: dayjs(e).format('YYYY-MM-DD') });
  };
  const saveStandAloneRange = () => {
    setAnchorEl(null);
    setCheckStatus(true);
    setHasWorkspace(false)
  };
  const handleSaveConsole = () => {
    setAnchorElConsole(null);
  };

  const SaveWorkspaceCompany = async () => {
    try {
      let data = {};
      if (typeof workspaceId !== 'undefined' && workspaceId !== null) {
        data = {
          workspaceId: workspaceId ? workspaceId : "",
          standalone_cy: workspace.standalone_cy,
          standalone_py: workspace.standalone_py,
          console_cy: workspace.console_cy,
          console_py: workspace.console_py,
          book_closure_as_on_date: workspace?.bookClosureAsOnDate,
          steps: "step1",
          has_published: "draft",
          status: workspace.status
        };
      } else {
        data = {
          workspaceId: workspaceId ? workspaceId : "",
          standalone_cy: yearData.standalone_cy,
          standalone_py: yearData.standalone_py,
          book_closure_as_on_date: yearData?.bookClosureAsOnDate || "",
          console_cy: isCheckedconsole ? yearData.standalone_cy : "",
          console_py: isCheckedconsole ? yearData.standalone_py : "",
          steps: "step1",
          has_published: "draft",
          status: "start"
        };
      }

      const res = await CreateWorkspace(data);
      if (res.status === 200) {
        if (isBookClosureCheckBox) {
          createBookClosure({
            workspaceID: res.data.data._id,
            asOnDate: moment(yearData.bookClosureAsOnDate).format("DD-MM-YYYY"),
          }).then(response => {
            dispatch({ type: "BOOK_cLOSURE_DATA", payload: response.data?.bookClosure || {} });
            toast.success("Book Disclosure Created Successfully.")
          }).catch(error => {
            toast.error(`Book Disclosure Error: ${error}`)
          })
        }
        history.push(`/workspacesetup/${res.data.data._id}`);
      }
    } catch (error) {
      return 0;
    }
  };

  const handlecheck = (e) => {
    console.log(yearData, "----------")
    if (yearData.standalone_cy == '' || yearData.standalone_py == '') {
      swal({
        title: "Please select the",
        text: "Financial Statements Standalone",
        html: true,
        icon: 'warning',

      });
      return false
    }

    if (e.target.checked === true) {


      setIsCheckedConsole(true);
    } else {
      setIsCheckedConsole(false);

    }
  }

  const isCheckedNextBtn = () => !((isChecked && checkstatus) || isCheckedconsole || isBookClosureCheckBox || (typeof workspaceId !== "undefined" && workspaceId !== null));


  return (
    <>
      <WorkspaceHeader
        currentStep="1"
        title="What are you currently working on ?"
      />
      <div className="row wp-selection">
        {/* Book Closure */}
        {activePlandata.includes('Book Closure') ? (
          <div className="col-md-3">
            <Card className="h-100">
              <CardContent>
                <input
                type="checkbox"
                checked={isBookClosureCheckBox}
                disabled={user.responsibilities === "approver" || workspace.status === "waiting"||user.responsibilities === "view"}
                onChange={() => {
                  setIsBookClosureCheckBox(!isBookClosureCheckBox);
                  setIsBookClosureOpen(!isBookClosureOpen);
                }}
                />
                <div className="chekbox-v2">
                  Book closure
                </div>
                <div className="pl-2">
                  <p className="font-inter font-size-12 text-secondary m-0 custom-font-600">
                    {`As On Date : ${yearData.bookClosureAsOnDate ? moment(yearData.bookClosureAsOnDate).format("MMMM DD, YYYY").toUpperCase() : ""}`}
                  </p>
                </div>
              </CardContent>
            </Card>
            {isBookClosureOpen && (
              <div className="popover-container">
                <div className="popover">
                  <div className="popover-arrow"></div>
                  <div className="popover-content">
                    <div>
                      <div className="p-4 ml-2">
                        <h6 className="text-secondary font-size-14  mb-1 mt-1">Select As On Date <span className="text-danger">*</span></h6>
                        <div className="w-100">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker', 'MobileDatePicker']}>
                              <div className="calendar-container">
                                <MobileDatePicker
                                  onChange={(e) => handleYearChange("bookClosureAsOnDate", e)}
                                />
                                <span className="calendar-icon">
                                  <CalendarMonthIcon />
                                </span>
                              </div>
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                        <div className="mt-3 mb-1">
                          <Button
                            className="text-capitalize custom-common-theme-bg-color w-100"
                            variant="contained"
                            onClick={() => setIsBookClosureOpen(false)}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="col-md-3">
            <Card className="h-100">
              <CardContent>
                <input
                type="checkbox"
                checked={false}
                disabled
                />
                <div className="chekbox-v2">
                  Book closure
                </div>
                <div className="pl-2">
                  <p className="font-inter font-size-12 text-secondary m-0 custom-font-600">
                    As On Date : N/A
                  </p>
                </div>
              </CardContent>
            </Card>
          </div>
        )}
        {/*new card  */}
        {activePlandata.includes('Standalone financial statements') ? (
          <div className="col-md-3">
            <Card
              className={`${open
                ? "card-v2-border"
                : (hasWorkspace && workspace.standalone_cy) || isChecked
                  ? "card-v2-border"
                  : "card-v2"
                }`}
            >
              <CardContent>
                <input
                  type="checkbox"
                  checked={hasWorkspace && workspace.standalone_cy ? true : isChecked}
                  disabled={user.responsibilities === "approver" || workspace.status === "waiting"||user.responsibilities === "view"}
                  onClick={handleCheckboxClick}
                  
                />
                <div className="chekbox-v2">
                  Financial Statements
                  <br />
                  Standalone
                </div>
                <div className="d-flex flex-column ml-2" style={{ color: "darkgray" }}>
                  <div className="font-inter" style={{ fontSize: "12px" }}>
                    <span className="font-inter" style={{ color: "darkgray", fontSize: "12px" }}>CY : </span>
                    {checkstatus === true && isChecked === true
                      ? moment(yearData.standalone_cy).format("MMMM DD, YYYY").toUpperCase()
                      : ""}
                    {hasWorkspace && workspace.standalone_cy != "" ? (
                      <span>{moment(workspace?.standalone_cy).format("MMMM DD, YYYY").toUpperCase()}</span>
                    ) : ""}
                  </div>
                  <div className="font-inter" style={{ fontSize: "12px" }}>
                    <span className="font-inter" style={{ color: "darkgray", fontSize: "12px" }}>PY: </span>
                    {checkstatus === true && isChecked === true
                      ? moment(yearData.standalone_py).format("MMMM DD, YYYY").toUpperCase()
                      : ""}
                    {hasWorkspace && workspace.standalone_py != "" ? (
                      <span>{moment(workspace?.standalone_py).format("MMMM DD, YYYY").toUpperCase()}</span>
                    ) : ""}
                  </div>
                </div>
              </CardContent>
            </Card>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Typography sx={{ p: 2 }}>
                <div className="wp-datepicker">
                  Select Current Year <span style={{ color: "red" }}>*</span> <br />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker', 'MobileDatePicker']}>
                      <MobileDatePicker
                        onChange={(e) => handleYearChange("standalone_cy", e)}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                <div className="wp-datepicker mt-3">
                  Select Previous Year <span style={{ color: "red" }}>*</span><br />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker', 'MobileDatePicker']}>
                      <MobileDatePicker
                        onChange={(e) => handleYearChange("standalone_py", e)}
                        maxDate={dayjs(new Date(yearData.standalone_cy))}
                        disabled={yearData.standalone_cy ? false : true}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                <div>
                  <div style={{ marginTop: "30px" }}>
                    <Button
                      className="datebtn font-sans"
                      style={{
                        backgroundColor: "#03565A",
                        color: "white",
                        textTransform: "none",
                      }}
                      onClick={saveStandAloneRange}
                    >
                      Save Range
                    </Button>
                  </div>
                </div>
              </Typography>
            </Popover>
          </div>
        ) : (
          <div className="col-md-3">
            <Card className="card-v2">
              <CardContent>
                <input
                  type="checkbox"
                  checked={false}
                  disabled
                />
                <div className="chekbox-v2">
                  Financial Statements
                  <br />
                  Standalone
                </div>
                <div className="d-flex flex-column ml-2" style={{ color: "darkgray" }}>
                  <div className="font-inter" style={{ fontSize: "12px" }}>
                    <span className="font-inter" style={{ color: "darkgray", fontSize: "12px" }}>CY : N/A</span>
                  </div>
                  <div className="font-inter" style={{ fontSize: "12px" }}>
                    <span className="font-inter" style={{ color: "darkgray", fontSize: "12px" }}>PY: N/A</span>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        )}


        {activePlandata.includes('Console financial statements') ? (
          <div className="col-md-3">
            <Card
              className={`${isCheckedconsole ? "card-v2-border" : hasWorkspace && workspace.console_cy ? "card-v2-border" : "card-v2"}`}>
              <CardContent>
                <input
                type="checkbox"
                checked={hasWorkspace && workspace.console_cy ? true : isCheckedconsole}
                disabled={user.responsibilities === "approver" || workspace.status === "waiting"||user.responsibilities === "view"}
                onClick={(e) => handlecheck(e)}
                />
                <div className="chekbox-v2">
                  Financial Statements
                  <br />
                  Consol
                </div>

                <div className="d-flex flex-column ml-2" style={{ color: "darkgray" }}>
                  <div className="font-inter" style={{ fontSize: "12px" }}>
                    <span className="font-inter" style={{ color: "darkgray", fontSize: "12px" }}>CY: </span>
                    {isCheckedconsole === true
                      ? moment(yearData.standalone_cy).format("MMMM DD, YYYY")
                      : ""}
                    {hasWorkspace && workspace.console_cy != "" ? (
                      <span>{moment(workspace?.console_cy).format("MMMM DD, YYYY")}</span>
                    ) : ""}
                  </div>
                  <div className="font-inter" style={{ fontSize: "12px" }}>
                    <span className="font-inter" style={{ color: "darkgray", fontSize: "12px" }}>PY: </span>
                    {isCheckedconsole === true
                      ? moment(yearData.standalone_py).format("MMMM DD, YYYY")
                      : ""}
                    {hasWorkspace && workspace.console_py != "" ? (
                      <span>{moment(workspace?.console_py).format("MMMM DD, YYYY")}</span>
                    ) : ""}
                  </div>
                </div>
              </CardContent>
            </Card>

            <Popover
              id={id_c}
              open={openConsole}
              anchorEl={anchorElConsole}
              onClose={handleClose2}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Typography sx={{ p: 2 }}>
                <div>
                  <div>
                    Select Current Year <span style={{ color: "red" }}>*</span>
                  </div>
                  <div>
                    <input
                      className="date-v2"
                      type="date"
                      onChange={(e) => handleYearChange("console_cy", e)}
                    />
                  </div>
                  <div>
                    Select Previous Year <span style={{ color: "red" }}>*</span>
                  </div>
                  <div>
                    <input
                      className="date-v2"
                      type="date"
                      onChange={(e) => handleYearChange("console_py", e)}
                    />
                  </div>

                  <div style={{ marginTop: "30px" }}>
                    <Button
                      className="datebtn"
                      style={{
                        backgroundColor: "#03565A",
                        color: "white",
                        textTransform: "none",
                      }}
                      onClick={handleSaveConsole}
                    >
                      Save Range
                    </Button>
                  </div>
                </div>
              </Typography>
            </Popover>
          </div>
        ) : (
          <div className="col-md-3">
            <Card className="card-v2">
              <CardContent>
                <input
                type="checkbox"
                checked={false}
                disabled
                />
                <div className="chekbox-v2">
                  Financial Statements
                  <br />
                  Consol
                </div>

                <div className="d-flex flex-column ml-2" style={{ color: "darkgray" }}>
                  <div className="font-inter" style={{ fontSize: "12px" }}>
                    <span className="font-inter" style={{ color: "darkgray", fontSize: "12px" }}>CY: N/A</span>
                  </div>
                  <div className="font-inter" style={{ fontSize: "12px" }}>
                    <span className="font-inter" style={{ color: "darkgray", fontSize: "12px" }}>PY: N/A</span>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        )}

        {activePlandata.includes('Data analytics') ? (
          <div className="col-md-3">
            <Card className="card-v2">
              <CardContent style={{ position: "relative" }}>
                <div className="position-absolute" style={{ top: "-16px", right: "-13px", }}>
                  <img
                    src={InProgress}
                    style={{ height: "256px" }}
                    className=""
                    alt=""
                  />
                </div>
                <input type="checkbox" disabled />
                <div className="chekbox-v2">Data Analytics</div>
              </CardContent>
            </Card>
          </div>
        ) : (
          <div className="col-md-3">
            <Card className="card-v2">
              <CardContent style={{ position: "relative" }}>
                <input type="checkbox" disabled />
                <div className="chekbox-v2">Data Analytics</div>
              </CardContent>
            </Card>
          </div>
        )}

        {activePlandata.includes('Projections (Budget vs fixed)') ? (
          <div className="col-md-3 mt-3">
            <Card className="card-v2">
              <CardContent className="position-relative">
                <div
                  className="position-absolute"
                  style={{ top: "-16px", right: "-13px" }}
                >
                  <img
                    src={InProgress}
                    style={{ width: "256px" }}
                    className=""
                    alt="In progress logos"
                  />
                </div>
                <input
                type="checkbox"
                disabled
                />
                <div className="chekbox-v2">Projections</div>
              </CardContent>
            </Card>
          </div>
        ) : (
          <div className="col-md-3 mt-3">
            <Card className="card-v2">
              <CardContent className="position-relative">
                <input type="checkbox"
                  disabled
                />
                <div className="chekbox-v2">Projections</div>
              </CardContent>
            </Card>
          </div>
        )}

        {activePlandata.includes('SEBI Reporting') ? (
          <div className="col-md-3 mt-3">
            <Card className="card-v-2">
              <CardContent className="position-relative">
                <div className="position-absolute" style={{ top: "-16px", right: "-13px" }}>
                  <img
                    src={InProgress}
                    style={{ width: "256px" }}
                    className=""
                    alt="In progress logos"
                  />
                </div>
                <input type="checkbox"
                  disabled
                />
                <div className="chekbox-v2">SEBI reporting</div>
              </CardContent>
            </Card>
          </div>
        ) : (
          <div className="col-md-3 mt-3">
            <Card className="card-v-2">
              <CardContent className="position-relative">
                <input type="checkbox"
                  disabled
                />
                <div className="chekbox-v2">SEBI reporting</div>
              </CardContent>
            </Card>
          </div>
        )}

      </div>

      <div className="row mt-5">
        <div className="col-md-6">
          <div
            className="backicon"
            onClick={() => history.push(`/dashboard`)}
            style={{ cursor: "pointer" }}
          >
            <ArrowBackIcon />
            <div>Back to the Homepage </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="text-right">
            <button
              className="font-sans w-75 border-0 rounded shadow py-2 text-white"
              disabled={isCheckedNextBtn()}
              style={{
                backgroundColor: isCheckedNextBtn() ? "#B3D9D9" : "#03565A",
              }}
              onClick={SaveWorkspaceCompany}
            >
              Next Step
            </button>
          </div>
        </div>
      </div>
    </>
  );
};


export default GettingStarted;
