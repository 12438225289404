import React from 'react';
import moment from "moment";
import { useSelector } from 'react-redux';
import { formatIndianCurrency } from '../../../helper/ConvertValue'
import { DisclosureNBFCTableBody, DisclosureNBFCTableHead } from './DisclosureHelper';


export function NBFC_34({ data, subIndex, HandleChange, dis_index_id }) {
    const reduxProjectData = useSelector((initialState) => initialState?.DisclosureReducer?.projectData || {});
    const user = JSON.parse(localStorage.getItem('user'));

    return (
        <>
            <thead>
                {dis_index_id === 1 && (
                    <tr>
                        <th className='text-left w-50 pl-3 border-top-0 border-bottom-0'>
                            Nature of Relationship
                        </th>
                        <th className='text-left w-50 pl-3 border-top-0 border-bottom-0'>Name of Related Party</th>
                        <DisclosureNBFCTableHead isEmpty={false} />
                    </tr>
                )}
                {dis_index_id === 2 && (
                    <>
                        <tr>
                            <th className='text-left w-75 pl-3 border-top-0 border-bottom-0'>
                                Nature of Transactions
                            </th>
                            <th className='w-auto border-top-0 border-bottom-0'>
                                For year ended Mar 31, 2023
                            </th>
                            <th className='w-auto border-top-0 border-bottom-0' colSpan={2}>
                                For year ended  Mar 31, 2022
                            </th>
                            <DisclosureNBFCTableHead isEmpty={false} />
                        </tr>

                    </>
                )}
                {dis_index_id === 3 && (
                    <>
                        <tr>
                            <th className='text-left w-75 pl-3 border-top-0 border-bottom-0' >
                                Particulars
                            </th>
                            <th className='w-auto border-top-0 border-bottom-0' style={{ minWidth: "110px" }}>
                                Options
                            </th>
                            <th className='w-auto border-top-0 border-bottom-0' style={{ minWidth: "110px" }}>
                                Weighted Average
                                Exercise Price
                            </th>
                            <DisclosureNBFCTableHead isEmpty={false} />
                        </tr>

                    </>
                )}
                {dis_index_id === 4 && (
                    <>
                        <tr>
                            <th className='text-left w-75 pl-3 border-top-0 border-bottom-0' >
                                Particulars
                            </th>
                            <th className='w-auto border-top-0 border-bottom-0' style={{ minWidth: "110px" }}>
                                Options
                            </th>
                            <th className='w-auto border-top-0 border-bottom-0' style={{ minWidth: "110px" }}>
                                Weighted Average
                                Exercise Price
                            </th>
                            <DisclosureNBFCTableHead isEmpty={false} />
                        </tr>

                    </>
                )}
                {dis_index_id === 5 && (
                    <>
                        <tr>
                            <th className='text-left w-75 pl-3 border-top-0 border-bottom-0'>
                                Category
                            </th>
                            <th className='w-auto border-top-0 border-bottom-0' style={{ minWidth: "110px" }}>
                                Weighted Average
                                Exercise Price
                            </th>
                            <th className='w-auto border-top-0 border-bottom-0' style={{ minWidth: "110px" }} >
                                Number of shares
                                arising out of
                                options
                            </th>
                            <th className='w-auto border-top-0 border-bottom-0' style={{ minWidth: "110px" }} >
                                Weighted average
                                remaining
                                contractual life (in
                                years)
                            </th>
                            <DisclosureNBFCTableHead isEmpty={false} />
                        </tr>

                    </>
                )}
                {dis_index_id === 6 && (
                    <>
                        <tr>
                            <th className='text-left w-75 pl-3 border-top-0 border-bottom-0'>
                                Category
                            </th>
                            <th className='w-auto border-top-0 border-bottom-0' style={{ minWidth: "110px" }}>
                                Weighted Average
                                Exercise Price
                            </th>
                            <th className='w-auto border-top-0 border-bottom-0' style={{ minWidth: "110px" }}>
                                Number of shares
                                arising out of
                                options
                            </th>
                            <th className='w-auto border-top-0 border-bottom-0' style={{ minWidth: "110px" }}>
                                Weighted average
                                remaining
                                contractual life (in
                                years)
                            </th>
                            <DisclosureNBFCTableHead isEmpty={false} />
                        </tr>

                    </>
                )}
                {dis_index_id === 7 && (
                    <>
                        <tr>
                            <th className='text-left w-75 pl-3 border-top-0 border-bottom-0'>
                                Particulars
                            </th>
                            <th className='w-auto border-top-0 border-bottom-0' style={{ minWidth: "110px" }}>
                                Unit
                            </th>
                            <th className='w-auto border-top-0 border-bottom-0' style={{ minWidth: "110px" }}>
                                As at {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}
                            </th>
                            <th className='w-auto border-top-0 border-bottom-0' style={{ minWidth: "110px" }}>
                                As at {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}
                            </th>
                            <DisclosureNBFCTableHead isEmpty={false} />
                        </tr>

                    </>
                )}
                {dis_index_id === 8 && (
                    <>
                        <tr>
                            <th className='text-left w-75 pl-3 border-top-0 border-bottom-0'>
                                Key Management Personnel For year ended
                            </th>
                            <th className='w-auto border-top-0 border-bottom-0'>
                                For year ended 31, 2023
                            </th>
                            <th className='w-auto border-top-0 border-bottom-0'>
                                For year ended Mar 31, 2022
                            </th>
                            <DisclosureNBFCTableHead isEmpty={false} />
                        </tr>
                    </>
                )}
            </thead>

            <tbody>
                {data &&
                    data.length > 0 &&
                    data.map((subDis, subDisIndex) => (
                        <>
                            {dis_index_id === 1 && (
                                <>
                                    <tr key={subDisIndex}>
                                        <td className='border-top-0 border-bottom-0' rowSpan={Array.isArray(subDis.name_related_party) ? subDis.name_related_party.length : 1}>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                    }`}
                                                type={"text"}
                                                value={subDis?.nature_of_relationship}
                                                name="header"
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                    }`}
                                                type={"text"}
                                                value={Array.isArray(subDis.name_related_party) ? subDis.name_related_party[0] : subDis.name_related_party}
                                                name="header"
                                            />
                                        </td>

                                        <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData}
                                           isReference={subDis?.isReference} keyValue={11} index={subIndex}  sub_index={subDisIndex} />
                                    </tr>
                                    {Array.isArray(subDis.name_related_party) &&
                                        subDis.name_related_party.slice(1).map((name, subIndex) => (
                                            <tr key={`${subDisIndex}-${subIndex}`}>
                                                <td className='border-top-0 border-bottom-0'>
                                                    <input style={{ backgroundColor: "inherit" }}
                                                        className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                            }`}
                                                        type={"text"}
                                                        value={name}
                                                        name="header"
                                                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                </>
                            )}
                            {dis_index_id === 2 && (
                                <>
                                    <tr>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                    }`}
                                                type={"text"}
                                                value={subDis?.header}
                                                name="header"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>

                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                readOnly={typeof subDis?.gratuity_cy !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                type={"text"}
                                                value={formatIndianCurrency(subDis?.cy)}
                                                name="gratuity_cy"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={"text"}
                                                readOnly={typeof subDis?.gratuity_py !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                value={formatIndianCurrency(subDis?.py)}
                                                name="gratuity_py"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} 
                                             isReference={subDis?.isReference}  keyValue={11} index={subIndex}  sub_index={subDisIndex} />
                                    </tr>
                                </>
                            )}
                            {dis_index_id === 3 && (
                                <>
                                    <tr >
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                    }`}
                                                type={"text"}
                                                value={subDis?.header}
                                                name="header"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>

                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                readOnly={typeof subDis?.options !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                type={"text"}
                                                value={formatIndianCurrency(subDis?.options)}
                                                name="options"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={"text"}
                                                readOnly={typeof subDis?.weighted_average !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                value={formatIndianCurrency(subDis?.weighted_average)}
                                                name="weighted_average"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        {/* <td className='border-top-0 border-bottom-0'>
                                           
                                        </td> */}
                                        <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData}
                                           isReference={subDis?.isReference}  keyValue={11} index={subIndex}  sub_index={subDisIndex}/>
                                    </tr>
                                </>
                            )}
                            {dis_index_id === 4 && (
                                <>
                                    <tr >
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                    }`}
                                                type={"text"}
                                                value={subDis?.header}
                                                name="header"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>

                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                readOnly={typeof subDis?.options !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                type={"text"}
                                                value={formatIndianCurrency(subDis?.options)}
                                                name="options"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={"text"}
                                                readOnly={typeof subDis?.weighted_average !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                value={formatIndianCurrency(subDis?.weighted_average)}
                                                name="weighted_average"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData}
                                            isReference={subDis?.isReference}  keyValue={11} index={subIndex}  sub_index={subDisIndex}/>
                                    </tr>
                                </>
                            )}
                            {dis_index_id === 5 && (
                                <>
                                    <tr>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                    }`}
                                                type={"text"}
                                                value={subDis?.header}
                                                name="header"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                readOnly={typeof subDis?.weighted_average !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                type={"text"}
                                                value={formatIndianCurrency(subDis?.weighted_average)}
                                                name="weighted_average"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={"text"}
                                                readOnly={typeof subDis?.number_of_shares !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                value={formatIndianCurrency(subDis?.number_of_shares)}
                                                name="number_of_shares"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={"text"}
                                                readOnly={typeof subDis?.weighted_average_remaining !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                value={formatIndianCurrency(subDis?.weighted_average_remaining)}
                                                name="weighted_average_remaining"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} 
                                            isReference={subDis?.isReference}  keyValue={11} index={subIndex}  sub_index={subDisIndex} />
                                    </tr>
                                </>
                            )}
                            {dis_index_id === 6 && (
                                <>
                                    <tr>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                    }`}
                                                type={"text"}
                                                value={subDis?.header}
                                                name="header"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                readOnly={typeof subDis?.weighted_average !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                type={"text"}
                                                value={formatIndianCurrency(subDis?.weighted_average)}
                                                name="weighted_average"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={"text"}
                                                readOnly={typeof subDis?.number_of_shares !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                value={formatIndianCurrency(subDis?.number_of_shares)}
                                                name="number_of_shares"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={"text"}
                                                readOnly={typeof subDis?.weighted_average_remaining !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                value={formatIndianCurrency(subDis?.weighted_average_remaining)}
                                                name="weighted_average_remaining"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} 
                                             isReference={subDis?.isReference}  keyValue={11} index={subIndex}  sub_index={subDisIndex}/>
                                    </tr>
                                </>
                            )}
                            {dis_index_id === 7 && (
                                <>
                                    <tr>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                    }`}
                                                type={"text"}
                                                value={subDis?.header}
                                                name="header"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"}`}
                                                type={"text"}
                                                value={subDis?.header_2}
                                                name="header_2"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={"text"}
                                                readOnly={typeof subDis?.cy !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                value={formatIndianCurrency(subDis?.cy)}
                                                name="cy"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={"text"}
                                                readOnly={typeof subDis?.py !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                value={formatIndianCurrency(subDis?.py)}
                                                name="py"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData}
                                           isReference={subDis?.isReference}  keyValue={11} index={subIndex}  sub_index={subDisIndex}/>
                                    </tr>
                                </>
                            )}
                            {dis_index_id === 8 && (
                                <>
                                    <tr>
                                        <td className='border-top-0 border-bottom-0' >
                                            <input style={{ backgroundColor: "inherit" }}
                                                className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                    }`}
                                                type={"text"}
                                                value={subDis?.header}
                                                name="header"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                readOnly={typeof subDis?.gratuity_cy !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                type={"text"}
                                                value={formatIndianCurrency(subDis?.cy)}
                                                name="gratuity_cy"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={"text"}
                                                readOnly={typeof subDis?.gratuity_py !== "number"||user.responsibilities === "view"||user.responsibilities === "approver"}
                                                value={formatIndianCurrency(subDis?.py)}
                                                name="gratuity_py"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData}
                                              isReference={subDis?.isReference}  keyValue={11} index={subIndex}  sub_index={subDisIndex}/>
                                    </tr>
                                </>
                            )}
                        </>
                    ))}
            </tbody>
        </>
    );
}