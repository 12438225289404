import React, { useEffect, useState } from 'react';
import useFetchApi from '../../../services/CustomHook/useFetchApi';
import swal from 'sweetalert';
import Loading from '../../Common/Loader/Loading';
import TableManagement from './TableManagement';
import { financialInstrumentUpdate } from "../../../services/api/StandAlone/disclosuresTabAPI";
import * as actionTypes from "../../../redux/actionTypes";
import { useDispatch } from 'react-redux';
import RestoreFinancialModal from './RestoreFinancialModal';


const FinancialInstrument = ({scrollToTop, setKey, setNoteName}) => {
    const dispatch = useDispatch();
    const project_id = localStorage.getItem('project_id');
    const tb_id = localStorage.getItem("tb_id");
    
    const { loading, response, error } = useFetchApi(`api/v1/disclosure/financial-instruments/${project_id}/${tb_id}`);
    const [financialData, setFinancialData] = useState({});
    const [deletedItemData, setDeletedItemData] = useState({});
    const [isDeleted, setIsDeleted] = useState(false);

    useEffect(() => {
        if (response && Object.keys(response).length > 0) {
            setFinancialData(response?.data?.data?.data);
            setDeletedItemData(response?.data?.data?.deletedItem);
        }
        if (error) {
            swal("Error", error, "error");
        }
    }, [response || error])

    const HandleChange = (value, index, onchangeProp, table_key) => {

        let updateData = {
            val:value, 
            arrIndex:index ,
            prop:onchangeProp,
            table:table_key,
        }

        financialInstrumentUpdate(`api/v1/disclosure/financial-instruments/${project_id}`, updateData).then(res => {
           
            if (res.success) {
                setFinancialData(res?.data?.data);
                setDeletedItemData(res?.data?.deletedItem);
            }
        }).catch(err => {
            swal("", err.message, "error");
            // setIsOpen(false);
        })

    }

    const HandleDelete = (i, onchangeProp, element, key) => {
       

        let updateData = {
            keyIndex: i,
            item: element,
            prop: onchangeProp,
            key : key
        }
       

        financialInstrumentUpdate(`api/v1/disclosure/delete-financial-instruments/${project_id}`, updateData).then(res => {
           
            if (res.success) {
                setFinancialData(res?.data?.data);
                setDeletedItemData(res?.data?.deletedItem);
                swal("Data deleted and restored in deleted items list, successfully!", {
                    icon: "success",
                });
            }
        }).catch(err => {
            swal("", err.message, "error");
        })
    }

    const handleSelect = (key) => {
       
        setNoteName(key);
        setKey("Notes");
        scrollToTop();
        dispatch({ type: actionTypes.SET_BLNO, payload: key });
    }


    return (
        <>
            <div className='d-flex mr-2 justify-content-end' style={{ gap: "1rem" }}>
                <div className='my-2 rounded deleteBtn' style={{ width: "fit-content", cursor: "pointer" }}
                    onClick={() => setIsDeleted(true)}
                >
                    <div className='px-4 py-2' style={{ color: "#03565a", fontWeight: "600" }}>Deleted Items</div>
                </div>

                {/* <div className='my-3 border rounded' style={{width:"fit-content", cursor: "pointer"}}>
                <div className='px-4 py-2' style={{color:"#03565a", fontWeight:"600"}}>Export Working</div>
                </div> */}
            </div>

            {/* {softLoading && <SoftLoading />} */}

            {
                !loading ? (
                    <>
                        <table>
                            <thead>
                                <tr className='custom-common-theme-bg-color'>
                                    <th style={{ width: "35%" }} ></th>
                                    <th style={{ width: "9%" }}  className='text-white' >Note</th>
                                    <th style={{ width: "9%" }}  className='text-white' >Level 1</th>
                                    <th style={{ width: "9%" }} className='text-white'  >Level 2</th>
                                    <th style={{ width: "9%" }}  className='text-white' >Level 3</th>
                                    <th style={{ width: "9%" }}  className='text-white' >Total</th>
                                    <th style={{ width: "14%" }} className='text-white'  >Fair value hierarchy</th>
                                    <th style={{ width: "6%" }}  ></th>
                                </tr>
                            </thead>
                        </table>
                        {
                            financialData?.current_year && <TableManagement handleSelect={handleSelect} HandleDelete={HandleDelete} onChangeYT={"cy"} HandleChange={HandleChange} table={1} tableData={financialData?.current_year} title={"Financial assets and liabilities - Fair value hierarchy (Current Year)"} />
                        }
                        {
                            financialData?.prev_year && <TableManagement handleSelect={handleSelect} HandleDelete={HandleDelete} onChangeYT={"py"} HandleChange={HandleChange} table={1} tableData={financialData?.prev_year} title={"Financial assets and liabilities - Fair value hierarchy (Previous Year)"} />
                        }

                        <p className='mt-3 custom-common-theme-text-color custom-font-500 pl-2'>Fair value of financial assets and liabilities measured at amortised cost</p>
                        <table>
                            <thead>
                                <tr className='custom-common-theme-bg-color'>
                                    <th style={{ width: "35%" }}></th>
                                    <th style={{}} colSpan={3} className='text-white'>As at period end of Preset</th>
                                    <th style={{}} colSpan={3} className='text-white'>As at comparative period end</th>
                                    <th style={{ width: "6%" }} ></th>
                                </tr>
                                <tr className='custom-common-theme-bg-color'>
                                    <th style={{ width: "35%" }} ></th>
                                    <th style={{ width: "9%" }} className='text-white' >Carrying amount</th>
                                    <th style={{ width: "9%" }} className='text-white' >Fair value</th>
                                    <th style={{ width: "9%" }} className='text-white' >Set Fair value same as cost</th>
                                    <th style={{ width: "9%" }} className='text-white' >Carrying amount</th>
                                    <th style={{ width: "9%" }} className='text-white' >Fair value</th>
                                    <th style={{ width: "14%" }} className='text-white' >Set Fair value same as cost</th>
                                    <th style={{ width: "6%" }} ></th>
                                </tr>
                            </thead>
                        </table>
                        {
                            financialData?.financialTableII && <TableManagement HandleDelete={HandleDelete} onChangeYT={"table3"} HandleChange={HandleChange} table={2} tableData={financialData?.financialTableII} title={""} />
                        }
                    </>
                ) : <Loading />
            }

            {isDeleted && 
                <RestoreFinancialModal isDeleted={isDeleted} setIsDeleted={setIsDeleted} 
                    deletedItemData={deletedItemData}
                    HandleDelete={HandleDelete}
                    handleSelect={handleSelect}
                    HandleChange={HandleChange}
                    setFinancialData={setFinancialData}
                    setDeletedItemData={setDeletedItemData}
                />}

        </>
    )
}

export default FinancialInstrument