import React, { useEffect, useState } from 'react';
import { Navbar } from '../../Components/Navbar';
import './linkRegister.css'
import { addLeaseTagAssets, deleteLeaseTagAssets, dropLeaseTagAssets, getLeaseTagAssets, removeLeaseTagAssetItem } from '../../services/api/apiHandler';
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import swal from 'sweetalert';
import CommonToolTip from '../../Components/Common/CommonToolTip';
import ErrorIcon from "@mui/icons-material/Error";
import Loading from "../../Components/Common/Loader/Loading";
import LoaderTwo from "../../Components/Common/Loader/LoaderTwo";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DotIndicatorSVGComponent from '../../Components/Common/SVGComponents/DotIndicatorSVGComponent';

const LeaseTagAssets = () => {
    const history = useHistory();
    const { leaseIndex } = useParams();
    const project_id = localStorage.getItem('project_id');
    const tb_id = localStorage.getItem('tb_id');

    const [assetsData, setAssetsData] = useState({
        leaseAssets: [],
        finanCialAssets: []
    });
    const [tagAssets, setTagAssets] = useState([]);
    const [draggedData, setDraggedData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dropLoading, setDropLoading] = useState(false);

    useEffect(() => {
        if (leaseIndex) {
            setLoading(true);
            getLeaseTagAssets(project_id, tb_id, leaseIndex).then(res => {
                setLoading(false);
                setAssetsData(prev => { return { ...prev, finanCialAssets: res?.data?.subGrpArray, leaseAssets: res?.data?.leaseAssets ?? [] } })
                setTagAssets(res?.data?.tagAssets);
            }).catch(err => {
                setLoading(false);
                swal("Error", err?.response?.data?.error || "Error: Lease Register Tag Assets API Failed!", "error");
            })
        }
    }, [])

    const AddNewItem = () => {
        setDropLoading(true);
        addLeaseTagAssets(project_id, tb_id, leaseIndex).then(res => {
            setDropLoading(false);
            setTagAssets(res.data.tagAssets);
        }).catch(err => {
            setDropLoading(false);
            swal("Error", err.response.data.error || "Error: Lease Register Tag Assets API Failed!", "error");
        })
    }

    const handleDragStart = (e, ele) => {
        setDraggedData(ele);
    };

    const handleDrop = (e, key, index) => {
        e.preventDefault();
        if (!draggedData) return;
        const group = key === "financialAssets" ? "sub_grp" : "assetClass";
        const filteredData = tagAssets.reduce((acc, obj) => {
            const financialAsset = obj[key].find(asset => asset[group] === draggedData[group]);
            if (financialAsset) {
                acc.push(financialAsset);
            }
            return acc;
        }, []);

        if(filteredData.length > 0){
            return swal("Warning", "Tag Asset Already Mapped!", "warning")
        };

        const data = {
            draggedData,
            key,
            index,
            leaseIndex
        }
        setDropLoading(true);
        dropLeaseTagAssets(project_id, tb_id, data).then(res => {
            setDropLoading(false);
            setTagAssets(res.data.tagAssets);
            setDraggedData(null);
        }).catch(err => {
            setDropLoading(false);
            swal("Error", err.response.data.error || "Error: Lease Register Tag Assets API Failed!", "error");
        })
    }

    const RemoveItem = (element, key, index) => {

        const data = {
            element,
            key,
            index,
            leaseIndex
        }

        setDropLoading(true);
        removeLeaseTagAssetItem(project_id, tb_id, data).then(res => {
            setDropLoading(false);
            setTagAssets(res.data.tagAssets);
        }).catch(err => {
            setDropLoading(false);
            swal("Error", err.response.data.error || "Error: Lease Register Tag Assets API Failed!", "error");
        })
    }

    const checkCondition = (index, element) => {
        const totalFinancialAssets = (tagAssets[index]?.financialAssets ?? []).reduce((acc, item) => {
            acc.totalPyAmt += item.py_amt;
            acc.totalCyAmt += item.cy_amt;
            return acc;
        }, { totalPyAmt: 0, totalCyAmt: 0 });

        return (totalFinancialAssets.totalPyAmt !== element.py || totalFinancialAssets.totalCyAmt !== element.cy) &&
            <div className='d-flex align-items-center justify-content-center'>
                <CommonToolTip
                    variant="primary"
                    title={"The values are not matching."}
                    placement="top"
                >
                    <ErrorIcon
                        style={{
                            color: "darkorange",
                            cursor: "pointer",
                        }}
                        className="financialInstrument-hoverIcon"
                    />
                </CommonToolTip>
            </div>
    }

    const DeleteItem = (index) => {
        setDropLoading(true);
        deleteLeaseTagAssets(project_id, tb_id, index).then(res => {
            setDropLoading(false);
            setTagAssets(res.data.tagAssets);
        }).catch(err => {
            setDropLoading(false);
            swal("Error", err.response.data.error || "Error: Lease Register Tag Assets API Failed!", "error");
        })
    }

    return (
        <React.Fragment>
            <Navbar />
            <div className="container-fluid">
                {
                    loading ? <Loading /> :
                        <div>
                            <div>
                                <button
                                    onClick={() => history.push(`/link-register`) }
                                    className="rounded back-btn btn btn-border-none pl-0 mt-3 ml-3"
                                >
                                    <div className="d-flex custom-font-600 custom-common-theme-text-color">
                                        <ArrowBackIcon className="mr-1" /> Back
                                    </div>
                                </button>
                            </div>

                            <div className="d-flex bg-transparent mb-2">
                                <div className="col-sm-3">
                                    <div className='radius-10px mt-3 border'>
                                        <p className='m-0 p-3 font-weight-bold font-size-14 font-sans custom-bg-warning-200 border-radius-t-lf-10'>Category of assets - Lease register</p>
                                        <div className='p-2 tag-assets-options bg-white '>
                                            {assetsData.leaseAssets.length > 0 && assetsData.leaseAssets.map((asset, index) => (
                                                <div
                                                    key={index} 
                                                    draggable
                                                    onDragStart={(e) => handleDragStart(e, asset)}
                                                    className='mt-2 py-2 px-3 tag-assets-option cursor-pointer radius-8px text-secondary font-size-14 border custom-bg-white-150 d-flex align-items-center' 
                                                >
                                                    <div><DotIndicatorSVGComponent color={"#DFE2E7"} height={21} width={13} /></div>
                                                    <p className='m-0 custom-font-500 ml-2'>{asset?.assetClass}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6" >
                                    <div className="w-100 d-flex justify-content-center align-items-center mt-1" style={{ height: "1.5rem" }} >
                                        {dropLoading && <LoaderTwo />}
                                    </div>

                                    <div className='d-flex flex-column justify-content-between border radius-10px bg-white'>
                                        <div>
                                            <p className='m-0 px-3 py-2 border-radius-t-lf-10 font-size-16 custom-font-600 font-inter custom-bg-gray-300' >Enter class of assets</p>
                                            {
                                                tagAssets.length > 0 && tagAssets.map((tag, tx) => (
                                                    <div key={tx} className='p-2 mt-1'>

                                                        <div className='d-flex justify-content-center align-items-center w-100'>
                                                            <div className='tag-assets-option-hr'></div>
                                                            <p className='text-secondary font-size-12 custom-font-500 font-inter m-0 text-center' style={{width: "20%"}} >Class of Assets {tx + 1}</p>
                                                            <div className='tag-assets-option-hr'></div>
                                                        </div>

                                                        <div className='d-flex justify-content-between' style={{gap:"10px"}}>
                                                            <div
                                                                className='w-50'
                                                                style={{ minHeight: "2rem" }}
                                                                onDragEnter={(e) => e.preventDefault()}
                                                                onDragOver={(e) => e.preventDefault()}
                                                                onDrop={(e) => handleDrop(e, "leaseAssets", tx)}
                                                                onDragLeave={(e) => e.preventDefault()}
                                                            >
                                                                {
                                                                    tag.leaseAssets.length > 0 && tag.leaseAssets.map((asset, ax) => (
                                                                        <div key={ax} className='mt-2 py-2 px-3 tag-assets-option cursor-pointer radius-8px text-secondary font-size-14 border custom-bg-white-150 d-flex align-items-center justify-content-between' >
                                                                            <div className='d-flex align-items-center' >
                                                                                <div><DotIndicatorSVGComponent color={"#DFE2E7"} height={21} width={13} /></div>
                                                                                <p className='m-0 custom-font-500 ml-2'>{asset?.assetClass}</p>
                                                                            </div>
                                                                            <div
                                                                                onClick={() => RemoveItem(asset, "leaseAssets", tx)}
                                                                            >
                                                                                <RemoveIcon />
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                            <div
                                                                className='w-50'
                                                                style={{ minHeight: "2rem" }}
                                                                onDragEnter={(e) => e.preventDefault()}
                                                                onDragOver={(e) => e.preventDefault()}
                                                                onDrop={(e) => handleDrop(e, "financialAssets", tx)}
                                                                onDragLeave={(e) => e.preventDefault()}
                                                            >
                                                                {
                                                                    tag.financialAssets.length > 0 && tag.financialAssets.map((asset, ax) => (
                                                                        <div key={ax} className='mt-2 py-2 px-3 tag-assets-option cursor-pointer radius-8px text-secondary font-size-14 border custom-bg-white-150 d-flex align-items-center justify-content-between' >
                                                                            <div className='d-flex align-items-center' >
                                                                                <div><DotIndicatorSVGComponent color={"#DFE2E7"} height={21} width={13} /> </div> 
                                                                                <p className='m-0 custom-font-500 ml-2'>{asset?.sub_grp}</p>
                                                                            </div>
                                                                            <div
                                                                                onClick={() => RemoveItem(asset, "financialAssets", tx)}
                                                                            >
                                                                                <RemoveIcon />
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <p 
                                            className='text-secondary font-size-12 custom-font-500 font-inter cursor-pointer'
                                            onClick={() => AddNewItem()}
                                        >
                                            + Add more class of assets
                                        </p>
                                    </div>
                                </div>

                                <div className="col-sm-3">
                                    <div className='radius-10px mt-3 border'>
                                        <p className='m-0 p-3 font-weight-bold font-size-14 font-sans custom-bg-warning-200 border-radius-t-lf-10'>Category of assets - As per Financials</p>

                                        <div className='p-2 tag-assets-options bg-white' >
                                            {assetsData.finanCialAssets.length > 0 && assetsData.finanCialAssets.map((asset, index) => (
                                                <div
                                                    key={index} 
                                                    draggable
                                                    onDragStart={(e) => handleDragStart(e, asset)}
                                                    className='mt-2 py-2 px-3 tag-assets-option cursor-pointer radius-8px text-secondary font-size-14 border custom-bg-white-150 d-flex align-items-center' 
                                                >
                                                    <div><DotIndicatorSVGComponent color={"#DFE2E7"} height={21} width={13} /></div>
                                                    <p className='m-0 custom-font-500 ml-2'>{asset?.sub_grp}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div>
        </React.Fragment>
    );
}

export default LeaseTagAssets;
