import React, { useEffect, useState } from 'react';
import { Navbar } from '../../Components/Navbar';
import { GetSubscriptionPlans, getActivePlan, getAllSubscriptions } from '../../WorkSpace/Components/ApiServices/apiHandler';
import { Typography } from '@mui/material';
import moment from 'moment';
import swal from "sweetalert";
import { useHistory } from 'react-router';
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
// import Typography from "@mui/material/Typography";
import Table from "react-bootstrap/Table";
import { getProfile } from '../../services/api/apiHandler';
import CircularProgress from "@mui/material/CircularProgress";

const SubscriptionMenu = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const [matchId, setMatchId] = useState('');
  const [activePlan, setActivePlan] = useState('');
  const [custId, setCustId] = useState('');
  const [active, setActive] = useState('');
  const history = useHistory();
  const [subscriptions, setSubscription] = useState([]);
  const [userId, setUserId] = useState('');
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState([]);

  const getProfileId = async () => {
    try {
      const response = await getProfile();
      console.log(response.data.user.data._id);
      if (response.status === 200) {
        setUserId(response.data.user.data._id);
      }
    } catch (error) {
      console.error('Error fetching profile:', error);
      throw error;
    }
  };

  const GetActivePlan = async () => {
    try {
      const res = await getActivePlan();
      console.log(res);
      setActive(res.data.status);
      setCustId(res.data.user_id);
      setMatchId(res.data.plan_id);
    } catch (error) {
      console.log('Error fetching active plan:', error);
    }
  };

  const GetAllSubscriptions = async () => {
    try {
      setLoading(true)
      const res = await getAllSubscriptions(userId);
      console.log(res, 'all subscriptions -------->');
      setSubscription(res.data);
    } catch (error) {
      console.error('Error fetching subscriptions:', error);
    }
    setLoading(false)
  };

  const subscriptionPlans = async () => {
    try {
      const res = await GetSubscriptionPlans();
      if (res.status === 200) {
        console.log(res, 'bhanbvnmfghj');
        setPlans(res.data.data);
      }
    } catch (error) {
      console.error('Error fetching subscription plans:', error);
    } finally {
    }
  };

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        await getProfileId();
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    fetchUserId();
  }, []);

  useEffect(() => {
    if (userId) {
      const fetchData = async () => {
        try {
          await subscriptionPlans();
          await GetActivePlan();
          await GetAllSubscriptions();
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [userId]);

  const subscriptionPlanDetails = async (id) => {
    if(user.isAdmin === "No"){
      swal("Warning","You don't have access to Upgrade plan contact admin","warning");
      return false;
  }
    history.push(`/subscription/${id}`);
  };

  const subscriptionDetails = async (id) => {
    history.push(`/subscription-details/${id}`);
  };

  return (
    <>
      <Navbar />

      {loading ? (
        <div className='d-flex justify-content-center align-items-center vh-100'>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div>
            <Typography variant='h4' sx={{ color: '#03565A', textAlign: 'center', margin: '30px' }}>Choose Your Plan</Typography>
          </div>

          {subscriptions.length > 0 ? (
            <>
              <div className='mr-5 ml-5 mt-3'>
                <TableContainer>
                  <Table sx={{}} aria-label="caption table">
                    <>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" className="font-sans">Plan Name</TableCell>
                          <TableCell align="center" className="font-sans">Plan Price</TableCell>
                          <TableCell align="center" className="font-sans">Active/Upgrade</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {plans.map((det, index) => (
                          <TableRow key={index}>
                            <TableCell className="font-sans" style={{ backgroundColor: "white" }} align="center">{det.planName}</TableCell>
                            <TableCell className="font-sans" style={{ backgroundColor: "white" }} align="center">{det.planPrice}</TableCell>
                            <TableCell className="font-sans" style={{ backgroundColor: "white" }} align="center">
                              {det._id === matchId ? <span className='text-success'>Active</span> : <span style={{ cursor: 'pointer', color: 'red' }} onClick={() => subscriptionPlanDetails(det._id)}>Upgrade</span>}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </>
                  </Table>
                </TableContainer>
              </div>
              <Typography variant='h6' sx={{ color: '#03565A', textAlign: 'center', margin: '30px' }}>Your Subscribed Plans</Typography>
              <div className='mr-5 ml-5 mt-3'>
                <TableContainer>
                  <Table sx={{}} aria-label="caption table">
                    <>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" className="font-sans">Plan Name</TableCell>
                          <TableCell align="center" className="font-sans">Plan Price</TableCell>
                          <TableCell align="center" className="font-sans">Start Date</TableCell>
                          <TableCell align="center" className="font-sans">End Date</TableCell>
                          <TableCell align="center" className="font-sans">Payment</TableCell>
                          <TableCell align="center" className="font-sans">Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {subscriptions.map((det, index) => (
                          <TableRow key={index} style={{ cursor: 'pointer' }} onClick={() => subscriptionDetails(det._id)}>
                            <TableCell className="font-sans" style={{ backgroundColor: "white" }} align="center">{det.plan_name}</TableCell>
                            <TableCell className="font-sans" style={{ backgroundColor: "white" }} align="center">{det.plan_price}</TableCell>
                            <TableCell className="font-sans" style={{ backgroundColor: "white" }} align="center">{moment(det.subscription_start_date).format('DD-MM-YYYY')}</TableCell>
                            <TableCell className="font-sans" style={{ backgroundColor: "white" }} align="center">{moment(det.subscription_end_date).format('DD-MM-YYYY')}</TableCell>
                            <TableCell className="font-sans" style={{ backgroundColor: "white" }} align="center">{det.payment_status}</TableCell>
                            <TableCell className="font-sans" style={{ backgroundColor: "white" }} align="center">{det.status}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </>
                  </Table>
                </TableContainer>
              </div>
            </>
          ) : (
            <div className='d-flex justify-content-between' style={{ flexWrap: 'wrap' }}>
              {plans?.map((plan) => (
                <div key={plan._id} className={`p-1 text-center m-3`} style={{ maxWidth: "300px", background: "linear-gradient(-45deg,#24ff72,#9a4eff)", borderRadius: '5px' }}>
                  <i className="fa fa-paper-plane" aria-hidden="true" style={{ fontSize: '50px', padding: '10px' }}></i>
                  <Typography>{plan.planName}</Typography>
                  <h4><sup>$</sup>{plan.planPrice}</h4>
                  <div>Features
                    <ul>
                      <li><i className="fa fa-check" aria-hidden="true"></i>{plan.planDescription}</li>
                      <li><i className="fa fa-check" aria-hidden="true"></i>3 Domain Names</li>
                      <li><i className="fa fa-check" aria-hidden="true"></i>20 Email Addresses</li>
                      <li><i className="fa fa-times" aria-hidden="true"></i>Live Support</li>
                    </ul>
                  </div>
                  <div className='m-3'>
                    <p className='pl-5 pr-5 pt-1 pb-1' style={{ border: '1px solid black', borderRadius: '10px', textDecoration: 'none', cursor: 'pointer' }} onClick={() => subscriptionPlanDetails(plan._id)}>Buy Now</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default SubscriptionMenu;
