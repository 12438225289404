import React, { useEffect, useState } from "react";
import { Navbar } from "../../Components/Navbar";
import { useHistory } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PrimaryButton from "../../Components/Common/PrimaryButton";
import { createNewTableRowLinkRegister, dropValueMappingLinkRegister, getLinkRegister, mappingLinkRegister, onchangeMappingLinkRegister } from "../../services/api/apiHandler";
import swal from 'sweetalert';
import "./linkRegister.css";
import Loading from "../../Components/Common/Loader/Loading";
import LinkRegisterTable from "./LinkRegisterTable";
import LinkRegisterAccordion from "./LinkRegisterAccordion";
import LoaderTwo from "../../Components/Common/Loader/LoaderTwo";
import moment from "moment";
import Card from '@mui/material/Card';


const LinkRegister = () => {
  const history = useHistory();
  const project_id = localStorage.getItem('project_id');
  const tb_id = localStorage.getItem('tb_id');
  const user = JSON.parse(localStorage.getItem('user'));
  const [linkRegisterData, setLinkRegisterData] = useState({});
  const [linkRegisterTableData, setLinkRegisterTableData] = useState({});
  const [loading, setLoading] = useState(false);
  const [dropLoading, setDropLoading] = useState(false);
  const [draggedData, setDraggedData] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    setLoading(true);
    getLinkRegister(project_id, tb_id).then(response => {
      setLoading(false);
      setLinkRegisterData(response.data.linkRegisterData);
      setLinkRegisterTableData(response.data.data)
    }).catch(error => {
      setLoading(false);
      swal("Error", error.response.data.error || "Error: Link Register API Failed!", "error");
    })
  }, [])

  const AddNewTableRow = (key) => {
    if(user.responsibilities === "view"||user.responsibilities === "approver"){
      swal("Warning","You don't have access to add","warning");
      return false;
  }
    const data = {
      keyName: key
    }
    setDropLoading(true);
    createNewTableRowLinkRegister(project_id, tb_id, data).then(response => {
      setDropLoading(false);
      setLinkRegisterTableData(response.data.data)
    }).catch(error => {
      setDropLoading(false);
      swal("Error", error, "error");
    })
  }

  const handleDrop = (e, keyName, indexId, name) => {
    if(user.responsibilities === "view"||user.responsibilities === "approver"){
      swal("Warning","You don't have access to drag and drop","warning");
      return false;
  }
    e.preventDefault()
    if (!draggedData) return;
    
    const validKeyMapping = {
      loans: "filteredLoansData",
      payable: "filteredPayableData",
      receivable: "filteredReceivableData",
      leases:"filteredLeaseData"
  };

  // Check if the dragged data belongs to the correct category
  // const draggedCategory = draggedData[0]?.name; // Assuming draggedData contains category info
  // if (!validKeyMapping[keyName] || draggedCategory.toLowerCase() !== keyName.toLowerCase()) {
  //     swal("Warning", `You cannot drop ${draggedCategory} data into ${keyName}.`, "warning");
  //     return;
  // }

  
    // ? Handle Date validation for loans
    if (["loans", "payable", "receivable"].includes(keyName)) {
      // const filteredKey = keyName === "loans" ? "filteredLoansData" : 
      //   keyName === "payable" ? "filteredPayableData" : "filteredReceivableData";
      const filteredKey = validKeyMapping[keyName];
      // const findLoanData = linkRegisterData[filteredKey].find(ele => ele?._id === draggedData[0]);
      const draggedIds = draggedData.map(item => item.id); // Extract all dragged IDs
      const findLoanData = linkRegisterData[filteredKey].find(ele => draggedIds.includes(ele?._id));
      if (findLoanData) {
        const dateKey = keyName === "loans" ? "endDate" : "asOnDate";
        const date = linkRegisterTableData[keyName][0][name];
        if (moment(findLoanData[dateKey]).format('YYYY-MM-DD') !== moment(date).format('YYYY-MM-DD')) {
          swal("Warning", `Loan registers data is not matching with Workspace ${name === "cy" ? "current" : name === "py" ? "previous" : "preceding"} year date.`, "warning")
          return;
        }
      }
    }
    
    const data = {
      all_ids: draggedData.map(item => item.id),
      keyName,
      name,
      indexId
    }
    setDropLoading(true);
    dropValueMappingLinkRegister(project_id, tb_id, data).then(response => {
      setDropLoading(false);
      swal("Success", "Data Register Mapped Successfully.", "success")
      setLinkRegisterTableData(response.data.data);
      setDraggedData(null);
      setSelectedItems([]);
    }).catch(error => { setDropLoading(false); swal("Error", error.response.data.error, "error") })
  }

  const HandleChange = (e, key, index, name=null) => {
    const data = {
      key,
      index,
      keyName: key === "loans" ? name : e.target?.name,
      value: key === "loans" ? e : e.target.value
    }
    setDropLoading(true);
    onchangeMappingLinkRegister(project_id, tb_id, data).then(response => {
      setDropLoading(false);
      setLinkRegisterTableData(response.data.data);
    }).catch(error => { setDropLoading(false); swal("Error", error.response.data.error, "error") })
  }

  const HandleMapping = (index, key) => {
    if(user.responsibilities === "view"||user.responsibilities === "approver"){
      swal("Warning","You don't have access to map","warning");
      return false;
  }
    setDropLoading(true);
    mappingLinkRegister(project_id, tb_id, index, key).then(response => {
      setDropLoading(false);
      swal("Success", (response.data.message || "Data Register Mapped Successfully."), "success")
    }).catch(error => {
      setDropLoading(false);
      swal("Error", error.response.data.error, "error")
    })
  }

  return (
    <>
      <Navbar text="Financial Year: 2021 - 2022" />
      <div className="custom-bg-blue-100 px-5 pb-5 pt-3" style={{ minHeight: "100vh" }}>
        {
          loading ? <Loading />
            :
            <>
              <button
                // style={{ marginTop: "-20px" }}
                className="rounded back-btn btn btn-border-none pl-0"
                onClick={() => {
                  history.push(`/financialstatement`);
                }}
              >
                <div className="d-flex  custom-font-600 custom-common-theme-text-color">
                  <ArrowBackIcon className="mr-1" /> Back
                </div>
              </button>

              <div>
                <p style={{ fontSize: "24px", lineHeight: "28px" }} className="mt-1 mb-1 custom-font-700 font-sans font-weight-bold ">Link your Registers to your Financial's</p>
                <p className="font-size-14 p-0 m-0 font-sans custom-font-500 " style={{ lineHeight: "24px", color: "#939393" }}> Map Imported Registers to your Financial Statements Notes and Disclosures </p>
              </div>

              <div className="w-100 bg-red d-flex justify-content-center align-items-center" style={{ height: "2rem" }} >
                {dropLoading && <LoaderTwo />}
              </div>

              <div className="d-flex py-2 ">
                <div className="w-75 pr-2 link-register-side-bar">
                  {
                    Object.keys(linkRegisterTableData).length > 0 && Object.keys(linkRegisterTableData).map(ele => (
                      <LinkRegisterTable
                        HandleMapping={HandleMapping}
                        keyElement={ele}
                        data={linkRegisterTableData}
                        AddNewTableRow={AddNewTableRow}
                        handleDrop={handleDrop}
                        HandleChange={HandleChange}
                      />
                    ))
                  }
                </div>

                <div className="w-25 ml-2" >
                  <Card className="mt-3" style={{ borderRadius: "12px", border: "2px solid #D6D6D6" }}>
                    <div className="link-header header">
                      <p className="font-size-16 custom-font-600 text-capitalize font-sans m-0 p-3" >Import Registers</p>
                    </div>

                    <div className="link-register-side-bar">
                      <LinkRegisterAccordion selectedItems={selectedItems} setSelectedItems={setSelectedItems} name={"Leases"} data={linkRegisterData?.filteredLeaseData} setDraggedData={setDraggedData} />
                      <LinkRegisterAccordion selectedItems={selectedItems} setSelectedItems={setSelectedItems} name={"Loans"} data={linkRegisterData?.filteredLoansData} setDraggedData={setDraggedData} />
                      <LinkRegisterAccordion selectedItems={selectedItems} setSelectedItems={setSelectedItems} name={"Receivable"} data={linkRegisterData?.filteredReceivableData} setDraggedData={setDraggedData} />
                      <LinkRegisterAccordion selectedItems={selectedItems} setSelectedItems={setSelectedItems} name={"Payable"} data={linkRegisterData?.filteredPayableData} setDraggedData={setDraggedData} />
                      <LinkRegisterAccordion selectedItems={selectedItems} setSelectedItems={setSelectedItems} name={"Book closure"} data={[]} setDraggedData={setDraggedData} />
                    </div>

                  </Card>
                </div>
              </div>
            </>
        }
      </div>
    </>
  );
};

export default LinkRegister;
